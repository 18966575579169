import React, { useMemo, useReducer } from 'react';
import { TournamentSettings } from '../interfaces';
import styles from './css/rules.module.css';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

const [height, width] = getDimensions();

interface RulesProps {
	id: string
	settings: TournamentSettings

}

export default function Rules(props: RulesProps) {

	const hashPage = parseInt(window.location.hash.slice(1));

	const [page, setPage] = useReducer((state: number, action: 'increment' | 'decrement') => {
		if (action === 'decrement') {
			window.location.hash = '#' + (state - 1).toString();
			return state - 1;
		} else
		if (action === 'increment') {
			window.location.hash = '#' + (state + 1).toString();
			return state + 1;

		}
		return state;
	}, !isNaN(hashPage) ? hashPage : 1);

	const url = useMemo(() => {
		let href = props.settings.rulesURL;
		if (!href) return '';
		if (href.startsWith('https')) return href;
		return href.replace('http://', 'https://s3.eu-west-2.amazonaws.com/');
	}, [props.settings.rulesURL]);

	return (
		<section className={styles.columns}>
			<embed key={page} src={url + (page ? '#page=' + page : '')} type='application/pdf' title='UK University Chess League Rules' height={height} width={width} />	
			<div className={styles.controls}>
				<div className={styles.title}>
					Quick Navigation
				</div>
				<div className={styles.arrows}>
					<div className={styles.arrow} onClick={() => setPage('decrement')}>
						<FaAngleLeft />
					</div>
					<div className={styles.arrow} onClick={() => setPage('increment')}>
						<FaAngleRight />
					</div>
				</div>
				<div className={styles.link}>
					<input value={window.location.href} />
				</div>
			</div>
		</section>
	);
}

function getDimensions() {
	if (window.innerWidth < 1080) return [window.innerWidth / (window.innerWidth * (2 ** 0.5)), window.innerWidth];
	let width = window.innerWidth * 0.68;
	let height = width / (2 ** 0.5);
	return [height, width];
}