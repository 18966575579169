import { useEffect, useMemo, useState, useCallback } from 'react';
import { cookies, lichess, lichessCallback, POST } from '../../utils/requests';

export function useLink(props: {
	currentPath: string
	updateProfile: () => void
}) {

	const { updateProfile } = props;
	const [sessionID, setSession] = useState('');
	useEffect(() => {
		(async () => {
			let c = await cookies.getAll();
			setSession(c.sessionID);
		})();
	}, [setSession]);
	const href = useMemo(() => {
		const base = 'https://oauth.lichess.org/oauth/authorize';
		const scopes = [
			['email', 'read'],
			['challenge', 'read'],
			['challenge', 'write']
		] as string[][];
		const params = {
			response_type: 'code',
			client_id: lichess,
			redirect_uri: lichessCallback,
			scope: scopes.map(scope => scope.join(':')).join(' '),
			state: `${sessionID?.slice(0, 8)}.referrer=${props.currentPath}`
		};
		return `${base}?${Object.entries(params).map(p => p.join('=')).join('&')}`;
	}, [sessionID, props.currentPath]);

	const [win, setWindow] = useState(null as Window | null);
	const handleClose = useCallback((myWin: Window) => {
		if (!myWin.closed) return;
		setWindow(null);
		updateProfile();
	}, [updateProfile, setWindow]);
	useEffect(() => {
		if (!win) return;
		let x = setInterval(() => handleClose(win), 1000);
		return () => clearInterval(x);
	}, [win, handleClose]);

	return { href, setWindow };

}

export function handleSync(updateProfile: () => void) {
	return POST({ url: ['profile', 'lichess', 'updateFrom'].join('/') })
		.finally(updateProfile);
}