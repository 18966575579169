import React, { ReactElement, Dispatch, SetStateAction, useContext, useMemo, useState, useEffect, useCallback, useReducer } from 'react';
import { Link } from 'react-router-dom';
import Markdown from 'react-markdown';
import { isMobile } from '../utils/auth';
import styles from '../css/header.module.css';
import { LoginContext } from '../Contexts';
import { ProfileData, Notif } from '../interfaces';
import { FaBars, FaBell, FaAngleDoubleRight, FaBullhorn, FaFacebook, FaTwitter, FaLightbulb } from 'react-icons/fa';
import { socket, POST } from '../utils/requests';
import { RouterLink, parseHtml } from '../utils/plugins';
import { timeBetween } from '../utils/prototype';

export interface TitlebarProps {
	setID: Dispatch<SetStateAction<string>>
	profile: ProfileData
	referrer: string
	currentPath: string
}

const notifIcons = {
	draw: <FaAngleDoubleRight />,
	announcement: <FaBullhorn />
} as {[key: string]: ReactElement};

const defaultTitle = 'Results | ScorchChess';
export const defPathDict = {
	'/login': 'login',
	'/register': 'login',
	'/me': 'login',
	'/@/': 'community',
	'/teams': 'teams',
	'/community': 'community',
	'/news': 'news',
	'/team/': 'teams',
	'/player/': 'community',
};

export default function Header(props: TitlebarProps): ReactElement | null {

	const pathDict = useMemo(() => ({['/@/' + props.profile.username]: 'login', ...defPathDict}), [props.profile.username]);

	const { isLoggedIn } = useContext(LoginContext);
	const [mobileMenu, setMenu] = useState(false);
	const [display, setDisplay] = useState(true);
	const [notifs, setNotifs] = useState([] as Notif[]);
	const [showNotifs, toggleNotifs] = useReducer((state) => !state, false);
	const ping = useMemo(() => notifs.filter(n => n && !n.seen).length, [notifs]);
	useEffect(() => {
		if (ping) document.title = '(' + ping + ') ' + document.title;
		else document.title = defaultTitle;
	}, [ping]);

	const toggleTheme = useCallback(() => POST({
		url: '/profile/updateSetting',
		data: {
			//theme: props.user.theme === 'light' ? 'dark' : 'light'
		}
	}).then(), []);//props.updateProfile), [props.user.theme, props.updateProfile]);
	
	const buttons = useMemo(() => [
		{
			name: <><strong>Scorch</strong>Results</>,
			mobile: 'Home',
			url: '/',
			selected: !Object.entries(pathDict).some(([k]) => props.currentPath.startsWith(k))
		},
		{
			name: 'Teams',
			url: '/teams',
			selected: pathDict[Object.keys(pathDict).find((k) => props.currentPath.startsWith(k)) as keyof typeof pathDict] === 'teams'
		},
		{
			name: 'News',
			url: '/news',
			selected: pathDict[Object.keys(pathDict).find((k) => props.currentPath.startsWith(k)) as keyof typeof pathDict] === 'news'
		},
		{
			name: 'About',
			url: 'https://scorchapp.co.uk',
			mobileIgnore: true
		},
		{
			name: <FaLightbulb />,
			mobileIgnore: true,
			color: 'yellow',
			onClick: toggleTheme,
			native: true,
			show: false
		},
		{
			name: <FaFacebook />,
			url: 'https://facebook.com/scorchchess',
			mobile: 'Facebook',
			native: true,
			color: '#4267B2'
		},
		{
			name: <FaTwitter />,
			url: 'https://twitter.com/scorchchess',
			mobile: 'Twitter',
			color: '#00aced',
			native: true,
		},/*
		{
			name: 'Desktop',
			url: 'https://beta.scorchapp.co.uk',
			mobileIgnore: true
		},*/
		{
			name: <>
				<FaBell />
				{!ping ? null : <div className={styles.unseen}>{ping}</div>}
			</>,
			onClick: toggleNotifs,
			native: true,
			hide: !isLoggedIn,
			mobileIgnore: true,
			color: '#43853d'
		},
		{
			name: isLoggedIn ? (props.profile.username || 'PROFILE') : 'SIGN IN',
			url: isLoggedIn ? '/@/' + props.profile.username : '/login',
			selected: pathDict[Object.keys(pathDict).find((k) => props.currentPath.startsWith(k)) as keyof typeof pathDict] === 'login',
			native: true
		}
	], [isLoggedIn, props.profile, props.currentPath, pathDict, toggleNotifs, ping, toggleTheme]);

	const checkIfFullScreen = useCallback(() => {
		if (window.innerWidth === window.screen.width && window.innerHeight === window.screen.height) setDisplay(false);
		else setDisplay(true);
	}, [setDisplay]);

	useEffect(() => {
		document.addEventListener('fullscreenchange', checkIfFullScreen);
		return () => document.removeEventListener('fullscreenchange', checkIfFullScreen);
	}, [checkIfFullScreen]);

	const checkKeyPress = useCallback((e: KeyboardEvent) => {
		if (e.keyCode !== 122) return;
		if ((window.innerWidth > window.screen.width - 10) && (window.innerHeight > window.screen.height - 10)) setDisplay(false);
		else setDisplay(true);
	}, [setDisplay]);

	useEffect(() => {
		document.addEventListener('keydown', checkKeyPress);
		return () => document.removeEventListener('keydown', checkKeyPress);
	}, [checkIfFullScreen, checkKeyPress]);

	const [small, setSmall] = useState(false);
	const updateHeight = useCallback(() => {
		if (!window.scrollY) setSmall(false);
		else setSmall(true);
	}, [setSmall]);
	useEffect(() => {
		document.addEventListener('scroll', updateHeight);
		return () => document.removeEventListener('scroll', updateHeight);
	}, [updateHeight]);

	useEffect(() => {
		if (props.profile.id) {
			socket.on('notifs', setNotifs);
			socket.emit('request_notifs');
			let x = setInterval(() => socket.emit('request_notifs'), 60000);
			return () => clearInterval(x);
		}
	}, [setNotifs, props.profile.id]);

	useEffect(() => {
		socket.emit('request_notifs');
	}, [setNotifs]);
	useEffect(() => {
		let x = () => socket.emit('request_notifs');
		window.addEventListener('focus', x);
		return () => window.removeEventListener('focus', x);
	}, []);
	useEffect(() => {
		let x = () => socket.emit('request_notifs');
		window.addEventListener('blur', x);
		return () => window.removeEventListener('blur', x);
	}, []);
	
	if (!display) return null;

	return (<>
		<div className={[styles.header, small ? styles.small : true].join(' ')}>
			<Link 
				to={{
					pathname: '/'
				}}
				className={styles.logoWrapper}
			>
				<img
					src={process.env.PUBLIC_URL + '/favicon.png'}
					className={styles.logo}
					alt='logo'
				/>
			</Link>
			{!isMobile() ? <>
				<div className={styles.buttonContainer}>
					{buttons.map((b, i) => {
						return b.url && b.url.startsWith('/') ? 
							<div
								key={['headerLink', i].join('.')}
								className={[styles.headerButton, b.selected ? styles.selected : '', ].join(' ')}
								style={{ visibility: b.hide ? 'hidden' : undefined }}
							>
								<Link 
									className={[styles.button, b.native ? styles.native : ''].join(' ')}
									to={{
										pathname: b.url
									}}
								>
									{b.name}
								</Link>
							</div> :
							<div
								key={['headerLink', i].join('.')}
								className={[styles.headerButton, b.selected ? styles.selected : '', ].join(' ')}
								style={{
									display: b.show === false ? 'none' : undefined,
									visibility: b.hide ? 'hidden' : undefined
								}}
							>
								<a
									key={['headerLink', i].join('.')}
									className={[styles.button, b.native ? styles.native : ''].join(' ')}
									onClick={b.onClick}
									href={b.url}
									target='_blank'
									rel='noopener noreferrer'
									style={{ color: b.color }}
								>
									{b.name}
								</a>
							</div>;                      
					})}
					<div className={[styles.notifications, 'scrollable'].join(' ')} style={{
						opacity: showNotifs ? 1 : 0,
						visibility: showNotifs ? 'visible' : 'hidden'
					}}>
						<div className={styles.inlineNotifTitle}><strong>Notifications</strong></div>
						<hr />
						{notifs.length ?
							notifs.sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()).map((n, i) => {
								return <Link key={['notif', i].join('.')} to={n.link} className={[styles.inlineNotif, !n.seen ? styles.unseenN : ''].join(' ')} onClick={() => POST({
									url: '/profile/readNotification',
									data: { id: n.id },
									noCatch: true
								}).then(() => socket.emit('request_notifs')).then(() => toggleNotifs())} >
									{notifIcons[n.role] || <div />}
									<div className={[styles.inlineNotifContent].join(' ')}>
										<Markdown
											source={n.content.trim()}
											escapeHtml={false}
											astPlugins={[parseHtml]}
											renderers={{
												link: RouterLink
											}}
										/>
									</div>
									<div className={styles.inlineNotifDate}>
										{timeBetween(new Date(n.createdAt), new Date(Date.now()))}
									</div>
								</Link>;
							}) :
							'You have no new notifications at this time.'
						}
					</div>				
				</div>
			</> :
				<div className={styles.menuButton} onClick={() => setMenu(!mobileMenu)}>
					<FaBars />
				</div>
			}
		</div>
		{isMobile() ?
			<div className={styles.mobileMenu} style={{
				paddingTop: mobileMenu ? undefined : '0px',
				maxHeight: mobileMenu ? '100%' : '0px'
			}}>
				{buttons.map((b, i) => {
					if (b.mobileIgnore) return null;
					let name = b.mobile || b.name;
					if (typeof name === 'string') name = name.toLowerCase();
					return <React.Fragment key={[b, i].join('.')}>
						<Link							
							className={styles.button}
							onClick={() => setMenu(false)}
							to={{
								pathname: b.url,
								state: {
									from: window.location.pathname
								}
							}}
						>
							{name}
						</Link>
					</React.Fragment>;
				})}
			</div> :
			null}
	</>);
}