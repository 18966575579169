import React from 'react'
import PropTypes from 'prop-types'
import { FaPause, FaPlay } from 'react-icons/fa'

class Play extends React.Component {

  _handlePlay = () => {
    const { onPlay } = this.props

    if(typeof onPlay !== 'function') return

    onPlay()
  }

  render() {

    return (
      <div onClick={this._handlePlay} style={this.props.iconStyles}>
        {this.props.isPlaying ? <FaPause /> : <FaPlay />}
      </div>
    )
  }
}

Play.propTypes = {
  onPlay: PropTypes.func.isRequired,
  isPlaying: PropTypes.bool,
  iconStyles: PropTypes.object.isRequired
}

export default Play
