import React from 'react';
import cx from 'classnames';
import { capitalise } from '../../utils/prototype';
import { TournamentSettings, TournamentStatus } from '../../interfaces';
import PlayerData from '../../models/Player';

import styles from './css/tournamentinfo.module.css';

interface InfoProps {
	status: TournamentStatus
	settings: TournamentSettings
	players: PlayerData[]
	loaded: {[key: string]: boolean}
	setFloat: (v: string) => void
}

export default function TournamentInfo(props: InfoProps) {

	/**
	 * We prefer to use iterators to make code very concise (Object-orientated, NOT declarative programming)
	 * Advantage: a lot less code, a lot easier to change
	 * Advantage: we can uniformly modify styles, bit of logic
	 * Disadvantage: if you use declarative, i.e. typing out lots of <div>s, then what you see is what you get
	 */
	if (!props.loaded.all) return null;
	const data = {
		format: capitalise(props.settings.pairingSystem),
		created: new Date(props.status.createdAt).toString().slice(4, 15),
		type: capitalise(props.settings.competitors) + 's',
		[capitalise(props.settings.competitors === 'team' ? 'Teams' : 'Players')]: props.status.players,
		games: props.settings.gamePointTotal === 1 ? null : <>
			<div>{Math.ceil(props.players.reduce((acc, curr) => acc += curr.histories.reduce((acc, curr) => curr ? acc += curr.played : acc, 0), 0) / 2)}</div>
		</>,
		location: props.settings.location,
		'Time control': props.settings.timeControl,
		status: props.status.active !== false ? 'Live' : props.status.round ? 'Finished' : 'Not yet started',
	};

	const info = Object.entries(data).map(([k, v], i) => {
		if (!v) return null;
		return <React.Fragment key={cx(k, i)}>
			<div className={styles.infoKey}>{capitalise(k)}:</div>
			<div className={styles.infoValue}>{v}</div>
		</React.Fragment>;
	});


	/* Tournament information card */
	return (
		<div className={styles.tournament_info}>
			<div>
				{info}
			</div>
			{!props.settings.prizes ?
				null :
				<div className={styles.tieBreakLink} onClick={() => props.setFloat('prizes')}>
					Prizes
				</div>
			}
		</div>
	);
}