import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import Move from './Move'

class MoveList extends React.Component {

	constructor() {
		super();
		this.moveList = createRef();
	}

	componentDidUpdate(prevProps) {
		if (!this.props.moves) return;
		let prevMoves = prevProps.moves || [];
		if (this.props.moves.length === prevMoves.length) return;
		if (this.moveList && this.moveList.current) {
			this.moveList.current.scrollTop = this.moveList.current.scrollHeight;
		}
	}

	componentDidMount() {
		if (this.moveList && this.moveList.current) {
			this.moveList.current.scrollTop = this.moveList.current.scrollHeight;
		}
	}

  render() {
    const { result, moves, currentIndex, onChangeMove, startAtMove, endAtMove, fenMove } = this.props

	if (!moves) return null;
    return (
      <div className="pgnViewerMoveList" ref={this.moveList}>
        {!fenMove &&
          moves.map((move, index) => {
            if(index < startAtMove - 1 || index > endAtMove - 1) {
              return null
            } else {
              return (
                <Move
                  onChangeMove={onChangeMove}
                  key={index}
                  currentIndex={currentIndex}
                  move={move}
                  moveIndex={index+1}
                />
              )
            }
          })
        }
        {fenMove &&
          <div>{fenMove % 2 === 0 ? 'White' : 'Black'} to move</div>
        }
		{<span className='bold'>{result}</span>}
      </div>
    )
  }
}

MoveList.propTypes = {
  currentIndex: PropTypes.number,
  moves: PropTypes.array,
  onChangeMove: PropTypes.func.isRequired,
  fenMove: PropTypes.number,
  startAtMove: PropTypes.number,
  endAtMove: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default MoveList
