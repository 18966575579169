import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { capitalise } from '../../utils/prototype';
import styles from '../css/switcher.module.css';

export default function Switcher(props: {
	section: string
	loaded: boolean
	classNames?: {[key: string]: string}
	sections: {
		[key: string]: {
			name?: string
			link: string
			icon: JSX.Element | string
			hide?: boolean,
			index?: number,
			className?: string
		}
	}
}): ReactElement {

	let selected = Object.keys(props.sections).find(k => props.section === k) || Object.keys(props.sections)[0];

	return <>
		<div className={[styles.switcher, props.classNames?.switcher].join(' ')}>
			{Object.entries(props.sections).sort((a, b) => (a[1].index ?? Infinity) - (b[1].index ?? Infinity)).map(([k, s], i) => {
				if (s.hide) return null;
				if (!props.loaded) {
					return <div className={['header-button'].join(' ')} key={['header-button', i].join(' ')}>
						{'\u200b'}
					</div>;
				} else {
					return <Link to={s.link} key={['header-button', i].join('.')} className={['header-button', s.className, selected === k ? 'selected' : ''].join(' ')}>
						<div>
							{typeof s.icon === 'string' ?
								<img src={s.icon} alt={s.name} /> :
								s.icon
							}
							{s.name || capitalise(k)}</div>
					</Link>;
				}
			})}
		</div>
	</>;
}