import React, { ReactElement, useState, useCallback, useEffect, useRef, useMemo, useReducer, createRef, RefObject } from 'react';
import { Team, ProfileData, TournamentStatus, Player } from '../../interfaces';
import { GET, POST } from '../../utils/requests';
import * as regexes from '../../utils/regexes';
import Info from '../../user/Info';
import { AlertContext } from '../../Contexts';
import { TournamentSettings } from '../../resources/settings';
import { capitalise, px } from '../../utils/prototype';
import PlayerData from '../../models/Player';
import { TieBreaks } from '../../resources/tiebreaks';
import { Live, Pairings } from '../';
import Announcements from '../Announcements';
import cx from 'classnames';
import TournamentInfo from './TournamentInfo';
import RegisteredPlayers from './RegisteredPlayers';
import Featured from './Featured';

import styles from './css/dashboard.module.css';
import useBio from '../../user/hooks/useBio';
import useTiebreaks from '../hooks/useTiebreaks';

export interface DashboardProps {
	status: TournamentStatus
	settings: TournamentSettings
	players: PlayerData[]
	loaded: {[key: string]: boolean}
	round: number
	isLive: boolean
	direction: string
	id: string
	profile: ProfileData
	playerDict: Map<string, Player>
	
	pop: (elem: HTMLDivElement | null) => void

	announcements: {
		announcement: string;
		createdAt: string;
		updatedAt: string;
	}[]
}

export default function Dashboard(props: DashboardProps):ReactElement {

	// Retrieve tournament organiser profile data
	const [profile, setProfile] = useState(null as ProfileData & Team | null);
	const updateProfile = useCallback(async () => {
		if (!regexes.username.test(props.status.user)) return setProfile(null);
		GET({ url: px('user', props.status.user) })
			.then(setProfile)
			.catch(() => setProfile(null));
	}, [props.status.user, setProfile]);
	useEffect(() => {
		updateProfile();
	}, [updateProfile]);

	const { updateBio } = useBio({ profile, updateProfile });

	type FloatTypes = '' | 'tie breaks' | 'prizes' | 'description';
	const [floatContainer, setFloat] = useReducer((state: FloatTypes, action: FloatTypes) => state === action ? '' : action, '');

	const { tieBreaks } = useTiebreaks();

	useEffect(() => {
		updateProfile();
	}, [updateProfile]);

	const lhs = useRef(null) as RefObject<HTMLDivElement>;
	const [leftHeightRef, setLeftHeightRef] = useState(0);
	const calculateLhs = useCallback(() => {
		if (!lhs.current) return;
		let div = lhs.current;
		let h = div.getBoundingClientRect().height;
		setLeftHeightRef(h);
	}, [lhs, setLeftHeightRef]);
	useEffect(() => {
		calculateLhs();
	});
	// TODO: Find the dependency (based on debugging likely the Featured table)

	const announcementsRef = useRef(null) as RefObject<HTMLDivElement>;
	const [announcementsHeightRef, setAnnouncementsHeightRef] = useState(0);
	useEffect(() => {
		if (!announcementsRef.current) return;
		if (!props.announcements.length) return;
		let div = announcementsRef.current;
		let h = div.getBoundingClientRect().height;
		setAnnouncementsHeightRef(h);
	}, [props.announcements, announcementsRef, setAnnouncementsHeightRef]);

	const [allowablePlayersHeight, setAllowablePlayersHeight] = useState(0);
	useEffect(() => {
		setAllowablePlayersHeight(leftHeightRef - announcementsHeightRef);
	}, [announcementsHeightRef, leftHeightRef]);

	const leftColumn = useMemo(() => {
		return (
			<>
				{props.settings.description ?
					<div className={cx('container', styles.description)}>
						{props.settings.description}
					</div> :
					null
				}
				<div className={styles.info_organiser_container}>

					{/* Tournament Stats/Data */}
					<TournamentInfo {...props} setFloat={setFloat as (v: string) => void} />

					{/* Organiser profile card */}
					<Info
						type='user'
						id={props.status.user}
						profile={profile}
						updateProfile={updateProfile}
						updateBio={updateBio}
						className={styles.organiser_container}
					/>

				</div>

				<section className={styles.tieBreakSection}>
					<div className={cx('container', styles.tieBreakContainer)}>
						<div>
							<div className={styles.tieBreakTitle}>
								Tie Breaks
							</div>
							<div className={styles.tieBreaks}>
								{tieBreaks.slice(0, tieBreaks.findIndex(([, v]) => v === null)).map(([, v], i) => {
									if (!v) return null;
									return (
										<>
											<div key={[v, i, '1'].join(' ')}>#{i + 1}</div>
											<div key={[v, i, '2'].join(' ')}>{v.name}</div>
										</>
									);
								})}
							</div>
						</div>
					</div>
				</section>

				{/* Upcoming/Featured Matches */}
				<Featured
					playerDict={props.playerDict}
					settings={props.settings}
					id={props.id}
					round={props.round}
					status={props.status}
					profile={profile}
					direction={props.direction}
				/>

				{/* Live Stream */}
				<div id='live' className={styles.live}>
					<Live
						streamURL={props.settings.streamURL}
						pop={props.pop}
						isLive={props.isLive}
						screenWidthProportion={5/8}
					/>
				</div>
			</>
		);
	}, [props.status.user, profile, updateProfile, updateBio, props.status, props.settings, props.players, props.loaded]);

	return <> 
		<section className={styles.container}>

			<div className={styles.left_column} ref={lhs}>
				{leftColumn}
			</div>

			<div className={styles.teams_announcements}>

				{/* Registered Teams/Players */}
				<h1 className={styles.header}>Registered Players</h1>
				<div className={cx(styles.teams_list)}>
					<RegisteredPlayers 
						players={props.players}
						height={allowablePlayersHeight}
					/>
				</div>
				
				{/* Announcements */}
				{props.announcements.length ? <div className={styles.announcements} ref={announcementsRef}>
					<div className={styles.header}> Announcements </div>
					<Announcements announcements={props.announcements.map((p, i) => {
						return {
							...p,
							content: p.announcement,
							id: i.toString(),
							tournamentId: props.status.id,
							author: '',
							role: 'director',
							title: '',
							link: ''
						};
					})} />
				</div> : null}
			</div>
		</section>
	</>;
}