import React, { useEffect, useState, useCallback, useMemo, useContext } from 'react';
import { POST, GET } from '../utils/requests';
import { ProfileData, ContactEntry } from '../interfaces';
import contactFields, { ContactMeta } from '../resources/contact';
import SectionBreak from '../components/SectionBreak';
import ToggleSwitch from '../components/ToggleSwitch';
import styles from './css/user.module.css';
import { FaCheckCircle, FaSyncAlt, FaSkullCrossbones, FaAngleRight, FaTimes, FaPaperPlane } from 'react-icons/fa';
import * as callbacks from './callbacks/';
import { capitalise } from '../utils/prototype';
import FacebookLogin from 'react-facebook-login';
import { AlertContext, LocationContext } from '../Contexts';
import { getChessComTeam } from '../chess/ChessCom';

const teams = {
	chessCom: getChessComTeam
};

export default function Accounts(props: {
	profile: ProfileData
	updateProfile: () => void
	permanent?: boolean
}) {

	const [accounts, setAccounts] = useState(props.profile as any as {[key: string]: ContactEntry});
	useEffect(() => {
		setAccounts(props.profile as any as {[key: string]: ContactEntry});
	}, [setAccounts, props.profile]);
	const getAccounts = useCallback(() => GET({ url: '/user/' + props.profile.username + '/getAccounts' })
		.then(setAccounts), [setAccounts, props.profile.username]);
	const updateAccount = useCallback((k: string, v: Partial<ContactEntry>) => {
		POST({
			url: '/profile/updateAccount',
			data: {
				account: k === 'email' ? 'emailMeta' : k,
				data: v
			}
		})
			.then(setAccounts);
	}, [setAccounts]);
	const [verified, setVerified] = useState([] as [ContactMeta, ContactEntry][]);
	const [pending, setPending] = useState([] as [ContactMeta, ContactEntry][]);
	const [available, setAvailable] = useState([] as [ContactMeta, ContactEntry][]);
	useEffect(() => {
		let v = [] as [ContactMeta, ContactEntry][];
		let p = [] as [ContactMeta, ContactEntry][];
		let a = [] as [ContactMeta, ContactEntry][];
		for (let [k, meta] of Object.entries(contactFields)) {
			if (k in accounts) {
				let entry = accounts[k as keyof ProfileData] as ContactEntry;
				if (k === 'email') entry = Object.assign(accounts.emailMeta || {}, {
					id: entry as any as string,
					username: entry as any as string
				});
				Object.assign(meta, { id: k });
				if (entry?.verified) v.push([meta, entry]);
				else if (entry?.id || entry?.username) p.push([meta, entry]);
				if (!entry || meta.hasMultiple) a.push([meta, entry]);
			} else
			if (meta.canLink) a.push([meta, {} as ContactEntry]);
		}
		setVerified(v);
		setPending(p);
		setAvailable(a);
	}, [accounts, setVerified, setPending, setAvailable]);

	const { updateProfile } = props;
	const location = useContext(LocationContext);
	const { currentPath } = location.state;
	const linkLichess = callbacks.lichess.useLink({ currentPath, updateProfile });
	const verifyChessCom = callbacks.chessCom.useVerify({ profile: props.profile, updateProfile });
	const linkChessCom = callbacks.chessCom.useLink({
		updateProfile: () => {
			updateProfile();
			verifyChessCom();
		}
	});	
	const linkFacebook = callbacks.facebook.useFacebook({ updateProfile });
	const links = {
		['lichess' as string]: linkLichess,
		chessCom: linkChessCom,
		facebook: { href: linkFacebook, setWindow: () => {} }
	};
	const setAlert = useContext(AlertContext);
	const verify = useMemo(() => {
		return {
			chessCom: verifyChessCom,
			email: (force?: boolean) => POST({ url: '/profile/email/verify', data: { force } })
				.then(() => setAlert({
					title: 'Verify email account',
					message: 'An email has been sent to ' + props.profile.email + '. Check your inbox!'
				}))
				.then(updateProfile)
		} as {[key: string]: (force?: boolean) => void};
	}, [verifyChessCom, setAlert, props.profile.email, updateProfile]);
	const sync = useMemo(() => {
		return {
			lichess: () => callbacks.lichess.handleSync(updateProfile),
		} as {[key: string]: () => void};
	}, [updateProfile]);

	return (<>
		<SectionBreak name='VERIFIED ACCOUNTS' />
		{verified.map(([meta, entry], i) => {
			return <section key={['section', i].join(' ')} className={['container', styles.accountSection].join(' ')}>
				<div className={['optionsContainer', styles.optionsContainer].join(' ')}>
					<a
						id={meta.id}
						href={(meta.idLink || meta.link as string).replace('|', entry.username || entry.id as string)}
						className={['button', 'link', styles.account, styles.inline].join(' ')}
						rel='noopener noreferrer'
						target='_blank'
					>
						{typeof meta.icon[1] === 'string' ? <img src={meta.icon[1]} alt={meta.id} className={styles.icon} /> : React.cloneElement(meta.icon[1], {
							className: styles.icon
						})}
						{meta.name || capitalise(meta.id as string)}
						<FaCheckCircle className='verified' />
					</a>
					<ToggleSwitch
						on={entry.public !== false}
						onSwitch={(newState) => updateAccount(meta.id as string, { public: newState })}
						main={{
							on: 'Public',
							off: 'Hidden'
						}}
						sub={{
							on: 'Switch to hide your account from public view',
							off: 'Switch on to make visible to all signed-in users'
						}}
					/>
					{(meta.id as string) in sync ? <div className={['header-button', styles.accountButton].join(' ')} onClick={sync[meta.id as string]}>
						<div><FaSyncAlt />Sync data</div>
					</div> : null}
					{!meta.permanent ? <div className={['header-button', styles.accountButton, styles.remove].join(' ')} onClick={() => POST({ url: '/profile/removeAccount', data: { account: meta.id} }).then(setAccounts)}>
						<div><FaSkullCrossbones />Remove integration</div>
					</div> : null}
				</div>
			</section>;
		})}
		<SectionBreak name='PENDING VERIFICATION' />
		{pending.map(([meta, entry], i) => {
			return <section key={['section', i].join(' ')} className={['container', styles.accountSection].join(' ')}>
				<div className={['optionsContainer', styles.pendingContainer].join(' ')}>
					<div
						id={meta.id}
						onClick={() => {
							if (!meta.id) return;
							if (!(meta.id in verify)) return;
							if (entry.sent) return;
							let action = verify[meta.id as string];
							action();
						}}
						className={['button', 'link', styles.account, styles.inline, entry.sent ? 'sent' : ''].join(' ')}
					>
						{typeof meta.icon[1] === 'string' ? <img src={meta.icon[1]} alt={meta.id} className={styles.icon} /> : React.cloneElement(meta.icon[1], { className: styles.icon })}
						{!entry.sent ? 'Verify' : ''} {entry.username || meta.name || capitalise(meta.id as string)}
						{!entry.sent ? <FaAngleRight /> : null}
					</div>
					{entry.sent ? <div className={['header-button', styles.accountButton].join(' ')} onClick={() => {
						if (!meta.id) return;
						if (!(meta.id in verify)) return;
						let action = verify[meta.id as string];
						action(true);
					}}>
						<div><FaPaperPlane />Resend email</div>
					</div> : null}
					{!meta.permanent ? <div className={['header-button', styles.accountButton, styles.remove].join(' ')} onClick={() => POST({ url: '/profile/removeAccount', data: { account: meta.id} }).then(setAccounts)}>
						<div><FaTimes />Cancel</div>
					</div> : null}
				</div>
			</section>;
		})}
		<SectionBreak name='AVAILABLE' />
		{!available.length ? <section /> : <section className={['container', styles.accountSection].join(' ')}>
			<div className={['optionsContainer', styles.availableContainer].join(' ')}>
				{available.map(([meta, entry], i) => {
					if (meta.id && meta.id === 'facebook') {
						let rProps = {
							render: function OptionsContainer(renderProps: any) {
								return <div
									key={['entry', i].join(' ')}
									id={meta.id}
									onClick={renderProps.onClick}
									className={['button', 'link', styles.account, styles.inline].join(' ')}
								>
									{typeof meta.icon[1] === 'string' ? <img src={meta.icon[1]} alt={meta.id} className={styles.icon} /> : React.cloneElement(meta.icon[1], { className: styles.icon })}
									Link {meta.name || capitalise(meta.id as string)}
									<FaAngleRight />
								</div>;
							}
						} as any;
						return <div
							key={['entry', i].join(' ')}
							id={meta.id}
							onClick={() => {
								if (!meta.id) return;
								if (!(meta.id in links)) return;
								let action = links[meta.id as string];
								let { href, setWindow } = action;
								if (typeof href === 'function') (href as () => void)();
								else setWindow(window.open(href, '_blank'));
							}}
							className={['button', 'link', styles.account, styles.inline].join(' ')}
						>
							{typeof meta.icon[1] === 'string' ? <img src={meta.icon[1]} alt={meta.id} className={styles.icon} /> : React.cloneElement(meta.icon[1], { className: styles.icon })}
							<FacebookLogin
								appId='3423958477620478'
								fields='name,email'
								scope='public_profile'
								cssClass={styles.facebookLink}
								callback={links.facebook.href}
								{...rProps as any}
							/>
							<FaAngleRight />
						</div>;
					}
					return <div
						key={['entry', i].join(' ')}
						id={meta.id}
						onClick={() => {
							if (!meta.id) return;
							if (!(meta.id in links)) return;
							let action = links[meta.id as string];
							let { href, setWindow } = action;
							if (typeof href === 'function') (href as () => void)();
							else setWindow(window.open(href, '_blank'));
						}}
						className={['button', 'link', styles.account, styles.inline].join(' ')}
					>
						{typeof meta.icon[1] === 'string' ? <img src={meta.icon[1]} alt={meta.id} className={styles.icon} /> : React.cloneElement(meta.icon[1], { className: styles.icon })}
						Link {meta.name || capitalise(meta.id as string)}
						<FaAngleRight />
					</div>;
				})}
			</div>
		</section>}
	</>);

}