import React, { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import axios from 'axios';
import { RouterLink, parseHtml } from '../utils/plugins';

export default function MDPage({ page }: { page: string }) {

	const [str, setPage] = useState('');
	useEffect(() => {
		axios.get(`/pages/${page}.md`)
			.then(res => res.data)
			.then(setPage);
	}, [setPage, page]);

	return (
		<div id='main'>
			<section>
				<Markdown
					source={str.trim()}
					escapeHtml={false}
					astPlugins={[parseHtml]}
					renderers={{
						link: RouterLink
					}}
				/>
			</section>
		</div>
	);
}