import React, { ReactElement } from 'react';
import { Player } from '../models';
import { lichess, zoom, chessCom } from '../assets/icons';
import { FaPhone, FaFacebook, FaEnvelope, FaSkype, FaTwitter, FaReddit } from 'react-icons/fa';

const contactFields = {
	reddit: {
		value: (p: Player) => p.contact?.reddit,
		link: 'https://reddit.com/r/|',
		icon: ['svg', <FaReddit key='reddit' />],
		//hasMultiple: true
	},
	lichess: {
		name: 'Lichess.org',
		value: (p: Player) => p.lichess,
		link: 'https://lichess.org/@/|',
		icon: ['img', lichess],
		authMessage: 'Authentication via lichess.org is available to any admins of a linked lichess.org team account',
		canLink: true
	},
	chessCom: {
		name: 'Chess.com',
		value: (p: Player) => p.chessCom,
		link: 'https://chess.com/club/|',
		idLink: 'https://chess.com/members/|',
		icon: ['img', chessCom],
		authMessage: 'Authentication via chess.com is available to any admins of a linked chess.com club account',
		canLink: true
	},
	zoom: {
		value: (p: Player) => p.contact?.zoom,
		link: 'https://us02web.zoom.us/user/|/profile',
		icon: ['img', zoom]
	},
	skype: {
		value: (p: Player) => p.contact?.skype,
		icon: ['svg', <FaSkype key='skype' />]
	},
	facebook: {
		value: (p: Player) => p.contact?.facebook,
		link: 'https://facebook.com/|',
		//idLink: 'https://facebook.com/profile.php?id=|',
		icon: ['svg', <FaFacebook key='facebook' />],
		authMessage: 'Authentication via Facebook is not available at this time',
		canLink: true,
		//hasMultiple: true
	},
	twitter: {
		value: (p: Player) => p.contact?.facebook,
		link: 'https://twitter.com/|',
		icon: ['svg', <FaTwitter key='twitter' />],
		// canLink: true,
		//hasMultiple: true
	},
	phone: {
		value: (p: Player) => p.contact?.phone,
		icon: ['svg', <FaPhone key='phone' />]
	},
	email: {
		value: (p: Player) => p.contact?.email,
		icon: ['svg', <FaEnvelope key='envelope' />],
		link: 'mailto:|',
		permanent: true,
		authMessage: 'Authentication via email requires a matching verified email address',
		canLink: true,
		//hasMultiple: true
	}
} as {
	[key: string]: ContactMeta
};

export default contactFields;

export interface ContactMeta {
	id?: string
	value: (p: Player) => string | undefined
	link?: string
	idLink?: string
	icon: ['img' | 'svg', ReactElement | string]
	name?: string
	permanent?: boolean
	authMessage?: string
	canLink?: boolean,
	hasMultiple?: boolean
}