import React, { useEffect, createRef, useState, useCallback } from 'react';

interface FormProps<T> {
	onChange: (validOptions: T[]) => void
	onSubmit: (value: T) => void
    action: string
	options: T[]
	convert: (option: T) => string
	styles?: {[key: string]: string}
	placeholder: string
}

export default function AutoComplete<T>(props: FormProps<T>) {

	const firstInput = createRef() as React.RefObject<HTMLInputElement>;
	const [currChoice, setCurrChoice] = useState(null as T | null);
	const [focus, mustFocus] = useState(false);

	useEffect(() => {
		if (!focus) return;
		if (!firstInput.current) return;
		firstInput.current.focus();
	}, [firstInput, focus]);
	
	const handleSubmit = useCallback(() => {
		if (!currChoice) return;
		props.onSubmit(currChoice);
	}, [currChoice, props.onSubmit]);

	const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
		switch (e.keyCode) {
		case 13: {
			if (currChoice) {
				(e.target as HTMLInputElement).value = '';
				handleSubmit();
			}
			e.preventDefault();
			break;
		}
		}
	}, [currChoice, handleSubmit]);

	const handleInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		let event = e.nativeEvent as InputEvent;
		let input = e.target as HTMLInputElement;
		let text = input.value;
		let length = text.length;
		let choices = props.options.filter(c => props.convert(c).toLowerCase().startsWith(text.toLowerCase()));
		props.onChange(choices);
		if (!event.data) return;
		if (!text.trim()) {
			setCurrChoice(null);
			return;
		}
		let firstChoice = choices[0];
		setCurrChoice(firstChoice);
		if (!firstChoice) return;
		let autofill = props.convert(firstChoice);
		input.value = autofill;
		input.select();
		input.setSelectionRange(length, autofill.length, 'forward');
	}, [props.options]);
	
	return (
		<div className={props.styles?.scoreSection}>
			<input type='text' className={[props.styles?.input].join(' ')} name='value' required
				placeholder={props.placeholder || 'Search'}
				onInput={handleInput}
				onKeyDown={handleKeyDown}
				onSubmit={handleSubmit}
				autoComplete={'off'}					
			/>
		</div>
	);
}