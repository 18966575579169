import React from 'react';

export default function Circle(props: {
	diameter: number
	width: number
	className?: string
}) {
	return (
		<div style={{
			transform: `scale(${props.diameter / 254})`,
			width: 254,
			height: 254
		}} className={props.className}>
			<svg width={254} height={254}>
				<linearGradient id="linearColors1" x1="0" y1="0" x2="1" y2="1">
					<stop offset="0%" stopColor="#01E400"></stop>
					<stop offset="100%" stopColor="#FEFF01"></stop>
				</linearGradient>
				<linearGradient id="linearColors2" x1="0.5" y1="0" x2="0.5" y2="1">
					<stop offset="0%" stopColor="#FEFF01"></stop>
					<stop offset="100%" stopColor="#FF7E00"></stop>
				</linearGradient>
				<linearGradient id="linearColors3" x1="1" y1="0" x2="0" y2="1">
					<stop offset="0%" stopColor="#FF7E00"></stop>
					<stop offset="100%" stopColor="#FB0300"></stop>
				</linearGradient>
				<linearGradient id="linearColors4" x1="1" y1="1" x2="0" y2="0">
					<stop offset="0%" stopColor="#FB0300"></stop>
					<stop offset="100%" stopColor="#9B004A"></stop>
				</linearGradient>
				<linearGradient id="linearColors5" x1="0.5" y1="1" x2="0.5" y2="0">
					<stop offset="0%" stopColor="#9B004A"></stop>
					<stop offset="100%" stopColor="#7D0022"></stop>
				</linearGradient>
				<linearGradient id="linearColors6" x1="0" y1="1" x2="1" y2="0">
					<stop offset="0%" stopColor="#7D0022"></stop>
					<stop offset="100%" stopColor="#01E400"></stop>
				</linearGradient>
				<path d="M126 10 a120 120 0 0 1 103.9230 60"
					fill="none" stroke="url(#linearColors1)" strokeWidth={props.width} />
				<path d="M229.9230 70 a120 120 0 0 1 0 120"
					fill="none" stroke="url(#linearColors2)" strokeWidth={props.width} />
				<path d="M229.9230 190 a120 120 0 0 1 -103.9230 60"
					fill="none" stroke="url(#linearColors3)" strokeWidth={props.width} />
				<path d="M126 250 a120 120 0 0 1 -103.9230 -60"
					fill="none" stroke="url(#linearColors4)" strokeWidth={props.width} />
				<path d="M22.077 190 a120 120 0 0 1 0 -120"
					fill="none" stroke="url(#linearColors5)" strokeWidth={props.width} />
				<path d="M22.077 70 a120 120 0 0 1 103.9230 -60"
					fill="none" stroke="url(#linearColors6)" strokeWidth={props.width} />
			</svg>
		</div>
	);
}