import { History, Bonus, TournamentSettings, Player as PlayerInterface } from '../interfaces';
import { avMode } from '../utils/prototype';

const templateD = {
	score: 0,
	gamePoints: 0,
	played: 0,
	gamesPlayed: 0,
	gameDifference: 0,
	expectedScore: 0,
	expectedGameScore: 0,
	whiteTotal: 0,
	wins: 0,
	draws: 0,
	losses: 0,
	matchPercentage: 0,
	gamePercentage: 0,
	ratingDifference: 0
};
export default class Player implements PlayerInterface {
	constructor(m: {[key: string]: any}, private round: number, public settings: TournamentSettings) {
		let proxied = Object.keys(templateD);
		for (let prop of Object.keys(m)) {
			if (!proxied.includes(prop)) {
				Object.defineProperty(this, prop, {
					value: m[prop]
				});
			}
		}
		if (this.id === 'bye') return;
		this.compute(round, settings);
	}

	public static bye(tournamentId: string, round: number, settings: TournamentSettings): Player {
		return new Player({
			id: 'bye',
			tournamentId,
			rating: 0,
			name: 'BYE',
			firstName: '',
			lastName: '',
			histories: []
		}, round, settings);
	}

	setRound(round: number) {
		this.round = round;
		this.compute(round, this.settings);
	}
	setSettings(settings: TournamentSettings) {
		this.settings = settings;
		this.compute(this.round, settings);
	}
	setBonuses(bonuses: Bonus[]) {
		this.bonuses = bonuses.filter(b => b.id === this.id);
		this.compute(this.round, this.settings);
	}

	calcPool(from?: number, to?: number): number {
		let histories = to ? this.histories.slice(from, to) : this.histories;
		return Number(avMode(histories.map(h => h?.pool)));
	}

	compute(round: number, settings: TournamentSettings) {
		let d = Object.assign({}, templateD) as {[key: string]: number};
		for (let i = 1; i < round + 1; i++) {
			let h = this.histories[i];
			if (!h) continue;
			if (h.match !== null) {
				d.played++;
				if (h.id !== 'bye') {
					if (h.played) d.gamesPlayed += h.played;
				}
				if (h.match === 0) {
					d.score += settings.lossReward;
					d.losses++;
				} else
				if (h.match === 0.5) {
					d.score += settings.drawReward;
					d.draws++;
				} else
				if (h.match === 1) {
					d.score += settings.winReward;
					d.wins++;
				}
				if (h.game) d.gamePoints += h.game;
				d.expectedScore += h.expected;
				d.expectedGameScore += h.expected * h.played;
			}
			if (h.colour === 'W') d.whiteTotal++;
		}
		Object.assign(d, {
			gameDifference: d.gamePoints * 2 - d.gamesPlayed,
			matchPercentage: d.played ? d.score * 100 / d.played : -1,
			gamePercentage: d.gamesPlayed ? d.gamePoints * 100 / d.gamesPlayed : -1,
			ratingDifference: this.performanceRating - this.rating
		});
		if (this.bonuses) {
			for (let b of this.bonuses) {
				if (b.round > round) continue;
				if (b.MP) d.score += b.MP;
				if (b.GP) d.gamePoints += b.GP;
			}
		}
		for (let prop of Object.keys(d)) {
			Object.defineProperty(this, prop, {
				value: d[prop],
				configurable: true
			});
		}


		let scores = [''] as string[];
		for (let i = 1; i < this.histories.length; i++) {
			if (i > round || !this.histories[i]) {
				scores[i] = '';
				continue;
			}
			if (this.histories[i].match === null || this.histories[i].match === undefined) scores[i] = '*';
			else scores[i] = (this.histories[i].match as number).toString();
			if (this.histories[i].id === 'bye') scores[i] += 'b';
		}
		this.rounds = scores;

		return this;
	}
	public rounds!: string[]

	public tournamentId!: string;
	public id!: string
	public firstName!: string
	public lastName!: string
	public rating!: number
	public nationality!: string

	public score!: number
	public gamePoints!: number
	public played!: number
	public expectedScore!: number
	public expectedGameScore!: number
	public whiteTotal!: 0
	public isPlaying!: string | null;
	public active!: boolean;
	public performanceRating!: number;

	public histories!: History[]
	public bonuses!: Bonus[]
	public clashList!: string[]
	public contact?: {
		email?: string
		zoom?: string
		skype?: string
		reddit?: string
		facebook?: string
		phone?: string
	}
	public lichess!: string
	public chessCom!: string
	public meta?: {
		[key: string]: any
	}
	public teamId?: string
	public pool?: number

	// timestamps!
	public readonly createdAt!: Date;
	public readonly updatedAt!: Date;

	get rank(): number {
		return 0;
	}
	get name(): string {
		return this.settings.competitors === 'individual' ? [this.firstName, this.lastName].join(' ') : this.firstName;
	}

	hasPlayed(id: string): boolean {
		if (!this.histories) return false;
		return this.histories.some(h => h.id && h.id === id);
	}
	
	scoreAgainst(id: string): number {
		if (!this.histories) return 0;
		return this.histories.reduce((acc, curr) => curr && curr.id === id && curr.match ? acc += curr.match : acc, 0);
	}
	
	gameScoreAgainst(id: string): number {
		if (!this.histories) return 0;
		return this.histories.reduce((acc, curr) => curr && curr.id === id && curr.game ? acc += curr.game : acc, 0);
	}

		private _netGamePoint!: number;
		get netGamePoint(): number {
			if (this._netGamePoint) return this._netGamePoint;
			if (!this.histories) return 0;
			let matchPoints = 0;
			let played = 0;
			this.histories.forEach((curr) => {
				if (!curr) return;
				matchPoints += curr.game || 0;
				played += curr.played;
			});
			return this._netGamePoint = matchPoints * 2 - played;
		}

		private _progressiveScore!: number;
		get progressiveScore(): number {
			if (this._progressiveScore) return this._progressiveScore;
			if (!this.histories) return 0;
			let prog = 0;
			for (let h of this.histories) {
				if (!h) continue;
				if (h.match) prog += h.match;
			}
			return this._progressiveScore = prog;
		}

		private _oppRatingSum!: number;
		get oppRatingSum(): number {
			if (this._oppRatingSum) return this._oppRatingSum;
			if (!this.histories) return 0;
			let sum = 0;
			for (let h of this.histories) {
				if (!h) continue;
				sum += h.oppRating;
			}
			return this._oppRatingSum = sum;
		}
}