import { Player, TournamentSettings } from '../../../interfaces';


export default function useDisplay(settings: TournamentSettings) {

	function getName(opp: Player) {
		if (!opp || !opp.id) return '\u200b';
		if (opp.id === 'bye') return 'BYE';
		
		let parts = [] as string[];
		
		if (settings.competitors === 'individual') parts.push([opp.firstName, opp.lastName].join(' '));
		else parts.push(opp.firstName);
		if (settings.showRatings) parts.push(`(${opp.rating})`);

		return parts.join(' ');
	}

	return { getName };
}