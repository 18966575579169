import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styles from '../css/results.module.css';
import '../css/fireworks.css';
import { GiTrophyCup, GiTrophy } from 'react-icons/gi';
import { IoIosTrophy } from 'react-icons/io';
import { DisplayNameContext } from '../Contexts';
import Player from '../models/Player';
import { columns } from '../resources/columns';
import { TournamentSettings } from '../interfaces';
import { getFlag } from '../utils/prototype';

interface ResultsProps {
	direction: string
	id: string
	state: string[]
	playerDict: Map<string, Player>
	settings: TournamentSettings
}

export default function Results(props: ResultsProps) {

	const getDisplayName = useContext(DisplayNameContext);

	function Description({ id }: { id: string }) {
		let player = props.playerDict.get(id) as Player;
		if (!player) return null;
		return <div className={styles.table}>
			{player && player.nationality ? <>
				<div className={styles.key}>Nationality</div>
				<div className={[styles.flag, styles.value].join(' ')}>
					{getFlag(player.nationality)}
				</div>
			</> : null}
			{Object.entries(columns.post).map(([k, meta], i) => {
				if (meta.show && !meta.show(player, i, props.settings, 'results')) return null;
				return <>
					<div className={styles.key}>{meta.mobile}</div>
					<div className={styles.value}>{meta.convert ? meta.convert(player[k as keyof Player], player, props.settings, 'results') : player[k as keyof Player]?.toString()}</div>
				</>;
			})}
		</div>;
	}

	return <>
		<section className={[styles.fireworksSection, 'animated-' + props.direction].join(' ')}>
			<div className={'pyro'}>
				<div className={'before'}></div>
				<div className={'after'}></div>
			</div>
			<div className={styles.resultsScreen}>
				<div id='first' className={styles.place}>
					<GiTrophyCup />
					<div className={styles.position}>
						1<sup>st</sup>
					</div>
					<div className={styles.teamName}>
						{getDisplayName(props.state[0])}
					</div>
					<Description id={props.state[0]} />
				</div>
				<div id='second' className={styles.place}>
					<GiTrophy />
					<div className={styles.position}>
						2<sup>nd</sup>
					</div>
					<div className={styles.teamName}>
						{getDisplayName(props.state[1])}
					</div>
					<Description id={props.state[1]} />
				</div>
				<div id='third' className={styles.place}>
					<IoIosTrophy />
					<div className={styles.position}>
						3<sup>rd</sup>
					</div>
					<div className={styles.teamName}>
						{getDisplayName(props.state[2])}
					</div>
					<Description id={props.state[2]} />
				</div>
			</div>
		</section>
		<section>
			<div className={['container', styles.links].join(' ')}>
				{props.settings.competitors === 'team' ?
					<Link to={`/t/${props.id}/standings/individual`} className={['header-button', styles.standingsButton].join(' ')}>
						View Individual Results
					</Link> :
					null
				}
				<Link to={`/t/${props.id}/standings/final`} className={['header-button', styles.standingsButton].join(' ')}>
					View Final Standings
				</Link>
			</div>
		</section>
	</>;
}