import React, { useState, useRef, RefObject, useEffect, useCallback, useReducer, useMemo } from 'react';
import styles from './css/embed.module.css';
import cx from 'classnames';

interface EmbedProps {
	search: URLSearchParams
}

export default function Embed(props: EmbedProps) {

	const cover = useRef() as RefObject<HTMLDivElement>;
	const [state, mutateState] = useReducer((s: number) => s + 1, 0);
	const [possible, setPossible] = useState([] as HTMLDivElement[]);
	
	const index = useMemo(() => {
		let iter = props.search.get('iteration');
		if (!iter) return 0;
		if (!isNaN(parseInt(iter))) return 0;
		return parseInt(iter);
	}, [props.search]);

	const curr = useMemo(() => possible[index], [possible, index]);
	const [rect, updateRect] = useState({} as DOMRect);
	useEffect(() => {
		if (!curr) return;
		let f = () => updateRect(curr.getBoundingClientRect());
		f();
		let t = [] as NodeJS.Timeout[];
		for (let i = 0; i < 10; i++) t.push(setTimeout(f, i * 500));
		return () => {
			for (let timeout of t) clearTimeout(timeout);
		};
	}, [curr]);

	const scale = useMemo(() => {
		let width = props.search.get('width');
		if (!width) return 1;
		return parseInt(width) / rect.width;
	}, [props.search, rect]);


	const load = useCallback(() => {
		const query = `[data-embed-id="${props.search.get('embed')}"]`;
		let elems = document.querySelectorAll(query);
		setPossible(Array.from(elems) as HTMLDivElement[]);
	}, [props.search]);
	useEffect(() => {
		if (possible.length) return;
		if (state > 10) return;
		let f = () => {
			load();
			mutateState();
		};
		if (!state) f();
		let x = setTimeout(f, 1000);
		return () => clearTimeout(x);
	}, [possible, load, mutateState, state]);

	useEffect(() => {
		if (!cover.current) return;
		if (!curr) return;
		cover.current.appendChild(curr);
	}, [cover, curr]);

	return (
		<div className={cx(styles.cover, 'scrollable')}>
			<div className={styles.canvas} ref={cover} style={{
				height: rect.height,
				width: rect.width,
				transform: `scale(${scale})`
			}} >
				<a className={styles.brand} href={window.location.href.replace(('?') + window.location.search, '')} target='_blank' rel='noopener noreferrer'>
					<img src='/images/brand.png' />
				</a>
			</div>
		</div>
	);
}