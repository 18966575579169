import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { getChessComProfile, getChessComTeam, Chess } from '../../chess/ChessCom';
import { POST, GET } from '../../utils/requests';
import { AlertContext } from '../../Contexts';
import { ProfileData, ContactEntry } from '../../interfaces';
import contactFields, { ContactMeta } from '../../resources/contact';
import { TeamData } from 'chess-web-api';
import { capitalise, isVisible } from '../../utils/prototype';

export function useLink (props: {
	updateProfile: () => void
}) {

	const setAlert = useContext(AlertContext);

	function href() {
		setAlert({
			title: 'Link Chess.com account',
			message: 'Enter Chess.com account to link:',
			type: 'prompt',
			icon: 'chess.com',
			resolve: handleHref
		});
	}

	async function handleHref(username: string) {
		if (!username) return;
		let profile = await getChessComProfile(username.trim());
		if (!profile) return console.error('Invalid username to link');
		POST({
			url: '/profile/chessCom/link',
			data: {
				username,
				name: profile.name,
				nationality: profile.country?.split('/').pop(),
				title: profile.title
			}
		})
			.then(props.updateProfile);
	}

	return { href, setWindow: () => {} };

}
const def = {
	'@id': '',
	name: ''
};
let cache = {} as {[key: string]: string};
export function useClub({ elem }: { elem?: null | HTMLElement | HTMLDivElement} = {}) {

	const [link, setLink] = useState('');
	const [fetched, setFetched] = useState(def as TeamData);

	const [visible, setVisible] = useState(false);

	const getClubName = useCallback((meta: ContactMeta, entry: ContactEntry) => {
		if (entry.name) return entry.name;
		if (entry.username) return entry.username;
		if (entry.id) return entry.id;
		if (meta.id !== 'chessCom') return capitalise(meta.id as string);
		if (fetched.name) return fetched.name;
		if (entry.link) {
			if (link in cache) return cache[link];
			if (!link && visible) setLink(entry.link);
		}
		return capitalise(meta.id);
	}, [link, fetched.name, setLink, visible]);

	useEffect(() => {
		setVisible(isVisible(elem));
	}, [setVisible, elem]);
	useEffect(() => {
		let f = () => setVisible(isVisible(elem));
		window.addEventListener('scroll', f);
		return () => window.removeEventListener('scroll', f);
	}, [setVisible, elem]);

	const h = useHistory();

	useEffect(() => {
		return h.listen((location) => {
			if (!location.pathname.startsWith('/teams')) {
				Chess.clearQueue();
			}
		});
	}, [h]);

	useEffect(() => {
		if (!link) return;
		let id = link.split('/').pop() as string;
		getChessComTeam(id).then(setFetched);
	}, [link]);

	return { getClubName, chessComClub: fetched };

}

export function useVerify (props: {
	profile: ProfileData
	updateProfile: () => void
}) {

	const setAlert = useContext(AlertContext);
	const [chessComToken, setChessComToken] = useState('');
	const getChessComToken = useCallback(() => GET({ url: '/profile/chessCom/token' })
		.then(setChessComToken), [setChessComToken]);
	const { username } = props.profile.chessCom || {};

	useEffect(() => {
		if (!username) return;
		if (chessComToken) return;
		getChessComToken();
	}, [chessComToken, getChessComToken, username]);

	return async function verify() {
		if (props.profile.chessCom?.verified) return;
		await POST({ url: '/profile/chessCom/verify' })
			.then(props.updateProfile)
			.catch(() => {});
		setAlert({
			title: 'Verify your chess.com account',
			icon: <img src={contactFields.chessCom.icon[1] as string} alt='chessCom' />,
			link: (contactFields.chessCom.idLink as string).replace('|', props.profile.chessCom?.username || ''),
			message: <>
				<p>Head to your chess.com profile and click 'Enter a status here'.
				Copy and paste the following code into your status. Don't worry! You can change it back afterwards!</p>
				<span className='mono'>SCORCH_TOKEN={chessComToken}</span>
				<p>Click <button onClick={() => POST({ url: '/profile/chessCom/verify' }).then(() => setAlert({ title: '', message: '' })).then(props.updateProfile)}>here</button> when done.</p>
			</>
		});
	};

}