import React, { useReducer, useRef, useEffect, useCallback, useState, ReactElement } from 'react';
import styles from './css/dropdown.module.css';
import { FaAngleLeft } from 'react-icons/fa';
import { capitalise } from '../utils/prototype';

export default function Dropdown(props: {
	current: string
	options: string[]
	resolve: (v: string) => void
	headerIcon?: ReactElement | string
	expanded?: boolean
	className?: string
}) {

	const [expanded, setExpanded] = useReducer((state) => !state, !!props.expanded);
	const [height, setHeight] = useState(0);
	const ul = useRef() as React.RefObject<HTMLUListElement>;
	
	const updateHeight = useCallback(() => {
		if (!ul.current) return;
		setHeight(expanded ? ul.current.getBoundingClientRect().height : 0);
	}, [setHeight, expanded, ul]);
	useEffect((): void => {
		updateHeight();
	}, [updateHeight]);

	return (
		<div className={[styles.container, props.className].join(' ')}>
			<div className={['button', styles.header].join(' ')} onClick={() => setExpanded()}>
				{props.headerIcon}
				{capitalise(props.current)}
				<FaAngleLeft className={styles.toggle} style={{
					transform: expanded ? 'rotate(-90deg)' : 'rotate(0deg)'
				}}/>
			</div>
			<div className={styles.wrapper} style={{ height }}>
				<ul className={styles.list} ref={ul}>
					{props.options.map((l, i) => <li key={[l, i].join('.')} onClick={() => {
						props.resolve(l);
						setExpanded();
					}}>{capitalise(l)}</li>)}
				</ul>
			</div>
		</div>
	);
}