import React, { useContext } from 'react';
import url from 'url';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import styles from './css/contact.module.css';
import { server, FORM } from '../utils/requests';
import { AlertContext, LoginContext } from '../Contexts';
import useContact from '../user/hooks/useContact';

export default function Contact() {

	const setAlert = useContext(AlertContext);
	const { isLoggedIn, profile } = useContext(LoginContext);
	const { links } = useContact({
		profile: {
			reddit: {
				name: '/r/scorchchess',
				username: 'scorchchess'
			},
			facebook: {
				name: '/scorchchess',
				username: 'scorchchess'
			},
			twitter: {
				name: '/@/scorchchess',
				username: 'scorchchess'
			},
			email: {
				name: 'Aloysius Lip, Founder',
				username: 'aloysius.lip@gmail.com'
			}
		},
		forceFacebook: true
	});

	return (
		<div id='main'>
			<section id='contact'>
				<h1>Contact</h1>
				<div className={styles.content}>
					ScorchChess Developers are available at the following links:
					<div className={styles.list}>
						{links}
					</div>
				</div>
			</section>
			<section id='feedback'>
				<h1>Feedback</h1>
				<div className={styles.content}>
					You can go to our <Link to='https://reddit.com/r/scorchchess'>subreddit</Link> and leave a post there to give feedback.
					Alternatively, fill in the following form:
				</div>
				{!isLoggedIn ?
					<Link to='/login'>Log in or register to send feedback</Link> :
					<div className={styles.formContainer}>
						<form
							className={styles.form}
							method='POST'
							action={url.resolve(server, 'profile/submitFeedback')}
							onSubmit={(e) => FORM(e).then((url: string) => {
								setAlert({
									title: 'Submitted feedback',
									message: <>
										Thanks for submitting feedback, it's much appreciated!
										View your post here: <a href={url} target='_blank' rel='noopener noreferrer'>{url}</a>
									</>
								});
								(e.target as HTMLFormElement).reset();
							})}
						>
							<fieldset className={styles.fieldSet}>
								<legend>Your feedback</legend>
								<input name='title' type='text' required placeholder='Your title' maxLength={!profile ? 300 : 300 - `[${profile.username}]: `.length} />
								<select name='type' id='type'>
									<option value='suggestion'>Suggestion</option>
									<option value='bug'>Bug</option>
									<option value='other'>Other</option>
								</select>
								<textarea name='content' className={styles.textArea} placeholder='Your text' required maxLength={40000} />
								<input className={cx(styles.submit, 'button')} type='submit' value='Submit' />
							</fieldset>
						</form>
					</div>
				}
			</section>
		</div>
	);
}