import React from 'react'
import PropTypes from 'prop-types'
import { months } from './constants'

class BoardHeader extends React.Component {
  render() {
    const { headerInfo } = this.props

    if (!headerInfo) return null

	const link = headerInfo.Link;
    const whiteName = headerInfo.White
    const blackName = headerInfo.Black
    const whiteTeam = headerInfo.WhiteTeam
    const blackTeam = headerInfo.BlackTeam
    const blackElo = headerInfo.BlackElo
    const whiteElo = headerInfo.WhiteElo
	const round = headerInfo.Round
	const game = headerInfo.Game
    const result = headerInfo.Result
    const event = headerInfo.Event
    const site = headerInfo.Site
    const date = headerInfo.Date.split('.')
    const textDate = date && `${date[2]} ${months[new Number(date[1]) - 1]} ${date[0]}`
    const eventInfo = event && site ? ` ${event} | ${site} | ${result} ` : null
    const gameInfo = textDate && round && game ? ` ${textDate} | Round ${round} | Game ${game} ` : null

    const pgnHeaderStyles = {
      padding: '5px',
    }

    const nameStyles = {
      fontWeight: 'bold',
      marginBottom: '0.25rem'
    }

    const detailStyles = {
      fontSize: '14px'
    }

    return (
      <div className="pgnHeader" style={pgnHeaderStyles}>
        {link ? 
			<a href={link} rel='noopener noreferrer' target='_blank' style={nameStyles}>{whiteName} ({whiteElo}) vs. {blackName} ({blackElo})</a> :
			<div style={nameStyles}>{whiteName} ({whiteElo}) vs. {blackName} ({blackElo})</div>
		}
        {whiteTeam || blackTeam ? <div style={nameStyles}>{whiteTeam} vs. {blackTeam}</div> : null}
        <div style={detailStyles}>{eventInfo}</div>
        <div style={detailStyles}>{gameInfo}</div>
      </div>
    )
  }
}

BoardHeader.propTypes = {
  headerInfo: PropTypes.object.isRequired
}


export default BoardHeader
