import React, { useEffect, useRef, RefObject, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import styles from '../css/components.module.css';

export default function SectionBreak(props: {
	name: string
	expanded?: boolean
}) {

	const [show, toggle] = useState(props.expanded ?? true);
	const divRef = useRef(null) as RefObject<HTMLDivElement>;
	const { current } = divRef;
	useEffect(() => {
		if (typeof props.expanded === 'boolean') toggle(props.expanded);
	}, [props.expanded, toggle]);

	useEffect(() => {
		if (!current) return;
		let section = current.nextElementSibling as HTMLElement;
		while (section && section.className.includes('container')) {
			if (!section) return;
			if (!section.style.overflow) section.style.overflow = 'hidden';
			if (!section.style.transition) section.style.transition = 'all 0.333s ease';
			if (show) {
				section.removeAttribute('style');
			} else {
				Object.assign(section.style, {
					maxHeight: '0px',
					paddingTop: '0px',
					paddingBottom: '0px',
					marginTop: '0px',
					marginBottom: '0px'
				});
			}
			section = section.nextElementSibling as HTMLElement;
		}
	});

	return (		
		<div ref={divRef} className={[styles.divide, show ? '' : styles.collapsed].join(' ')} onClick={() => toggle(!show)}>
			<hr />
			{props.name}
			<FaChevronDown />
			<hr />
		</div>
	);
}