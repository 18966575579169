import React, { useState, useContext, useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import styles from '../css/floated.module.css';
import url from 'url';
import { LoginContext, AlertContext } from '../../Contexts';
import { GET, server, FORM, cookies } from '../../utils/requests';
import * as regexes from '../../utils/regexes';
import { ProfileData, Team, WaitingGameLink } from '../../interfaces';
import { FaCircleNotch, FaEye, FaCircle, FaDotCircle, FaRegCircle } from 'react-icons/fa';

const hours = 12;

export default function GameLink() {

	const [hidden, setHidden] = useState(false);
	const updateHidden = useCallback(async () => {
		if (window.location.search.includes('refresh')) {
			await cookies.remove('linkHidden').catch(() => {});
			setHidden(false);
		}
		let h = await cookies.get('linkHidden').catch(() => {});
		if (!h || isNaN(Number(h))) setHidden(false);
		if (Date.now() - Number(h) < hours * 60 * 60 * 1000) setHidden(true);
	}, [setHidden]);
	useEffect(() => {
		updateHidden();
	}, [updateHidden]);
	const hide = useCallback(async (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		await cookies.set('linkHidden', Date.now()).catch(() => {});
		setHidden(true);
	}, [setHidden]);

	const { isLoggedIn, profile } = useContext(LoginContext);

	const [player, setPlayer] = useState(undefined as Team & ProfileData | null | undefined);
	const updatePlayer = useCallback(async () => {
		if (!profile || !profile.username) return;
		if (!regexes.username.test(profile.username)) return setPlayer(null);
		GET({ url: '/user/' + profile.username, params: { fetchGameLinks: true } })
			.then(setPlayer);
	}, [setPlayer, profile]);
	useEffect(() => {
		if (!isLoggedIn) return;
		updatePlayer();
	}, [updatePlayer, isLoggedIn]);

	const teams: WaitingGameLink[] = useMemo(() => {
		if (!player) return [];
		let links = player.teams.map(t => t.gameLinks.map(g => Object.assign({}, g, { team: t.name }))).flat();
		return links;
	}, [player]);
	const [index, setIndex] = useState(0 as number | undefined);
	const team = useMemo(() => index === undefined ? null : teams[index], [teams, index]);

	const [isLoading, startLoading] = useState(true);
	const setAlert = useContext(AlertContext);
	const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		startLoading(true);
		let form = e.target as HTMLFormElement;
		while (form.parentElement && form.constructor.name !== 'HTMLFormElement') form = form.parentElement as HTMLFormElement;
		let input = form.link;
		let value = input?.value.trim();
		if (!value) {
			startLoading(false);
			return;
		}
		if (team?.source === 'chessCom') {
			if (!regexes.chessCom.test(value)) {
				startLoading(false);
				setAlert({
					title: 'Link error',
					message: 'Link does not match format for a chess.com link',
					type: 'error'
				});
				return;
			} else {
				//TODO: Lichess
			}
		}
		FORM(e).then(() => setPlayer(undefined)).then(updatePlayer).finally(() => startLoading(false));
	}, [updatePlayer, team, startLoading, setAlert]);

	const placeholder = useMemo(() => {
		if (team?.source === 'chessCom') {
			return 'Ex: https://chess.com/live#tm=xxxxx';
		}
		return '';
	}, [team]);

	const buttons = useMemo(() => {
		let arr = [];
		for (let i = 0; i < teams.length; i++) {
			if (i === index) arr.push(<FaCircle key={cx('circle', i)} className={cx(styles.dot)} onClick={() => setIndex(undefined)} />);
			else arr.push(<FaRegCircle key={cx('circle', i)}  className={cx(styles.dot)} onClick={() => setIndex(i)} />);
		}
		return arr;
	}, [teams, index]);

	if (!profile) return null;
	if (!teams.length) return null;
	if (hidden) return null;

	return <div className={cx(styles.top, styles.right, styles.container)} data-aos='fade-left'>
		<div className={cx(styles.linkSwitcher)}>
			{buttons}
		</div>
		{team ?
			<div className={cx('container')} data-aos='fade-left'>
				<div className={styles.title}>
					You have one link from <b>{team.name}</b> waiting for team <b>{team.team}</b>
				</div>			
				<form
					className={styles.formWrapper}
					method='POST'
					action={url.resolve(server, 'submit/gameLink')}
					onSubmit={handleSubmit}
				>
					<div className={styles.input}>
						<input type='hidden' name='id' value={team.id} />
						<input type='hidden' name='tournamentId' value={team.tournamentId} />
						<input type='text' name='link' placeholder={placeholder} />
					</div>
					<div className={styles.buttons}>
						<button className='button' onClick={hide}>Later</button>
						<Link className={cx('button', styles.viewTournament)} to={'/t/' + team.tournamentId}>
							<FaEye />
						</Link>
						<button className='button'>Submit</button>
					</div>
				</form>
			</div> :
			null
		}
	</div>;
}