import React, { useState, useCallback, useEffect } from 'react';
import Announcements from '../tournament/Announcements';
import { GET } from '../utils/requests';
import { Notif } from '../interfaces';
import styles from './css/news.module.css';

export default function News() {

	const [posts, setPosts] = useState([] as Notif[]);
	const updatePosts = useCallback(() => GET({ url: '/announcements' })
		.then(setPosts), [setPosts]);
	useEffect(() => {
		updatePosts();
	}, [updatePosts]);

	return <div id='main'>
		<div className={styles.news}>
			<Announcements announcements={posts.map(p => ({
				...p,
				createdAt: new Date(p.createdAt).valueOf() * 1000,
				updatedAt: new Date(p.updatedAt).valueOf() * 1000
			}))} />
		</div>
	</div>;
}