import React, { ReactElement, useMemo } from 'react';
import { Match } from '../../models';
import Chartist from 'react-chartist';
import { IPieChartOptions, Svg } from 'chartist';

interface PieProps {
	matches: {
		[key: string]: Match
	}
	round: number
	total: number
	donutWidth?: number,
	height?: number
}

const def = {
	aborted: 0,
	pending: 0,
	scheduled: 0,
	registered: 0,
	live: 0,
	finished: 0,
	confirmed: 0
} as {[key: string]: number};

export default function PercentageCompleted(props: PieProps): ReactElement {

	const percentages = useMemo(() => {		
		if (!props.matches) return def;
		let totals = Object.assign({}, def, {pending: props.total});
		Object.values(props.matches).forEach((curr) => {
			if (!curr) return;
			if (curr.round !== props.round) return;
			totals[curr.status]++;
			totals.pending--;
		});
		return totals;
	}, [props.matches, props.round, props.total]);

	const myChart = useMemo(() => {
		return <Chartist
			type='Pie'
			data={{
				labels: Object.entries(percentages).map(([k, v]) => `${k} ${Math.round(v * 100 / props.total)}% (${v})`),
				series: Object.entries(percentages).map(([k, v]) => {
					return {meta: k, value: v};
				})
			}}
			options={{
				donut: true,
				donutSolid: true,
				donutWidth: props.donutWidth || 60,
				startAngle: 270,
				total: props.total * 2,
				height: props.height || 400,
				ignoreEmptyValues: true,
				showLabel: true,
				labelPosition: 'inside'
			} as IPieChartOptions}
			listener={{
				draw: (data: any) => {
					if(data.type === 'slice') {
						let pathLength = data.element._node.getTotalLength();
						let animationDefinition = {
							'stroke-dashoffset': {
								id: 'anim' + data.index,
								dur: 1000,
								from: -pathLength + 'px',
								to:	'0px',
								easing: Svg.Easing.easeOutQuint,
								// We need to use `fill: 'freeze'` otherwise our animation will fall back to initial (not visible)
								fill: 'freeze',
								begin: undefined as string | undefined
							}
						};
						
						// If this was not the first slice, we need to time the animation so that it uses the end sync event of the previous animation
						if (data.index !== 0) {
							animationDefinition['stroke-dashoffset'].begin = 'anim' + (data.index - 1) + '.end';
						}
						
						// We need to set an initial value before the animation starts as we are not in guided mode which would do that for us
						data.element.attr({
							'stroke-dashoffset': -pathLength + 'px'
						});
						
						// We can't use guided mode as the animations need to rely on setting begin manually
						// See http://gionkunz.github.io/chartist-js/api-documentation.html#chartistsvg-function-animate
						data.element.animate(animationDefinition, false);
					}
				}
			}}
		/>;
	}, [percentages, props.total]);

	return (
		<div className='gauge'>
			{myChart}
		</div>
	);
}