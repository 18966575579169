import React, { useState, useEffect, useCallback, useMemo, useRef, RefObject } from 'react';
import cx from 'classnames';
import { StaticContext } from 'react-router';
import { RouteComponentProps, Route, Link } from 'react-router-dom';
import { GET } from '../utils/requests';
import { SingleGame } from '../chess/ChessCom';
import styles from './css/game.module.css';
import chessStyles from '../css/chesscom.module.css';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa';
import { Player } from '../models';

export default function Game(props: RouteComponentProps<any, StaticContext, any>) {

	const id = props.match.params.id as string;
	const [result, setResult] = useState({} as RawResult);
	const updateResult = useCallback(() => GET({
		url: ['result', id].join('/')
	}).then(setResult), [id, setResult]);
	useEffect(() => {
		updateResult();
	}, [updateResult]);

	const games = useMemo(() => Object.values(result.games || {}), [result.games]);

	const more = useRef() as RefObject<HTMLDivElement>;
	const scroll = useCallback((direction: 'up' | 'down') => {
		let div = more.current;
		if (!div) return;
		let width = div.getBoundingClientRect().width + 100;
		div.scrollBy({
			top: width * (direction === 'up' ? -1 : 1),
			behavior: 'smooth'
		});
	}, [more]);

	const [players, setPlayers] = useState([] as Player[]);
	const [team, setTeam] = useState(undefined as undefined | boolean);
	const updatePlayers = useCallback(() => {
		let { idW, idB, tournamentId } = result;
		if (!idW || !idB) return;
		if (result.chessComTeamLink || result.lichessTeamLink) setTeam(true);
		else if (result.chessComLink || result.lichessLink) setTeam(false);
		GET({
			url: ['', 'tournament', tournamentId, 'fetchPlayers'].join('/'),
			params: {
				ids: [idW, idB].join(',')
			}
		}).then(setPlayers);
	}, [result]);
	useEffect(() => {
		updatePlayers();
	}, [updatePlayers]);

	function DisplayGame(rProps: RouteComponentProps<any, StaticContext, any>) {
		const index = Number(rProps.match.params.index) - 1;
		useEffect(() => {
			setTimeout(() => window.dispatchEvent(new Event('resize')), 100);
		}, []);

		if (!games[index]) return null;
		return <SingleGame g={games[index]} />;
	}

	return (
		<div id='main'>
			<div className={[styles.stage].join(' ')}>
				<div className={styles.info}>
					{team ? 'Team ' : ''}Match<br />
					{players[0]?.name} vs. {players[1]?.name}
				</div>
				<div className={[styles.viewer, chessStyles.wrapper].join(' ')}>
					<div className={chessStyles.gameViewWrapper}>
						<Route path='/g/:id/:index' render={(rProps) => <DisplayGame {...rProps} />} />
					</div>
				</div>
				<div className={styles.rightCol}>
					<div className={['button', styles.scroll].join(' ')}  onClick={() => scroll('up')}>
						<FaAngleUp />
					</div>
					<div className={[styles.more].join(' ')} ref={more}>
						{games.map((r, i) => <Link key={cx('gameLink', i)} to={'/g/' + id + '/' + (i + 1).toString()}>
							<SingleGame g={r} />
						</Link>)}
					</div>
					<div className={['button', styles.scroll].join(' ')}  onClick={() => scroll('down')}>
						<FaAngleDown />
					</div>
				</div>
			</div>
		</div>
	);

}

interface RawResult {
    input:            Input;
    fetched:          Fetched;
    games:            Game[];
    id:               string;
    idW:              string;
    idB:              string;
    tournamentId:     string;
    round:            number;
    resultWhite:      number;
    resultBlack:      number;
    matchW:           number;
    matchB:           number;
    ratingW:          number;
    ratingB:          number;
    expectedW:        number;
    expectedB:        number;
    chessComTeamLink: string;
    lichessTeamLink:  null;
    chessComLink:     null;
    lichessLink:      null;
    createdAt:        Date;
    updatedAt:        Date;
}

interface Input {
    scores: number[];
    user:   string;
    at:     number;
}

interface Fetched {
    startTime:  number;
    endTime:    number;
    scores:     number[];
    isFinished: boolean;
}

interface Game {
    board: number;
    game:  number;
    pgn:   string;
}