import React, { useState, useCallback, useEffect, useMemo, useContext } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { GET } from '../utils/requests';
import { TournamentStatus, TournamentSettings, ProfileData, Team, EnhancedPlayer } from '../interfaces';
import SectionBreak from '../components/SectionBreak';
import styles from './css/tournaments.module.css';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { RenderTournament } from './hooks';
import { AuthContext } from '../Contexts';

export default function Tournaments(props: {
	profile: ProfileData & Team
	type: 'player' | 'team'
}) {

	const { isAuthed } = useContext(AuthContext);

	const [managed, setManaged] = useState([] as (TournamentStatus & { setting: TournamentSettings })[]);
	const updateManaged = useCallback(() => GET({ url: '/profile/tournaments' })
		.then(setManaged), [setManaged]);
	useEffect(() => {
		if (!isAuthed || props.type !== 'player') return;
		updateManaged();
	}, [updateManaged, isAuthed, props.type]);

	const [active, inactive] = useMemo(() => {
		let arr = [[], []] as [EnhancedPlayer[], EnhancedPlayer[]];
		if (!props.profile) return arr;
		for (let p of props.profile.tournaments.sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf())) {
			if (p.active && p.tournament.active) arr[0].push(p);
			else arr[1].push(p);
		}
		return arr;
	}, [props.profile]);
	
	return (<>
		{!managed.length ? null : <section key='standings.1'>
			<SectionBreak name='MANAGED TOURNAMENTS'/>
			<div className={['container'].join(' ')}>
				<table className={[styles.tableAnnounce].join(' ')}>
					<thead>
						<tr>
							<th style={{width: '20px' }}>Manage</th>
							<th>Name</th>
							<th>Started</th>
							<th>Round</th>
							<th>View</th>
						</tr>
					</thead>
					<tbody>
						{managed.sort((a, b) => new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf()).map((tourn, i) => {
							let dateStarted = new Date(tourn.createdAt).toString();
							return (
								<tr key={[tourn.id, i].join('.')} className={styles.rowLink}>
									<td><a className={styles.tournamentLink} href='https://beta.scorchapp.co.uk'>
										<FaAngleLeft />
									</a></td>
									<td>
										{tourn.name || tourn.id}
									</td>
									<td>
										{dateStarted.slice(0, 11)}
									</td>
									<td>
										{tourn.round}
									</td>
									<td>
										{tourn.setting && tourn.setting.public ?
											<Link className={styles.tournamentLink} to={'/' + tourn.id}>
												<FaAngleRight />
											</Link>
											: null}
									</td>
								</tr>
							);
						})}				
					</tbody>
				</table>
			</div>
		</section>}
		<SectionBreak name='ACTIVE TOURNAMENTS' />
		{active.map((v, i) => <RenderTournament key={cx('active', i)} p={v} />)}
		<SectionBreak name='INACTIVE TOURNAMENTS' />
		{inactive.map((v, i) => <RenderTournament key={cx('inactive', i)} p={v} />)}
		<section />
	</>);

}