import React, { useCallback, useEffect, useState, useReducer } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import styles from './css/leaderboard.module.css';
import { useMemoWithLabel } from '../../utils/hooks';
import { GET } from '../../utils/requests';
import { TournamentStatus } from '../../interfaces';

interface IndividualPlayer {
	nationality?: string
	name?: string
	team?: string
	username: string
	histories: [null, ...{
		board: number
		score: number
	}[]]
	userId?: string
}

interface LeaderboardProps {
	status: TournamentStatus
}

export default function Leaderboard(props: LeaderboardProps) {

	const [data, setData] = useState([] as IndividualPlayer[]);
	const updateData = useCallback(() => {
		GET({ url: ['', 'tournament', props.status.id, 'getIndividualPlayers'].join('/') })
			.then(setData);
	}, [props.status.id, setData]);
	useEffect(() => {
		updateData();
	}, [updateData]);

	const [showUnregistered, toggleUnregistered] = useReducer((state: boolean) => !state, false);

	const headers: {[key: string]: {
		name: string,
		value: (p: IndividualPlayer, i?: number) => string | number
		convert?: (v: number) => string
		bold?: boolean
		class?: string
	}} = useMemoWithLabel(() => {
		return {
			rank: {
				name: '',
				value: (p: IndividualPlayer, i: number) => i + 1,
				convert: (v: number) => '#' + v,
				bold: true
			},
			nationality: {
				name: '',
				value: (p: IndividualPlayer) => p.nationality
			},
			name: {
				name: 'Name',
				class: 'playerName',
				value: (p: IndividualPlayer) => p.name || p.userId,
				bold: true
			},
			username: {
				name: 'Username',
				value: (p: IndividualPlayer) => p.username
			},
			team: {
				name: 'Team',
				value: (p: IndividualPlayer) => p.team
			},
			score: {
				name: 'Score',
				value: (p: IndividualPlayer) => p.histories.reduce((acc, curr) => curr ? acc += curr.score : acc, 0),
				bold: true
			},
			averageBoard: {
				name: 'Av. Board',
				value: (p: IndividualPlayer) => p.histories.reduce((acc, curr) => curr ? acc += curr.board : acc, 0) / p.histories.slice(1).length,
				convert: (v: number) => v.toFixed(2)
			},
			scorePercentage: {
				name: 'Score %',
				value: (p: IndividualPlayer) => {
					let score = p.histories.reduce((acc, curr) => curr ? acc += curr.score : acc, 0);
					let played = p.histories.filter(curr => curr?.score !== undefined).length;
					return score / played * 100;
				},
				convert: (v: number) => Math.round(v)
			},
			wins: {
				name: 'W',
				value: (p: IndividualPlayer) => p.histories.filter(curr => curr?.score === 1).length
			},
			draws: {
				name: 'D',
				value: (p: IndividualPlayer) => p.histories.filter(curr => curr?.score === 0.5).length
			},
			losses: {
				name: 'L',
				value: (p: IndividualPlayer) => p.histories.filter(curr => curr?.score === 0).length
			},
			played: {
				name: 'P',
				value: (p: IndividualPlayer) => p.histories.filter(curr => curr?.score !== undefined).length
			}
		};
	}, [], 'headers');

	const rows = useMemoWithLabel(() => {
		return data
			.filter(p => showUnregistered ? p : p.userId)
			.sort((a, b) => {
				let x = (headers.score.value(b) as number) - (headers.score.value(a) as number);
				if (x !== 0) return x;
				return (headers.averageBoard.value(a) as number) - (headers.averageBoard.value(b) as number);
			}).map((p, i) => {
				return (
					<tr key={cx('row', i)}>
						{Object.entries(headers).map(([k, h], j) => {
							return <td
								key={['cell', i, j].join('.')}
								className={[styles.th, h.bold ? 'bold' : ''].join(' ')}
							>
								{k === 'name' && p.userId ?
									<Link to={'/@/' + p.userId} className={[styles.cell, styles.headerCell, h.class].join(' ')}>
										{h.convert ? h.convert(h.value(p, i) as number) : h.value(p, i)}
									</Link> :
									<div className={[styles.cell, styles.headerCell, h.class].join(' ')}>
										{h.convert ? h.convert(h.value(p, i) as number) : h.value(p, i)}
									</div>
								}
							</td>;
						})}
					</tr>
				);
			});
	}, [headers, data, showUnregistered], 'rows');

	return <>
		<section>
			<div className={'optionsContainer container'}>
				<div className={['header-button', styles.pairingRound, 'switchContainer'].join(' ')}>
					<label>
						<div>{showUnregistered ? 'Hide unregistered' : 'Show unregistered'}</div>
						<div>{showUnregistered ? 'Hide all users without an account (default)' : 'Show all users who have played a game'}</div>
					</label>
					<div className='toggleSwitch' onClick={toggleUnregistered}>
						<input type='checkbox' className='check' checked={showUnregistered}/>
						<div className='toggleSlider round' />
					</div>
				</div>
			</div>
		</section>
		<section key='standings.1'>
			<table className={['download-table full-width', styles.table, 'standings'].join(' ')}>
				<thead className={styles.headers}>
					<tr>
						{Object.entries(headers).map(([k, h], i) => {
							return <th
								scope='column'
								key={['column', i].join('.')}
								className={[styles.th].join(' ')}
							>
								<div className={[styles.cell, styles.headerCell].join(' ')}>
									{h.name}
								</div>
							</th>;
						})}
					</tr>
				</thead>
				<tbody>				
					{rows}
				</tbody>
			</table>
		</section>
		<section>
			<h5>
				This feature is in beta. Please contact the tournament director if you believe are there any errors. These will be corrected when the functionality to do so has been added.
			</h5>
		</section>
	</>;
}