import universalCookies, { CookieSetOptions } from 'universal-cookie';

const windowCookies = new universalCookies();

export default class Cookies {

	getAll(): Promise<{[key: string]: string}> {
		let c = windowCookies.getAll();
		return Promise.resolve(c);
	}

	get(name: string): Promise<string> {
		return Promise.resolve(windowCookies.get(name) || '');
	}

	set(name: string, value: any, options: CookieSetOptions = { path: '/', maxAge: 604800 }): Promise<void> {
		return Promise.resolve(windowCookies.set(name, value, options));
	}

	getLocal(name: string): string | boolean {
		if (!localStorage) return '';
		let x = localStorage.getItem(name) || '';
		if (x === 'true') return true;
		else if (x === 'false') return false;
		return x;
	}

	setLocal(name: string, value: any): void {
		if (!localStorage) return;
		return localStorage.setItem(name, value);
	}

	remove(name: string, options: CookieSetOptions = { path: '/' }): Promise<void> {
		return Promise.resolve(windowCookies.remove(name, options));
	}
}