import React, { ReactElement, useState, useCallback, useMemo, useEffect, CSSProperties, useContext } from 'react';
import { StaticContext } from 'react-router';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import url from 'url';
import { server, FORM, cookies, GET } from '../utils/requests';

import styles from '../css/login.module.css';
import { FaAngleLeft, FaAngleUp, FaAngleRight, FaAngleDown } from 'react-icons/fa';
import { isMobile } from '../utils/auth';
import { AlertContext } from '../Contexts';

export function Crown(props: {
	classNames?: {[key: string]: string}
}) {

	return (
		<div
			className={[styles.logo, props.classNames?.logo].join(' ')}
		>
			<img className={styles.staticLogo} src={process.env.PUBLIC_URL + '/favicon.png'} alt='swiss-logo.png'/>
		</div>
	);
}

interface LoginProps {
	isLoggedIn: boolean | undefined
	mode: 'login' | 'register' | 'reset/password'
	setSession: (s: string) => void
	updateProfile: () => void
}
export default function Login(props: RouteComponentProps<any, StaticContext, any> & LoginProps) {

	const [mode, setMode] = useState(props.mode as string);
	const [errorMessage, triggerError] = useState('');
	const { setSession } = props;
	const [shouldRedirect, redirectTo] = useState('');
	const login = useCallback(async (sessionID: string): Promise<void> => {
		await cookies.set('sessionID', sessionID );
		setSession(sessionID);
		setTimeout(() => redirectTo('/me'), 2000);
	}, [setSession, redirectTo]);

	useEffect(() => {
		if (mode !== 'register' && mode !== 'login' && mode !== 'reset/password') return;
		window.history.pushState('', document.title, '/' + mode + window.location.search);
	}, [mode]);

	const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement>): void => {
		FORM(e)
			.then(login)
			.catch((e) => {
				if (e && e.response && e.response.data) triggerError(e.response.data);
				console.error(e);
			});
	}, [triggerError, login]);

	const setAlert = useContext(AlertContext);

	const ForgottenContainer = useCallback(function ForgottenContainer({ style }: { style?: CSSProperties }) {
		return (
			<div className={styles.forgottenContainer} style={style}>
				<div className={['input', styles.forgotten].join(' ')} onClick={() => setMode('reminder')}>username reminder</div>
				<div className={['input', styles.forgotten].join(' ')} onClick={() => setMode('reset')}>password reset</div>
			</div>
		);
	}, [setMode]);

	const PasswordForm = useCallback(function PasswordForm(): ReactElement | null {
		const [name, setName] = useState('');

		let code = props.location.search.slice(1).split('&').find(p => p.startsWith('code='))?.slice('code='.length);
		
		useEffect(() => {
			if (!code) return;
			GET({ url: 'exchangeSession', params: { sid: code }}).then(setName);
		}, [code]);

		if (!code) {
			redirectTo('/login');
			return null;
		}
		return (
			<form
				className={styles.form}
				method='POST'
				action={url.resolve(server, 'triggerPasswordReset')}
				onSubmit={(e) => {
					let form = e.target as HTMLFormElement;
					if (form.password.value !== form.passwordRepeat.value) {
						setAlert({
							title: 'Password reset',
							message: 'Passwords must match!',
							type: 'error'
						});
						return e.preventDefault();
					}
					FORM(e).then(() => redirectTo('/login'));					
				}}
			>
				<div className={['input', styles.input, styles.text].join(' ')}>
					{name}
				</div>
				<input type='password' name='password' autoComplete='current-password' required placeholder='new password' className={['input', styles.input].join(' ')} />
				<input type='password' name='passwordRepeat' required placeholder='repeat password' className={['input', styles.input].join(' ')} />
				<input type='hidden' name='sid' required value={props.location.search.slice(1).split('&').find(p => p.startsWith('code='))?.slice('code='.length)} />
				<button type='submit' className={['input', styles.submit].join(' ')}>
					RESET PASSWORD
				</button>
			</form>
		);
	}, [setAlert, props.location.search, redirectTo]);

	const resetForm = useMemo((): ReactElement => {
		return (
			<form
				className={styles.form}
				method='POST'
				action={url.resolve(server, 'requestPasswordReset')}
				onSubmit={(e) => FORM(e).then(() => setAlert({
					title: 'Password reset',
					message: 'Successfully sent a password reset link to the email on file. Check your inbox!'
				}))}
			>
				<div className={['input', styles.input, styles.back].join(' ')} onClick={() => setMode('login')}>BACK</div>
				<input type='text' name='username' autoComplete='username' required placeholder='username' className={['input', styles.input].join(' ')} />
				<ForgottenContainer />
				<button type='submit'className={['input', styles.submit].join(' ')}>
					REQUEST PASSWORD RESET
				</button>
			</form>
		);
	}, [setMode, setAlert]);

	const reminderForm = useMemo((): ReactElement => {
		return (
			<form
				className={styles.form}
				method='POST'
				action={url.resolve(server, 'requestUsernameReminder')}
				onSubmit={(e) => FORM(e).then(() => setAlert({
					title: 'Username reminder',
					message: 'Successfully sent a username reminder. Check your inbox!'
				}))}
			>
				<div className={['input', styles.input, styles.back].join(' ')} onClick={() => setMode('login')}>BACK</div>
				<input type='email' name='email' autoComplete='email' required placeholder='email' className={['input', styles.input].join(' ')} />
				<ForgottenContainer />
				<button type='submit'className={['input', styles.submit].join(' ')}>
					REQUEST USERNAME REMINDER
				</button>
			</form>
		);
	}, [setMode]);

	const loginForm = useMemo((): ReactElement => {
		return (
			<form
				className={styles.form}
				method='POST'
				onSubmit={handleSubmit}
				action={url.resolve(server, 'login')}
			>
				<input type='text' name='username' autoComplete='username' required placeholder='username' className={['input', styles.input].join(' ')} />
				<input type='password' name='password' autoComplete='current-password' required placeholder='password' className={['input', styles.input].join(' ')} />
				<ForgottenContainer />
				<button type='submit'className={['input', styles.submit].join(' ')}>
					LOGIN
				</button>
			</form>
		);
	}, [handleSubmit]);

	const registerForm = useMemo((): ReactElement => {
		return (
			<form
				className={styles.form}
				method='POST'
				onSubmit={handleSubmit}
				action={url.resolve(server, 'register')}
			>
				<input type='text' minLength={4} name='username' required placeholder='username' className={['input', styles.input].join(' ')}></input>
				<input type='password' name='password' required placeholder='password' minLength={8} className={['input', styles.input].join(' ')}></input>
				<input type='email' name='email' autoComplete='email' required placeholder='email' className={['input', styles.input].join(' ')}></input>
				<button type='submit' className={['input', styles.submit].join(' ')} onSubmit={(e) => {
					let button = e.target as HTMLButtonElement;
					let form = button.parentElement as HTMLFormElement;
					form.requestSubmit();
				}}>
					REGISTER
				</button>
			</form>
		);
	}, [handleSubmit]);

	const crownBuild = useMemo(() => <Crown />, []);

	const loginSection = useMemo((): ReactElement => {
		return (
			<div className={[styles.loginSection, styles.initialiseButton].join(' ')}>
				<div className={[styles.flip, styles.loginButton].join(' ')} onClick={() => {
					setMode(props.mode === 'reset/password' ? 'reset/password' : 'login');
					triggerError('');
				}}>
					<div>LOGIN</div>
					<div>
						{!isMobile() ? <FaAngleLeft /> : <FaAngleUp />}
					</div>
				</div>
				<div className={['container', styles.foreground, mode === 'register' ? styles.registerForeground : styles.loginForeground].join(' ')}>
					{crownBuild}
					{
						mode === 'register' ?
							registerForm :
							mode === 'login' ?
								loginForm :
								mode === 'reminder' ?
									reminderForm :
									mode === 'reset' ?
										resetForm :
										mode === 'reset/password' ?
											<PasswordForm /> :
											null
					}
				</div>
				<div className={[styles.flip, styles.registerButton].join(' ')} onClick={() => {
					setMode('register');
					triggerError('');
				}}>
					<div>REGISTER</div>
					<div>
						{!isMobile() ? <FaAngleRight /> : <FaAngleDown />}
					</div>
				</div>
			</div>
		);
	}, [mode, setMode, triggerError, loginForm, registerForm, reminderForm, resetForm, crownBuild, props.mode, PasswordForm]);

	if (shouldRedirect) {
		if (shouldRedirect === '/login') {
			setMode('login');
			redirectTo('');
		}
		return <Redirect to={shouldRedirect}/>;
	}

	return (
		<div id='main' className={'animated-' + props.location.state?.direction}>
			<div className={[styles.column].join(' ')}>
				<div className={[styles.frame, 'container'].join(' ')}>
					{loginSection}
				</div>
				<div className={styles.messageBar}>
					{errorMessage || '\u200b'}
				</div>
			</div>
		</div>
	);


}