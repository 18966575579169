import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PairingsProps } from '../../tournament/Pairings';
import { GET } from '../../utils/requests';
import styles from '../../tournament/Pairings/css/pairings.module.css';

export default function Round(props: PairingsProps & {
	section: 'pairings' | 'standings' | 'stats' | 'tv',
	round: number
	loaded: {[key: string]: boolean}
}): ReactElement {

	const [roundTimes, setRoundTimes] = useState([]);
	const fetchRoundTimes = useCallback(() => GET({ url: `tournament/${props.id}/fetchRounds` })
		.then(setRoundTimes), [props.id, setRoundTimes]);
	useEffect(() => {
		fetchRoundTimes();
	}, [fetchRoundTimes]);

	const showResults = props.loaded.status && props.section === 'pairings' && props.round && !props.status.active;
	const showIndividual = props.loaded.status && props.section === 'standings' && props.settings.competitors === 'team';

	let rounds = [];
	let totalRounds = props.settings.totalRounds === Infinity || props.settings.totalRounds === 'Infinity' ? 1 : props.settings.totalRounds;
	for (let i = 1; i < totalRounds + 1; i++) {
		let date = roundTimes[i] ? new Date(roundTimes[i]).toString() : '';
		rounds.push(
			<Link
				className={['header-button', styles.pairingRound, props.round === i ? styles.roundSelected : '', i > props.status.round ? styles.nullRound : ''].join(' ')}
				to={`/t/${props.id}/${props.section}/${i}`}
				key={['round', i].join('.')}
			>
				<div className='full-text'>
					{props.loaded.all ?
						<>
							<h4>{!showResults ? 'Round ' : ''}{i}</h4>
							{roundTimes[i] ? 
								<h5>{date.substring(0, 11) + date.substring(16, 21)}</h5> :
								null
							}
						</>
						: '\u200b'}
				</div>
			</Link>
		);
	}
	if (showResults) rounds.push(
		<Link
			className={['header-button', styles.pairingRound, props.round === totalRounds + 1 ? styles.roundSelected : ''].join(' ')}
			to={`/t/${props.id}/${props.section}/results`}
		>
			<div className='full-text'>
				<h4>Results</h4>
				{roundTimes[totalRounds + 1] ? <h5>{new Date(roundTimes[totalRounds + 1]).toString().substring(0, 11) + new Date(roundTimes[totalRounds + 1]).toString().substring(16, 21)}</h5> : null}
			</div>				
		</Link>
	);

	if (showIndividual) rounds.push(
		<Link
			className={['header-button', styles.pairingRound, props.round === totalRounds + 1 ? styles.roundSelected : ''].join(' ')}
			to={['', 't', props.id, props.section, 'individual'].join('/')}
		>
			<div className='full-text'>
				<h4>Individual Scores</h4>
			</div>
		</Link>
	);

	return (
		<section className={['container', 'bodyContainer'].join(' ')}>
			{rounds.length ?
				<div className='button-list'>
					{rounds}
				</div> :
				null}
		</section>
	);
}