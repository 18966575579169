import React, { useEffect, useState } from 'react';
import { StaticContext } from 'react-router';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { POST } from '../utils/requests';

export default function Verify(props: RouteComponentProps<any, StaticContext, any> & {
	id: string
	updateProfile: () => void
}) {

	const [verified, setVerified] = useState(false);

	useEffect(() => {
		if (verified) return;
		POST({
			url: '/profile/email/authenticate',
			data: props.location.search.slice(1).split('&').reduce((acc: {[key: string]: string}, curr: string) => {
				let [k, v] = curr.split('=');
				acc[k] = v;
				return acc;
			}, {})
		})
			.then(() => setVerified(true));
	}, [verified, setVerified, props.location.search]);

	if (verified) {
		props.updateProfile();
		return <Redirect push to={{
			pathname: `/@/${props.id}/accounts`,
			state: props.history.location.state
		}} />;
	}

	return null;
}