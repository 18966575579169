import React, { ReactElement, CSSProperties, useState, useReducer, useMemo, useEffect, useCallback } from 'react';
import cx from 'classnames';
import styles from './css/sticky.module.css';
import { getDocumentHeight, getAbsoluteTop } from '../utils/prototype';

export default function Sticky(props: {
	children: ReactElement
	absoluteStyles?: CSSProperties
	fixedStyles?: CSSProperties
	upperLimit?: null | number
	lowerLimit?: null | number
}) {
	const upperLimit = useMemo(() => {
		if (props.upperLimit === null) return null;
		if (props.upperLimit === undefined) return window.innerHeight * 10 / 100;
		return props.upperLimit;
	}, [props.upperLimit]);
	const lowerLimit = useMemo(() => {
		if (props.lowerLimit === null) return null;
		if (props.lowerLimit === undefined) return 275;
		return props.lowerLimit;
	}, [props.lowerLimit]);

	const [elem, setElem] = useState(null as null | HTMLDivElement);
	const [left, setLeft] = useState(undefined as number | undefined);
	const [top, setTop] = useState(undefined as number | undefined);

	const [upperThreshold, setUpper] = useState(undefined as number | undefined);
	const [lowerThreshold, setLower] = useState(undefined as number | undefined);

	const reduceSidebar = useCallback((state: boolean) => {
		if (!elem) return false;
		let rect = elem.getBoundingClientRect();
		if (!state) {
			if (lowerThreshold) {	// Occurs when you've reached the bottom of the page
				if (window.scrollY < lowerThreshold) {
					return true;
				}
			} else {				// Occurs when you're at the top of the page
				if (upperThreshold) setUpper(undefined);
				if (top) setTop(undefined);
				if (upperLimit === null || rect.top < upperLimit) {
					setLeft(rect.left);
					setUpper(window.scrollY);
					return true;
				}
			}
			return false;
		} else {					// Occurs in the middle sticky section
			if (lowerThreshold) setLower(undefined);
			if (lowerLimit && (getDocumentHeight() - window.scrollY - rect.height) < lowerLimit) {
				let cacheLower = getDocumentHeight() - rect.height - lowerLimit;
				let absTop = getAbsoluteTop(elem);
				setLower(cacheLower);
				setTop(absTop);
				return false;
			}
			if (upperThreshold && window.scrollY < upperThreshold) {
				return false;
			}
			return true;
		}
	}, [elem, upperLimit, top, lowerLimit, setLeft, setTop,
		upperThreshold, lowerThreshold, setUpper, setLower]);
	const [isSticky, updateSidebar] = useReducer(reduceSidebar, upperLimit === null);

	useEffect(() => {
		window.addEventListener('scroll', updateSidebar);
		return () => window.removeEventListener('scroll', updateSidebar);
	}, [updateSidebar]);

	const style = useMemo(() => {
		let base = Object.assign({}, props.children.props.style);
		if (isSticky) return Object.assign(base, { left }, upperLimit === null ? { top: '8vh' } : undefined, props.fixedStyles);
		else return Object.assign(base, props.absoluteStyles, top ? { top } : undefined);
	}, [isSticky, props.absoluteStyles, props.fixedStyles,
		props.children.props.style, top, left, upperLimit]);

	return React.cloneElement(props.children, {
		className: cx(props.children.props.className, styles.sticky, {
			[styles.absolute]: !isSticky
		}),
		style,
		ref: setElem
	});
}