import React, { useState, useCallback, useEffect } from 'react';
import { StaticContext } from 'react-router';
import { Link, RouteComponentProps } from 'react-router-dom';
import styles from './css/community.module.css';
import { GET } from '../utils/requests';
import { ProfileData, Team } from '../interfaces';
import { FaCrown, FaCircle } from 'react-icons/fa';

export default function Community(props: RouteComponentProps<any, StaticContext, any>) {

	const [users, setUsers] = useState([]);
	const updateUsers = useCallback(() => GET({ url: '/users' })
		.then(setUsers), [setUsers]);
	useEffect(() => {
		updateUsers();
	}, [updateUsers]);

	return (
		<div id='main' className={'animated-' + props.location.state?.direction}>
			<div className={styles.container}>
				<section className='container'>
					<div className={styles.title}>
						<div><b>Users</b></div>
						<div className={'light'}>{users.length}</div>
					</div>
					<div className={styles.userTable}>
						{users.map((u: Partial<ProfileData>, i: number) => {
							return <>
								<div key={['status', i].join('.')} className={[styles.status, u.online ? styles.online : ''].join(' ')}>
									{u.isLicensed ? <FaCrown title='Licensed' /> : <FaCircle style={{fontSize: '0.5em'}} title={u.online ? 'Online' : 'Offline'} />}
								</div>
								<Link key={['username', i].join('.')} className={styles.profile} to={'/@/' + u.username}>
									{u.username}
								</Link>
							</>;
						})}
					</div>
				</section>
			</div>
		</div>
	);
}