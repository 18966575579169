import { randBetween } from './prototype';

const regexes = {
	rgb: /^(?:rgb)?([0-9]{1,3})[,\s]+\s?([0-9]{1,3})[,\s]+?([0-9]{1,3})$/,
	hex: /^(?:0x|#)?([0-9a-f]{1,6})$/i,
	strings: /\w+/
};

export default class Colour {

	constructor(public arr?: [number, number, number]) {}

	static randDecimal() {
		return parseInt(Colour.randHex().join(''), 16);
	}

	static randHex() {
		return Colour.randRGB().map(n => n.toString());
	}

	static randRGB() {
		let arr = [];
		for (let i = 0; i < 2; i++) {
			arr[i] = randBetween(0, 255);
		}
		return arr;
	}

	public fromRGB(s: string): [number, number, number] | null {
		let m = s.match(regexes.rgb);
		if (!m) return null ;
		return this.arr = m.slice(1).map(n => Number(n)) as [number, number, number];
	}

	public fromHex(s: string) {
		let m = s.match(regexes.rgb);
		if (!m) return null ;
		let hex = m[1];
		let hexes = [hex.slice(-6, -4), hex.slice(-4, -2), hex.slice(-2, 0)];
		return this.arr = hexes.map(n => parseInt(n, 16)) as [number, number, number];
	}

	public byDecimal(x: Number) {
		if (x < 0 || x > 16777215) throw new RangeError('Numbers must be within 0 to 1677777215 range');
		return this.fromHex(x.toString(16));
	}

	public getText() {
		if (!this.arr) return 'black';
		let t = this.arr.reduce((a, c) =>  a += c, 0) / 3 < 255 / 2;
		if (t) return 'white';
		return 'black';
	}

	public toDecimal() {
		if (!this.arr) return 0;
		return parseInt(this.arr.map(v => v.toString(16)).join(''), 16);
	}

}