import React, { useRef, RefObject, useCallback, useContext, useMemo } from 'react';
import cx from 'classnames';
import { TwitchChat } from 'react-twitch-embed';

import styles from '../../css/streams.module.css';
import { FaTwitch, FaYoutube } from 'react-icons/fa';
import {MdPictureInPictureAlt } from 'react-icons/md';
import * as regexes from '../../utils/regexes';
import Twitch from './Twitch';
import { LiveContext } from '../../Contexts';

const streamDict = {
	twitch: 'https://twitch.tv/',
	youtube: 'https://youtube.com/'
};

interface TVProps {
	streamURL: string
	pop: (elem: HTMLDivElement | null) => void
	isLive: boolean
	screenWidthProportion?: number
}

export default function TV(props: TVProps) {

	const { pop } = props;
	const twitchPlayer = useRef(null) as RefObject<HTMLDivElement>;
	const handlePop = useCallback(() => {
		if (!twitchPlayer.current) return;
		pop(twitchPlayer.current);
	}, [pop, twitchPlayer]);

	const { site, channel } = useContext(LiveContext);

	const totalWidth = useMemo(() => {
		return Math.min(window.innerWidth - 40, 1132) * (props.screenWidthProportion || 1);
	}, [props.screenWidthProportion]);

	if (!props.streamURL) return null;
	return (
		<>
			{site === 'twitch' ? 
				<section className={[styles.container].join(' ')}>
					<div className={styles.streamHeader}>
						<FaTwitch />
						<span className='bold'>{channel}</span>
						<div className={cx('button', styles.popOut)} onClick={handlePop}>
							Pop-out
							<MdPictureInPictureAlt />
						</div>
					</div>
					<div className={styles.streamContainer}>
						<Twitch ref={twitchPlayer} width={totalWidth * 0.66} height={totalWidth * 0.66 * 3 / 4} />
						<div id={styles.twitchChat} className={styles.chat} style={{ height: totalWidth * 0.66 * 3 / 4 }}>
							<TwitchChat
								channel={channel} width={totalWidth * 0.31} height={totalWidth * 0.66 * 3 / 4} 
							/>
						</div>
					</div>
				</section> :
				null
			}
			{site === 'youtube' ? <section className={[styles.container].join(' ')}>
				<div className={styles.streamHeader}>
					<FaYoutube />
					<span className='bold'>chess-england</span>
				</div>
				<div className={styles.streamContainer}>
					<div id={styles.youtubePlayer} className={styles.player}>
						<iframe title='youtubePlayer' width={totalWidth * 0.66} height={totalWidth / 2.73} src="https://www.youtube.com/embed/R2v-XSRMOFo" frameBorder={0} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
					</div>
					<div id={styles.youtubeChat} className={[styles.chat, styles.youtubeChat].join(' ')}>
						<iframe title='youtubeChat' width={totalWidth * 0.31} height="315" src="https://www.youtube.com/live_chat?v=R2v-XSRMOFo&embed_domain=localhost" frameBorder={0} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
					</div>
				</div>
			</section> : null}
			<section className={styles.footer}>
				(Live-Stream) If you're having problems viewing this player, try disabling any extensions or Brave Shields if you have them enabled.
			</section>
		</>
	);

}