import React from 'react';

import styles from './css/playerline.module.css';
import { Player } from '../interfaces';
import { getFlag } from '../utils/prototype';

export default function PlayerLine(props: {
	profile: Player
	type: string
	i: number
	onClick?: () => void
}) {

	return (
		<div data-aos='fade-left' data-aos-delay={!props.i ? undefined : 100 * (props.i % 4)} className={styles.matchUp}>
			<div className={[styles.white].join(' ')}>
				<div className={styles.flag}>
					{props.profile.nationality ? getFlag(props.profile.nationality) : null}
				</div>
				<div
					className={['playerInfo', styles.playerInfo].join(' ')}
					onClick={props.onClick}
				>{props.profile.name}</div>
			</div>
		</div>
	);

}