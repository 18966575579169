import React, { useContext, useMemo, useRef, RefObject } from 'react';
import { Link } from 'react-router-dom';
import { FaAngleRight, FaCheckCircle, FaCrown, FaCircle } from 'react-icons/fa';
import cx from 'classnames';

import styles from './css/info.module.css';
import userStyles from './css/user.module.css';
import { ProfileData, Team } from '../interfaces';
import { getFlag, px } from '../utils/prototype';
import { LoginContext, AuthContext } from '../Contexts';
import useCreate from './hooks/useCreate';
import useContact from './hooks/useContact';

export interface SingleProps {
	type: 'user' | 'team' | 'player'
	id: string
	profile: (Team & ProfileData) | null
	i?: number
	small?: boolean
	showLinks?: boolean
	updateBio?: () => void
	updateProfile?: () => Promise<void>
	endSession?: () => Promise<void>
}
interface GroupProps {
	type: 'user' | 'team' | 'player'
	id: string
	profile: (Team & ProfileData) | null
	i?: number
}
type InfoProps = SingleProps & GroupProps & {
	renderNull?: boolean
	className?: string
}

export default function Info(props: InfoProps) {

	const isSmall = !props.updateProfile && props.small !== false;
	const { isLoggedIn } = useContext(LoginContext);
	const { isAuthed } = useContext(AuthContext);

	const info = useRef() as RefObject<HTMLDivElement>;

	const { fields, links } = useContact({
		profile: props.profile as any,
		validate: e => props.type !== 'user' ? !e.verified : true,
		elem: info.current
	});

	const { handleCreate } = useCreate({ ...props, fields });

	const notes = useMemo(() => {
		return Object.entries(props.profile?.meta || {}).map(([k, v]) => {
			if (!v) return null;
			k = k.trim();
			if (k.startsWith('_')) return null;
			if (k.endsWith('?')) k = k.slice(0, -1);
			if (typeof v === 'boolean') return k;
			k += ': ';
			return k + v.toString();
		}).filter(v => v);
	}, [props.profile]);
	const Notes = useMemo(() => {
		return notes.map((line, i) => !line ? null : (
			<div className={userStyles.notes}>
				<div key={['line', i].join('.')}>{line}</div>
			</div>
		));	
	}, [notes]);

	const title = useMemo(() => {
		return <>
			{props.type === 'user' ? <div className={[styles.status, props.profile?.online ? styles.online : ''].join(' ')}>
				{props.profile?.isLicensed ? <FaCrown title='Licensed' /> : <FaCircle style={{fontSize: '0.5em'}} title={props.profile?.online ? 'Online' : 'Offline'} />}
			</div> : null}
			<Link to={px(props.type === 'user' ? '@' : props.type, props.profile?.username || '')}>
				<h1>
					{props.profile?.username || props.profile?.name}
					{props.profile?.verified ? <FaCheckCircle className='verified' /> : null}
				</h1>
			</Link>
		</>;
	}, [props.profile, props.type]);

	if (!props.profile && props.renderNull === false) return null;

	return <div
		data-aos='fade-left'
		data-aos-delay={!props.i ? undefined : 100 * (props.i % Math.min(4, Math.floor(window.innerWidth / 300)))}
		className={cx('container', styles.info, { [styles.small]: isSmall }, props.className)}
		ref={info}
	>
		<div className={styles.flag}>
			{props.profile?.nationality ? getFlag(props.profile?.nationality) : null}
		</div>
		{isSmall || props.showLinks ?
			<Link className={styles.name} to={['', props.type === 'user' ? '@' : props.type, props.profile?.id].join('/')}>
				{title}
			</Link> :
			<div className={styles.name}>
				{title}
			</div>
		}
		{props.profile?.bio ?
			<div className={styles.bio}>
				{props.profile?.bio}
			</div> :
			isAuthed ?
				<div className={['button', 'link', styles.account].join(' ')} onClick={props.updateBio}>
					Add a {props.type !== 'team' ? 'bio' : 'description'}?
					<FaAngleRight />
				</div> :
				<div className={styles.bio} />
		}
		<div className={styles.cols}>
			<div className={styles.details}>
				{props.type === 'team' && props.profile?.founder ?
					<div className={styles.detail}>
						<div className={styles.title}>Founder</div>
						<Link to={'/@/' + props.profile?.founder} className='playerName'>
							{props.profile?.founder}
						</Link>
					</div> :
					null
				}
				{props.type === 'team' && props.profile?.users ?
					<div className={styles.detail}>
						<div className={styles.title}>Size</div>
						<Link to={['', 'team', props.id, 'members'].join('/')} className='playerName'>
							{props.profile?.users.length}
						</Link>
					</div> :
					null
				}
				<div className={styles.detail}>
					<div className={styles.title}>
						{props.type === 'team' ? 'Created' : 'Joined'}
					</div>
					<div>
						{props.profile ? new Date(props.profile.createdAt).toString().slice(0, 15) : null}
					</div>
				</div>
			</div>
			<div className={styles.buttons}>
				{!!props.updateProfile && isLoggedIn && !isAuthed && props.type !== 'user' ?
					(props.type === 'team' && !props.profile?.teamId) || (props.type === 'player' && !props.profile?.userId) ?
						<div className={['button', 'link', styles.account].join(' ')} onClick={handleCreate}>
							This {props.type} is unclaimed. Register {props.type}?
							<FaAngleRight />
						</div> :
						<div className={['button', 'link', styles.account, styles.claim].join(' ')}>
							Registered
						</div> :
					null
				}				
				{!isLoggedIn ?
					<div className={styles.row}>
						<Link to='/login' className={['button', 'link'].join(' ')}>
							Sign in to view contact details{props.type === 'team' ? ' and members' : ''}
						</Link>
					</div> :
					!Object.values(fields).length && !isSmall ?
						<div className={styles.row}>No contact details registered</div> :
						links
				}
				{isSmall ? null : Notes.length && Notes.some(n => n) ? Notes.map((n, i) => <div key={cx('note', i)} className={styles.row}>{n}</div>) : null}
			</div>
		</div>
		{props.profile?.users ? <div className={styles.detail}>
			<div className={styles.title}>Members</div>
			<div className={styles.memberBox}>
				{props.profile?.users.map((m, i) => {
					return <Link to={'/@/' + props.profile?.founder} className='playerName' key={cx('user', i.toString())}>
						{m.username}
					</Link>;
				})}
			</div>
		</div> : null}
		{isSmall || props.showLinks ?
			<Link className={['button', styles.more].join(' ')} to={['', props.type === 'user' ? '@' : props.type, props.profile?.id].join('/')}>
				Profile <FaAngleRight />
			</Link> :
			null
		}
	</div>;
}

/*{isAuthed ?
	<div className={styles.row}>								
		<a href={isDev ? 'https://localhost:3000' : 'https://beta.scorchapp.co.uk'} rel='noopener noreferrer' target='_blank' className={['button', 'link', styles.new].join(' ')}>
			Create New Tournament
			<FaAngleRight />
		</a>							
		{props.endSession ? <div className={['button', 'link', styles.logout].join(' ')} onClick={() => {
			if (props.endSession) props.endSession().then(() => setRedirect(true));
		}}>
			Log out
			<FaAngleRight />
		</div> : null}
	</div> :
null}	*/