import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { RouteComponentProps, Route, Switch } from 'react-router-dom';
import { StaticContext } from 'react-router';
import Tournaments from './Tournaments';
import { ProfileData, Team } from '../interfaces';
import Info from './Info';
import { SettingsComponent, Accounts, Teams } from '.';
import { GET, POST } from '../utils/requests';
import * as regexes from '../utils/regexes';
import NotFound from '../404';
import Verify from './Verify';
import { AlertContext, AuthContext } from '../Contexts';
import Switcher from './Switcher';
import styles from './css/user.module.css';
import Sticky from '../components/Sticky';
import useBio from './hooks/useBio';

export default function User(props: RouteComponentProps<any, StaticContext, any> & {
	id: string
	profile: ProfileData
	updateProfile: () => void
	endSession: () => Promise<void>
}) {

	const [profile, setProfile] = useState(undefined as Team & ProfileData | null | undefined);
	const id = useMemo(() => profile?.username || props.id, [props.id, profile]);

	const updateProfile = useCallback(async () => {
		if (!regexes.username.test(props.id)) return setProfile(null);
		GET({ url: '/user/' + props.id })
			.then(setProfile)
			.catch(() => setProfile(null));
	}, [props.id, setProfile]);
	useEffect(() => {
		updateProfile();
	}, [updateProfile]);

	const { updateBio } = useBio({ profile, updateProfile });

	const isAuthed = props.id.toLowerCase() === props.profile.uniqueID;
	const authLevel = isAuthed ? 2 : 0;

	if (profile === undefined) return null;
	if (!profile) return <NotFound />;

	return <AuthContext.Provider value={{ isAuthed, authLevel }}>
		<div id='main'>
			<div className={styles.container}>
				<div className={styles.col1}>
					<Sticky upperLimit={null}>
						<div className={styles.infoWrapper}>
							<Info
								{...props}
								profile={profile}
								updateProfile={updateProfile}
								updateBio={updateBio}
								type='user'
							/>
						</div>
					</Sticky>
				</div>
				<div className={styles.col2}>
					<Route path={'/@/:id/:section?'} render={(rProps) => {
						return <Switcher
							{...rProps.match.params}
							type='individual'
							profile={profile}
						/>;
					}} />
					<Route render={({location}) => {
						return (
							<Switch location={location}>
								<Route exact path={`/@/${id}`} render={() => {
									return <Tournaments {...props} profile={profile} type='player'/>;	
								}}/>
								<Route exact path={`/@/${id}/verify/:account?`} render={(rProps) => {
									if (!isAuthed) return null;
									return <Verify {...props} id={id} updateProfile={updateProfile}/>;
								}}/>
								<Route exact path={`/@/${id}/tournaments`} render={() => {
									return <Tournaments {...props} profile={profile} type='player'/>;	
								}}/>
								<Route exact path={`/@/${id}/teams`} render={() => {
									return <Teams {...props} profile={profile} updateProfile={updateProfile}/>;	
								}}/>
								<Route exact path={`/@/${id}/accounts`} render={() => {
									if (!isAuthed) return null;
									return <Accounts {...props} profile={profile} updateProfile={updateProfile}/>;	
								}}/>
								<Route exact path={`/@/${id}/settings`} render={() => {
									if (!isAuthed) return null;
									return <SettingsComponent
										{...props}
										updateProfile={props.updateProfile}
										updateBio={updateBio}
									/>;	
								}}/>
							</Switch>
						);
					}} />
				</div>
			</div>
		</div>
	</AuthContext.Provider>;
}