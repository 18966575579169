import React, { ReactElement, useCallback, useReducer, useState, useEffect, useMemo, useContext, useRef, RefObject } from 'react';
import { FaDownload, FaClock } from 'react-icons/fa';
import cx from 'classnames';

import Row from './Row';
import Sidebar from './Sidebar';
import { useMatches, useDisplay, useSidebar } from './hooks';
import { Result, Player, TournamentStatus, TournamentSettings, Team, ProfileData } from '../../interfaces';
import * as regexes from '../../utils/regexes';
import { GET } from '../../utils/requests';
import styles from './css/pairings.module.css';
import Pie from './PieChart';
import { LoginContext } from '../../Contexts';
import { Game } from '../../resources/result';
import { useMemoWithLabel } from '../../utils/hooks';
import { convertPoolDisplay } from '../../utils/prototype';

const headers = ['White', 'White score', 'Black', 'Black score', 'Game link', 'Time'] as string[];

export interface PairingsProps {
	direction: string
	id: string
	status: TournamentStatus
	playerDict: Map<string, Player>
	settings: TournamentSettings
}

export default function Pairings(props: PairingsProps & {
	round: number
	profile: ProfileData
	announcements: {
		announcement: string
		createdAt: string
		updatedAt: string
	}[]
}): ReactElement | null {

	const [loaded, hasLoaded] = useState(false);
	const [results, setResults] = useState([] as Result[]);
	const headerRow = useRef() as RefObject<HTMLTableRowElement>;
	const [showSidebar, updateShowSidebar] = useReducer(() => (headerRow.current?.getBoundingClientRect().top ?? Infinity) < 250, false);
	useEffect(() => {
		window.addEventListener('scroll', updateShowSidebar);
		return () => window.removeEventListener('scroll', updateShowSidebar);
	}, [updateShowSidebar]);
	
	const { getName } = useDisplay(props.settings);
	const {
		matches, matchDict, setMatch, populatematchDict,
		fetchedMatch, fetchNext, hasFetched, beforeSetResults
	} = useMatches({ playerDict: props.playerDict, results, settings: props.settings });
	const {
		searched, expanded, visible,
		setSearched, setExpanded, setVisible
	} = useSidebar();

	const [gameDict, setGameDict] = useState({} as {[key: string]: Game[]});
	const fetchGames = useCallback((ids?: string[]) => {
		return GET({
			url: ['', 'tournament', props.id, 'getPairings'].join('/'),
			params: {
				round: props.round,
				ids: ids?.join(','),
				games: true
			}
		}).then(arr => setGameDict(arr.reduce((acc: {[key: string]: Game[]}, curr: { id: string, games: Game[]}) => {
			acc[curr.id] = curr.games;
			return acc;
		}, {})));
	}, [props.id, props.round, setGameDict]);
	
	useEffect(() => {
		GET({
			url: '/tournament/' + props.id + '/getPairings',
			params: { round: props.round }
		})
			.then((data) => {
				setResults(data);
				hasLoaded(true);
				return data;
			})
			.then((data: Result[]) => {
				if (window.location.hash) return;
				if (props.round >= props.status.round) return;
				//fetchGames().then(() => setExpanded(data.filter(m => m).map(m => m.id)));
			})
			.catch(() => {});
	}, [setResults, props.round, props.id, fetchGames, props.status.round]);
	
	const { isLoggedIn } = useContext(LoginContext);
	const [profile, setProfile] = useState(undefined as Team & ProfileData | null | undefined);

	const csv: string[][] = useMemoWithLabel(() => {
		return Object.values(matchDict)
			.sort((a, b) => {
				if (!a) return -1;
				if (!b) return 1;
				return a.board - b.board;
			}).map((match, i) => {
				if (!match) return null;
				if (match.round !== props.round) return null;
				if (match.id in gameDict) match.games = gameDict[match.id];
				let displayTime = match.startTime || match.registrationTime;
				let date = new Date(displayTime || 0).toString();
				let data = [
					getName(match.w),
					match.scores[0] ?? ((match.isLive || match.isFinished) && match.teams && (match.w?.chessCom || match.b?.chessCom) ? match.teams[0].score : ''),
					getName(match.b),
					match.scores[1] ?? ((match.isLive || match.isFinished) && match.teams && (match.w?.chessCom || match.b?.chessCom) ? match.teams[1].score : ''),
					displayTime ? date.slice(0, 10) + '\n' + date.slice(16, 21): '',
					match.link,
					match.w ? match.w.nationality : '',
					match.b ? match.b.nationality : ''
				] as string[];

				return data;
			});
	}, [gameDict, getName, matchDict, props.round], 'csv');

	useEffect(() => {
		beforeSetResults();
		setResults([]);
	}, [setResults, beforeSetResults, props.round]);
	
	useEffect(() => {
		populatematchDict(results, props.round);
	}, [populatematchDict, results]);
	useEffect(() => {
		fetchNext();
	}, [fetchNext]);


	const updateProfile = useCallback(async () => {
		if (!regexes.username.test(props.profile.username)) return setProfile(null);
		if (props.profile.username) GET({ url: '/user/' + props.profile.username })
			.then(setProfile);
	}, [setProfile, props.profile.username]);
	useEffect(() => {
		if (!isLoggedIn) return;
		updateProfile();
	}, [updateProfile, isLoggedIn]);
		
	const rows = useCallback((pool: number) => {
		return Object.values(matchDict).sort((a, b) => {
			if (!a) return -1;
			if (!b) return 1;
			return a.board - b.board;
		}).map((match, i) => {
			if (!match) return null;
			if (match.id in gameDict) match.games = gameDict[match.id];
			let displayTime = match.startTime || match.registrationTime;
			let date = new Date(displayTime || 0).toString();
			let data = [
				getName(match.w),
				match.scores[0] ?? ((match.isLive || match.isFinished) && match.teams && (match.w?.chessCom || match.b?.chessCom) ? match.teams[0].score : ''),
				getName(match.b),
				match.scores[1] ?? ((match.isLive || match.isFinished) && match.teams && (match.w?.chessCom || match.b?.chessCom) ? match.teams[1].score : ''),
				displayTime ? date.slice(0, 10) + '\n' + date.slice(16, 21): '',
				match.link,
				match.w ? match.w.nationality : '',
				match.b ? match.b.nationality : ''
			] as string[];

			return <Row
				key={['row', match.board].join('.')}
				i={i}
				{...{ data, match, pool, setMatch, setExpanded, fetchGames }}
				setFinished={hasFetched}
				round={props.round}
				fetch={fetchedMatch ? fetchedMatch.id === match.id : false}
				expanded={expanded.includes(match.id)}
				highlight={isLoggedIn && match.w && match.b && (match.w.id === profile?.username || match.b.id === profile?.username || profile?.teams?.some(t => t && (t.id === match.w.teamId || t.id === match.b.teamId)))}
				hidden={(visible !== 'all' && match.status !== visible) || (!!searched.length && !searched.some(c => c.id === match.w?.id || c.id === match.b?.id))}
				settings={props.settings}
			/>;
		});
	}, [gameDict, getName, fetchedMatch, setExpanded,
		expanded, searched, matchDict, hasFetched, visible, fetchGames, setMatch,
		props.round, props.settings, profile, isLoggedIn
	]);


	const handleDownload = useCallback(() => {
		const element = document.createElement('a');
		const fileContent = headers.join(',') + '\n' + csv.map(line => line.join(',')).join('\n');
		const fileFormat = 'csv';
		const file = new Blob([fileContent], {type: 'text/csv'});
		const tourName = (props.status.name.match(regexes.lowerCase) || []).join('');
		const date = new Date(Date.now()).toString().slice(4, 15);
	
		element.href = URL.createObjectURL(file);
		element.download = `${tourName}_${date}_${[props.round]}.${fileFormat}`;
		element.click();
	}, [csv, props.round, props.status.name]);

	const poolDistribution = useMemo(() => results.reduce((acc, curr) => {
		let pool = curr.pool || 0;
		if (!acc[pool]) acc[pool] = 0;
		acc[pool]++;
		return acc;
	}, [] as number[]), [results]);

	return <>
		<section style={{display: !results.length ? 'none' : undefined}} data-aos='zoom-in' data-aos-once>
			<div className={styles.pieMatrix}>
				<div className={['button', styles.timezone].join(' ')}>
					<FaClock />{new Date(Date.now()).toString().slice(25)}				
				</div>
				<div className={styles.pie}>
					<Pie matches={matchDict} round={props.round} total={results.length}/>
				</div>
				<div className={['button', styles.download].join(' ')} onClick={handleDownload}>
					<FaDownload />Download
				</div>
			</div>
		</section>
		<Sidebar {...{
			id: props.id, playerDict: props.playerDict, showSidebar, fetchGames, matches, title: props.status.name,
			round: props.round, matchDict, results, announcements: props.announcements, getName,
			visible, setVisible, expanded, setExpanded, setSearched,
			settings: props.settings
		}} />
		{(() => {
			let tables = [];
			let hasRefed = false;
			for (let i = 0; i < poolDistribution.length; i++) {
				if (!poolDistribution[i]) continue;
				tables.push(
					<section key={['table', i].join('.')} className={cx({
						still: searched.length,
						['animated-' + props.direction]: results.length
					}, styles.table)}>
						{i ? <div className={styles.pool}>Pool {convertPoolDisplay(i, props.settings.poolDisplay)}</div> : null}
						<table className={cx('download-table', 'full-width', 'pairings')}>
							<thead>
								<tr ref={hasRefed ? null : headerRow}>
									<td style={{width: '5%'}}>Fed</td>
									<td style={{width: '22.5%'}}>White</td>
									<td style={{width: '12.5%'}}>Link</td>
									<td style={{width: '5%'}}></td>
									<td style={{width: '10%'}}>Start Time</td>
									<td style={{width: '5%'}}></td>
									<td style={{width: '12.5%'}}>Watch Live</td>
									<td style={{width: '22.5%'}}>Black</td>
									<td style={{width: '5%'}}>Fed</td>
								</tr>
							</thead>
							<tbody>
								{loaded && !results.length ? <tr><td colSpan={100}>Draw has not yet taken place!</td></tr> : null}                       
								{rows(i)}
							</tbody>
						</table>
					</section>
				);
				hasRefed = true;
			}
			return tables;
		})()}
		
	</>;

}