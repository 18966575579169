import React, { useMemo } from 'react';
import { FaCheckCircle } from 'react-icons/fa';

import styles from '../css/user.module.css';
import contactFields, { ContactMeta } from '../../resources/contact';
import { ContactEntry } from '../../interfaces';
import { useClub } from '../callbacks/ChessCom';

export default function useContact({ profile, showVerified, validate = () => true, forceFacebook, elem }: {
	profile: { [key: string]: Partial<ContactEntry> } | null;
	showVerified?: boolean
	validate?: (e: ContactEntry) => boolean,
	forceFacebook?: boolean
	elem?: null | HTMLElement | HTMLDivElement
}) {

	const { getClubName } = useClub({ elem });

	const fields = useMemo(() => {
		if (!profile) return [];
		return Object.entries(contactFields).map(([k, meta], i) => {
			meta = Object.assign(contactFields[k], { id: k }) as ContactMeta;
			if (!meta) return null;
			let entry =  profile[k as keyof typeof profile] as ContactEntry | undefined;
			if (k === 'email' && typeof profile.email === 'string') entry = profile.email ? Object.assign({}, profile.emailMeta, {id: profile.email}) as ContactEntry : undefined;
			if (!entry || typeof entry !== 'object' || !validate(entry)) return null;
			return [meta, entry];
		}).filter(v => v) as [ContactMeta, ContactEntry][];
	}, [profile, validate]);

	const links = useMemo(() => {
		return fields.map(([meta, entry], i) => {
			return entry.link || meta.idLink || meta.link ?
				<a
					id={meta.id}
					href={entry.link || (meta.id === 'facebook' && !forceFacebook ? undefined : (meta.idLink || meta.link as string).replace('|', entry.username || entry.id || ''))}
					className={['button', 'link', styles.account].join(' ')}
					rel='noopener noreferrer'
					target='_blank'
					key={['a', i].join('.')}
				>
					{typeof meta.icon[1] === 'string' ? <img src={meta.icon[1]} alt={meta.id} className={styles.icon} /> : React.cloneElement(meta.icon[1], { className: styles.icon })}
					{getClubName(meta, entry)}
					{showVerified !== false && entry.verified ? <FaCheckCircle className='verified' /> : null}
				</a> :
				<div
					id={meta.id}
					key={['div', i].join('.')}
					className={['button', 'link', styles.account].join(' ')}
				>
					{typeof meta.icon[1] === 'string' ? <img src={meta.icon[1]} alt={meta.id} className={styles.icon} /> : React.cloneElement(meta.icon[1], { className: styles.icon })}
					{getClubName(meta, entry).length > 20 ? '' : getClubName(meta, entry)}
					{showVerified !== false && entry.verified ? <FaCheckCircle className='verified' /> : null}
				</div>;
		});
	}, [getClubName, fields, showVerified, forceFacebook]);

	return { fields, links };
}