import React, { useReducer, useCallback, useEffect, useState, useMemo, useRef, RefObject } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import ChessWebAPI, { BoardData, MatchData, Game, ProfileData, TeamData, ChessTeam, ChessResult } from 'chess-web-api';
import * as regexes from '../utils/regexes';
import styles from '../css/chesscom.module.css';
import '../css/react-pgn-viewer.css';
import { FaChessBoard, FaAngleLeft, FaAngleRight, FaExclamationTriangle, FaSyncAlt } from 'react-icons/fa';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import PGN from '../components/react-pgn-viewer/';
import Match from '../models/Match';
import bcrypt from 'bcryptjs';
import { POST, GET, saltRounds } from '../utils/requests';
import { useMemoWithLabel, useStateWithLabel } from '../utils/hooks';
import { compare } from '../utils/prototype';
import { Player } from '../models';
import { Game as GameInterface } from '../resources/result';

export const LossResult = {timeout: 1, result: 1, agreed: 1};
export const DrawResult = {insufficient: 1, agreed: 1, repetition: 1};
export const WinResult = {win: 1};	//TODO

export const Chess = new ChessWebAPI({ queue: true });

export async function getChessComProfile(username: string): Promise<ProfileData> {
	return new Promise((res, rej) => {
		Chess.dispatch(Chess.getPlayer, (data: { body: ProfileData }, error) => {
			if (error) return rej(error);
			res(data.body);
		}, [username]);
	});
}

export async function getChessComTeam(id: string): Promise<TeamData> {
	return new Promise((res, rej) => {
		Chess.dispatch(Chess.getClub, (data: { body: TeamData }, error) => {
			if (error) return rej(error);
			res(data.body);
		}, [id]);
	});
}

export async function loadChessComGames(id: number, board: number): Promise<BoardData> {
	return new Promise((res, rej) => {
		Chess.dispatch(Chess.getTeamLiveMatchBoard, (data: { body: BoardData }, error) => {
			if (error) return rej(error);
			res(data.body);
		}, [id, board]);
	});
}

export async function loadChessComMatch(id: number): Promise<MatchData> {
	return new Promise((res, rej) => {
		Chess.dispatch(Chess.getTeamLiveMatch, (data: { body: MatchData }, error) => {
			if (error) return rej(error);
			res(data.body);
		}, [id]);
	});
}

function compareTeams(p: Player, t: ChessTeam) {
	if (p.chessCom) {
		let x = p.chessCom.toLowerCase().split('/').pop();
		let y = t.url.toLowerCase().split('/').pop();
		if (x && y && x === y) return true;
	}
	let name = p.name || p.firstName;
	if (name) {
		let l = compare(name?.toLowerCase().replace('chess', '').replace('club', ''), t.name?.replace('chess', '').replace('club', ''));
		if (l < 0.45) return true;
	}
	return false;
}

interface ChessComProps {
	id: string
	match: Match
	setMatch: (match: Match) => void
	setFinished: (id: string) => void
	sync: (set?: boolean) => void
	fetch: boolean
	expanded: boolean
	enforceBoards: number | undefined
	round: number
	teams: ChessTeam[]
	showBoards?: boolean
	showButtons?: boolean
}

export default function ChessCom(props: ChessComProps) {

	const [boards, setBoards] = useStateWithLabel(20, 'boards');
	useEffect(() => {
		if (props.match.players) setBoards(typeof props.match.players === 'string' ? parseInt(props.match.players) : props.match.players);
		else if (props.enforceBoards) setBoards(props.enforceBoards);
	}, [props.enforceBoards, setBoards, props.match.players]);

	const reduceGamesFromMatch = useCallback((): {games: Game[], boardScores: {[key: string]: number}}[] => {
		if (!props.match.games) return [];
		let g = Array.isArray(props.match.games) ? props.match.games : Object.values(props.match.games) as GameInterface[];
		return g.reduce((acc: {boardScores: {[key: string]: number}, games: Game[]}[], curr, i, arr) => {
			let newPlayers = [curr.w?.username.toLowerCase(), curr.b?.username.toLowerCase()];
			let exists = acc.findIndex(g => {
				let oldPlayers = [g.games[0].white?.username.toLowerCase(), g.games[0].black?.username.toLowerCase()];
				if (newPlayers[0] === oldPlayers[0] && newPlayers[1] === oldPlayers[1]) return true;
				if (newPlayers[0] === oldPlayers[1] && newPlayers[1] === oldPlayers[0]) return true;
				return false;
			});
			let w = curr.w?.username.toLowerCase();
			let b = curr.b?.username.toLowerCase();
			let game = {
				white: curr.w,
				black: curr.b,
				pgn: curr.pgn,
				team: curr.w?.team === arr[0].w?.team ? 0 : 1
			} as any as Game;
			if (exists !== -1) {
				acc[exists].games.push(game);
				if (w in acc[exists].boardScores) {
					acc[exists].boardScores[w] += curr.w?.result;
				}
				if (b in acc[exists].boardScores) {
					acc[exists].boardScores[b] += curr.b?.result;
				}
			} else {
				acc.push({
					boardScores: {
						[w]: curr.w?.result,
						[b]: curr.b?.result
					},
					games: [game]
				});
			}
			return acc;
		}, []);
	}, [props.match.games]);
	const [games, setGame] = useReducer((state: {games: Game[], boardScores: {[key: string]: number}}[], action: {
        board: number,
        boardScores: {[key: string]: number}
        games: Game[]
    } | {games: Game[], boardScores: {[key: string]: number}}[]) => {
		if (Array.isArray(action)) return action;
		let arr = state.slice(0);
		arr[action.board - 1] = {
			boardScores: action.boardScores,
			games: Array.isArray(action.games) ? action.games : Object.values(action.games)
		};
		return arr;
	}, []);
	useEffect(() => {
		setGame(reduceGamesFromMatch());
	}, [setGame, reduceGamesFromMatch]);

	const [id, setID] = useState(0);
	const teams: [{name: string}, {name: string}] = useMemoWithLabel(() => {
		if (props.teams.length) return props.teams;
		if (props.match.games && props.match.games[0]) return [{
			name: props.match.games[0].w?.team
		}, {
			name: props.match.games[0].b?.team
		}];
		return [];
	}, [props.teams, props.match.games], 'teams');
	const teamDict: {[key: string]: number} = useMemoWithLabel(() => {
		let dict = {} as {[key: string]: number};
		if (!props.match.teams) return dict;
		for (let i = 0; i < props.match.teams.length; i++) {
			let t = props.match.teams[i];
			for (let player of t.players) {
				dict[player.username.toLowerCase()] = i;
			}
		}
		return dict;
	}, [props.match.teams], 'teamDict');
	const matchFinished: boolean = useMemoWithLabel(() => props.match.isFinished, [props.match], 'matchFinished');
	const [boardsFinished, setFinished] = useReducer((state: boolean[], action: {
       board: number,
       set: boolean
    }) => {
		let arr = state.slice(0);
		arr[action.board] = action.set;
		return arr;
	}, []);
	const [gamesLength, setGamesLength] = useState(0);

	const fetchMatch = useCallback(async () => {
		if (!props.match.link) return;
		let matches = props.match.link.match(regexes.chessCom);
		if (!matches) return console.error('Invalid URL: ', props.match.link);
		let teamID = Number(matches[1]);
		if (!teamID) return;
		await loadChessComMatch(teamID as number)
			.catch((e: Error | MatchData) => {
				if (e) console.error(e);
				return {} as MatchData;
			})
			.then((body) => {
				if (body && body.teams) {
					let teams = Object.values(body.teams);
					if (props.match.w?.chessCom || props.match.b?.chessCom) {
						if (compareTeams(props.match.w, teams[1])) teams.reverse();
						else if (compareTeams(props.match.b, teams[0])) teams.reverse();
					}
					if ((body.start_time && Date.now() < body.start_time * 1000) || body.status === 'finished') {
						setID(teamID);
						let scores = props.match.scores;
						if (!props.match.endTime) scores = teams.map(t => t.score) as [number, number];
						props.setMatch(Object.assign(props.match, {
							bodyStatus: body.status,
							startTime: body.start_time * 1000,
							endTime: body.end_time ? body.end_time * 1000 : undefined,
							players: body.boards,
							teams,
							scores
						}));
						return Promise.reject();
					}
				}
				return body;
			})
			.then((body) => {
				GET({
					url: '/tournament/' + props.match.tournamentId + '/scrapeChessComTeamPage',
					params: {
						url: props.match.link
					}
				})	
					.then((meta) => {
					/* eslint-disable-next-line no-throw-literal */
						if (!meta.Teams) throw null;
						let teams = Object.values(meta.Teams) as ChessTeam[];
						if (props.match.w?.chessCom || props.match.b?.chessCom) {
							if (compareTeams(props.match.w, teams[1])) teams.reverse();
							else if (compareTeams(props.match.b, teams[0])) teams.reverse();
						}
						/* eslint-disable-next-line no-throw-literal */
						if (!meta['Registration Open'] && !meta['Start Time'] && !meta['Finished On']) throw null;

						let bTeams = Object.values(body.teams || {});
						if (body.teams && bTeams[0] && bTeams[0].players.length && (!teams[0] || !teams[0].players.length)) {
							teams[0].players = bTeams.find(t => t['@id'] === teams[0]['@id'])?.players || [];
							teams[1].players = bTeams.find(t => t['@id'] === teams[1]['@id'])?.players || [];
						}
						setID(teamID);
						let scores = props.match.scores;
						if (!props.match.endTime) scores = teams.map(t => t.score) as [number, number];
						let players = meta['Players per Team'];
						props.setMatch(Object.assign(props.match, {
							registrationTime: meta['Registration Open'] ? new Date(meta['Registration Open']).valueOf() : undefined,
							metaStatus: meta['Status'],
							startTime: body && body.start_time ? body.start_time * 1000 : meta['Start Time'] ? new Date(meta['Start Time']).valueOf() : undefined,
							endTime: meta['Finished On'] ? new Date(meta['Finished On']).valueOf() : undefined,
							players: players === '1-10000' ? undefined : players,
							teams,
							scores
						}));
					})
					.catch((e) => {
						if (e) console.error(e);
						props.setMatch(Object.assign(props.match, {
							aborted: true
						}));
						props.setFinished(props.match.id);
					});
			})
			.catch((e) => {
				if (e) console.error(e);
			});
	}, [id, props.setMatch, props.match, setID, props.setFinished]);

	useEffect(() => {
		if (id) return;
		if (props.fetch) fetchMatch();
		if (props.match.isFinished && props.match.games) return;
		if (props.expanded) fetchMatch();
	}, [fetchMatch, props.expanded, props.fetch, id, props.match]);

	const ping = useCallback(async () => {
		if (!id) return;
		for (let i = 1; i < boards + 1; i++) {
			if (boardsFinished[i]) continue;
			let x = loadChessComGames(id, i)
				.then((fetchedGames) => {
					setGame({
						games: fetchedGames.games.map((g, j) => {
							g.pgn = saturatePGN(g, teamDict, props.teams.map(t => t.name) as [string, string], props.match.round, i, j + 1);
							return g;
						}),
						boardScores: fetchedGames.board_scores,
						board: i
					});
					let lastGame = fetchedGames.games[fetchedGames.games.length - 1];
					if (!lastGame) throw new Error('No game data given');
					if (fetchedGames.games.length > gamesLength) setGamesLength(fetchedGames.games.length);
					if (lastGame.end_time) setFinished({board: i, set: true});
					return true;
				})
				.catch((e) => {
					if (e.status) {
						switch (e.status) {
						case 429:
							return;
						}
					}
					//if (e.message.includes('doesn\'t have board')) {
					if (i !== 1 || games.length > 2) {
						if (boards < i) return true;
						if (i === 2 && !games.length) setBoards(i - 2);
						else {
							setBoards(i - 1);
							return false;
						}
					}
					//} else {
					//	setBoards(i - 1);
					//}
					return true;
				});
			if (!props.enforceBoards && !props.match.players && await x === false) break;
		}

	}, [id, boards, boardsFinished, games.length, gamesLength, setGamesLength, props.enforceBoards, props.match.players, props.teams, teamDict]);
	
	const sendGameData = useCallback(async () => {
		let match = props.match;
		let key = await bcrypt.hash([match.w?.id, match.b?.id, match.tournamentId, props.round].join('.'), saltRounds);
		let pgn = [];
		for (let i = 0; i < games.length; i++) {
			if (!games[i] || !games[i].games) continue;
			for (let j = 0; j < games[i].games.length; j++) {
				pgn.push({
					board: i,
					game: j + 1,
					pgn: games[i].games[j].pgn,
					w: {
						username: games[i].games[j].white?.username,
						result: numerateResult(games[i].games[j].white?.result),
						team: match.teams[teamDict[games[i].games[j].white?.username.toLowerCase()]]?.name
					},
					b: {
						username: games[i].games[j].black?.username,
						result: numerateResult(games[i].games[j].black?.result),
						team: match.teams[teamDict[games[i].games[j].black?.username.toLowerCase()]]?.name
					}
				});
			}
		}
		let data = {
			idW: match.w?.id,
			idB: match.b?.id,
			tournamentId: match.tournamentId,
			round: props.round,
			key,
			fetched: {
				startTime: match.startTime || match.registrationTime,
				endTime: match.endTime,
				scores: match.teams?.map(t => t.score) || [],
				isFinished: match.isFinished || match.isConfirmed
			},
			games: pgn
		}; 
		await POST({ url: '/submit/game', data, noCatch: true });
		props.setFinished(match.id);
		props.sync(false);
	}, [games, props.match, props.round, props.setFinished, teamDict, props.sync]);

	const length = useMemo(() => games.filter(v => v && v).length, [games]);
	useEffect(() => {
		if (props.match.aborted) {
			sendGameData();
			return;
		}
		if (!boards) {
			sendGameData();
			return;
		} else {
			if (length >= boards) sendGameData();
		}
	}, [props.match.aborted, length, boards]);

	useEffect(() => {
		if (matchFinished && games.length && !boards) return;
		let x = setInterval(ping, 1000);
		return () => clearInterval(x);
	}, [id, ping,  matchFinished, games.length, boards]);

	const summaryRows = useCallback((data: { games: (Game | {
		team: number
		white: { username: string }
		black: { username: string }
	})[], boardScores: {[key: string]: number}}, i: number) => {
		let { games, boardScores } = data;
		let lastGame = games[games.length - 1];
		if (!lastGame) return null;
		let prop = ('team' in lastGame ? lastGame.team === 0 : teamDict[lastGame.white?.username.toLowerCase()] === 0) ? lastGame.white : lastGame.black;
		let opp = ('team' in lastGame ? lastGame.team === 0 : teamDict[lastGame.black?.username.toLowerCase()] === 1) ? lastGame.black : lastGame.white;
		if (!prop) return [];
		return [
			<div key='usernameW' className={styles.username}>{prop.username}</div>,
			<div key='scoreW' className={styles.score}>{boardScores[prop.username.toLowerCase()]}</div>,
			<a key='board' href={`#${props.id}.${i + 1}`}>{'#' + (i + 1)}</a>,
			<div key='scoreB' className={styles.score}>{boardScores[opp.username.toLowerCase()]}</div>,
			<div key='usernameB' className={styles.username}>{opp.username}</div>
		];
	}, [teamDict, props.id]);

	const [focused, setFocused] = useState(0);

	let style = games.length ? {} : {gridTemplateColumns: '1fr'};
	if (!props.match.games) {
		if (!games.length && !boards) return <div>Match has not yet started!</div>;
		if (!games.filter(v => v && v.games.length).length && !boards) return <div>Still waiting for data from Chess.com. Check back later.</div>;
		if (!games.length) return <div className={styles.loading}><AiOutlineLoading3Quarters /></div>;
		if (!props.expanded) return null;
	}

	return (
		<div className={styles.container} style={style}>
			<div className={styles.results}>
				<table className='download-table'>
					<thead>
						{!props.match.games && !Object.keys(teamDict).length ?
							<tr>
								<th colSpan={5}>
									<FaExclamationTriangle /> Couldn't fetch player-team identifying data from Chess.com for this match<br />
									Check back later or contact a dev if this problem persists.
								</th>
							</tr> :
							null
						}
						<tr>
							<th style={{width: '40%'}}>
								{teams[0]?.name ? teams[0].name : ''}
							</th>
							<th style={{width: '5%'}}></th>
							<th style={{width: '10%'}}>	</th>
							<th style={{width: '5%'}}></th>
							<th style={{width: '40%'}}>
								{teams[1]?.name ? teams[1].name : ''}
							</th>
						</tr>
					</thead>
					<tbody>
						{games.map((g, i) => {
							return <tr key={['row', i].join('.')}>
								{summaryRows(g, i)?.map((cell, j) => {
									return <td key={['cell', i, j].join('.')}>{cell}</td>;
								})}
							</tr>;
						})}
					</tbody>
				</table>
			</div>
			{props.showButtons !== false ?
				<>
					<div className={[styles.button, 'button'].join(' ')} onClick={() => props.sync()}>
						<FaSyncAlt />Sync
					</div>
					<Link to={['', 'g', props.match.id].join('/')} className={[styles.button, 'button'].join(' ')}>
						<FaChessBoard />Analysis
					</Link>
				</> :
				null
			}
			{props.showBoards ? games.map((pairing, i) => <GameViewer
				id={props.id}
				key={['game', i].join('.')}
				pairing={pairing}
				teamDict={teamDict}
				teams={props.teams.map(t => t.name) as [string, string]}
				round={props.round}
				board={i + 1}
				focused={focused === i}
				setFocused={() => setFocused(i)}
			/>) : null}
		</div>
	);
}

interface ViewerProps {
	id: string
	pairing: { games: Game[], boardScores: {[key: string]: number}}
	teamDict: {[key: string]: number}
	board: number
	round: number
	teams: [string, string]
	focused: boolean
	setFocused: () => void
}

function GameViewer(props: ViewerProps) {

	const scores = useMemo(() => Object.entries(props.pairing.boardScores), [props.pairing.boardScores]);
	const [currIndex, setIndex] = useState(props.pairing.games.length - 1);
	const gameRef = useRef(null) as RefObject<HTMLDivElement>;

	let rendered = useMemo(() => props.pairing.games.map((g, i) => <SingleGame key={cx('singleGame', i)} g={g} />), [props.pairing.games]);

	const g = props.pairing.games[currIndex];
	const flip = g && props.teamDict[g.black?.username.toLowerCase()]; 
	let _scores = flip ? scores.reverse() : scores;

	useEffect(() => {
		window.dispatchEvent(new Event('resize'));
	}, []);

	const buttons = useMemo(() => {
		let div = gameRef.current;
		if (!div) return [];
		let buttons = Array.from(div.querySelectorAll('.visible .pgnViewerFooter > div'));
		return buttons;
	}, [gameRef]);

	const handleScroll = useCallback((ev: Event) => {
		let e = ev as WheelEvent;
		if (e.deltaY < 0) {
			let button = buttons[1] as HTMLDivElement;
			if (!button) return;
			button.click();
			e.preventDefault();
		} else
		if (e.deltaY > 0) {
			let button = buttons[2] as HTMLDivElement;
			if (!button) return;
			button.click();
			e.preventDefault();
		}
	}, [buttons]);
	const handleKeyPress = useCallback((e: KeyboardEvent) => {
		switch (e.keyCode) {
		case 37: {	//left
			let button = buttons[1] as HTMLDivElement;
			if (!button) break;
			button.click();
			e.preventDefault();
			break;
		}
		case 39: {	//right
			let button = buttons[2] as HTMLDivElement;
			if (!button) break;
			button.click();
			e.preventDefault();
			break;
		}
		case 38: { //up
			let button = buttons[0] as HTMLDivElement;
			if (!button) break;
			button.click();
			e.preventDefault();
			break;
		}
		case 40: { //down
			let button = buttons[3] as HTMLDivElement;
			if (!button) break;
			button.click();
			e.preventDefault();
			break;
		}
		case 32: { //space
			let button = buttons[4] as HTMLDivElement;
			if (!button) break;
			button.click();
			if (gameRef.current) gameRef.current.scrollIntoView({ behavior: 'smooth' });
			e.preventDefault();
			break;				
		}
		case 70: { //f
			let button = buttons[5] as HTMLDivElement;
			if (!button) break;
			button.click();
			break;
		}
		case 68: { //d
			let button = buttons[6] as HTMLDivElement;
			if (!button) break;
			button.click();
			e.preventDefault();
			break;
		}
		}
	}, [gameRef, buttons]);
	useEffect(() => {
		if (!props.focused) return;
		document.addEventListener('keydown', handleKeyPress);
		return () => document.removeEventListener('keydown', handleKeyPress);
	}, [props.focused, handleKeyPress]);

	const handleClick = useCallback(() => {
		let div = gameRef.current;
		if (!div) return;
		props.setFocused();
	}, [gameRef, props.setFocused]);
	
	if (currIndex < 0) return null;

	return (
		<div id={[props.id, props.board].join('.')} className={styles.wrapper} onClick={handleClick} ref={gameRef}>
			<div className={styles.boardLabel}>
				<FaAngleLeft className={[styles.pan, currIndex ? styles.active : styles.null].join(' ')} onClick={() => currIndex ? setIndex(currIndex - 1) : null}/>
				<span className='bold'>{_scores[0][1]}</span>
				<a href={'https://www.chess.com/live/game/' + g.url.split('/').pop()} target='_blank' rel='noopener noreferrer'>
					<FaChessBoard />
				</a>
				<span className='bold'>{_scores[1][1]}</span>
				<FaAngleRight className={[styles.pan, currIndex === props.pairing.games.length - 1 ? styles.null : styles.active].join(' ')} onClick={() => currIndex < props.pairing.games.length - 1 ? setIndex(currIndex + 1) : null}/>
			</div>
			{rendered.map((r, i) => <div key={[styles.gameViewWrapper, i].join('.')} className={[styles.gameViewWrapper, i === currIndex ? 'visible' : 'hidden'].join(' ')}>
				{r}
			</div>)}
		</div>
	);
}

function numerateResult(r: ChessResult): 0 | 0.5 | 1 {
	if (Object.keys(WinResult).includes(r)) return 1;
	if (Object.keys(DrawResult).includes(r)) return 0.5;
	if (Object.keys(LossResult).includes(r)) return 0;
	return 0;
}

function saturatePGN(g: Game, teamDict: {[key: string]: number}, teams: [string, string], round: number, board: number, game: number) {
	let flip = teamDict[g.black?.username.toLowerCase()];    // team 1 is black
	let arr = [
		`[WhiteTeam "${flip ? teams[0] : teams[1]}"]`,
		`[BlackTeam "${flip ? teams[1] : teams[0]}"]`,
		`[Board "${board}"]`,
		`[Game "${game + 1}"]`,
		g.pgn.replace('[Round "-"]', `[Round "${round}"]`)
	];
	return arr.join('\n');
}

export function SingleGame({ g }: {
	g: { pgn: string }
}) {

	
	const [pgn, setPGN] = useState('');
	
	useEffect(() => {
		setPGN('');
		if (!g.pgn) return;
		setPGN(g.pgn);
	}, [g, setPGN]);

	if (!pgn) return null;

	return (
		<PGN
			innerHTML={false}
			showCoordinates={true}
			width={'100%'}
			startAtLatest
		>
			{pgn}
		</PGN>
	);
}