import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

import { Player, Result, TournamentSettings } from '../../interfaces';
import styles from './css/sidebar.module.css';
import { FaThumbtack, FaSearch, FaFilter, FaAngleDown, FaEye, FaBullhorn, FaAngleUp, FaAngleRight } from 'react-icons/fa';
import Dropdown from '../../components/Dropdown';
import AutoComplete from '../../components/AutoComplete';
import { LoginContext, LiveContext } from '../../Contexts';
import { Match } from '../../models';
import Pie from './PieChart';
import { parseHtml, RouterLink } from '../../utils/plugins';
import { truncate } from '../../utils/prototype';
import Sticky from '../../components/Sticky';
import Twitch from '../Live/Twitch';

//, [getName, expanded.length, visible, setSearched, setVisible, title]);
export default function Sidebar({
	id, playerDict, fetchGames, matches, round,
	matchDict, results, announcements, getName, title,
	expanded, setExpanded, visible, setSearched, setVisible,
	settings
}: {
	id: string
	playerDict: Map<string, Player>
	fetchGames: (ids?: string[]) => Promise<void>
	matches: Match[],
	round: number,
	matchDict: {[key: string]: Match}
	results: Result[]
	showSidebar: boolean
	announcements: {
		announcement: string
		createdAt: string
		updatedAt: string
	}[]
	getName: (p: Player) => string
	title: string

	expanded: string[]
	setExpanded: (v: string[]) => void
	visible: string
	setVisible: (v: string) => void
	setSearched: (p: Player[]) => void

	settings: TournamentSettings
}) {

	const { isLoggedIn } = useContext(LoginContext);

	const last = announcements[announcements.length - 1];
	const pie = useMemo(() => <Pie
		matches={matchDict}
		round={round}
		total={results.length}
		donutWidth={30}
		height={200}
	/>, [matchDict, round, results.length]);

	const dropdown = useMemo(() => {
		return (
			<Dropdown
				current={visible}
				options={[
					'all',
					'aborted',
					'pending',
					'scheduled',
					'registered',
					'live',
					'finished',
					'confirmed'
				].filter(s => s === 'all' || matches.some(m => m && m.round === round && m.status === s))}
				resolve={setVisible}
				className={styles.statusPicker}
				headerIcon={<FaFilter />}
			/>
		);
	}, [visible, matches, setVisible, round]);

	const { isLive } = useContext(LiveContext);

	return <Sticky
		absoluteStyles={{ top: '400px' }}
		fixedStyles={{ top: '8vh' }}
		upperLimit={window.innerHeight * 10 / 100}
		lowerLimit={275}
	>
		<div className={styles.sidebar}>
			<div className={styles.name}>
				{title}
			</div>
			{isLive ? <Twitch height='200px' /> : null}
			{last ?
				<div className={styles.announcement}>
					{/* eslint-disable-next-line no-constant-condition */}
					{true ? //TODO
						<Link to={['', 't', id, 'announcements'].join('/') + '#latest'} className={styles.title}><FaBullhorn />Latest News</Link> :
						<div className={styles.title}><FaThumbtack style={{ transform: 'rotate(45deg)' }} />Pinned announcement</div>
					}
					<div className={styles.body}>
						{last ? <ReactMarkdown
							source={truncate(last.announcement.trim().split('*').join('').split('\n').map(l => l.trim()).join(' '), 200)}
							escapeHtml={false}
							astPlugins={[parseHtml]}
							renderers={{
								link: RouterLink
							}}
						/> : null}
					</div>
					<div className={styles.dates}>
						{new Date(last.createdAt).toString().slice(0, 21)}
					</div>
					<Link to={['', 't', id, 'announcements'].join('/')} className={styles.more}>
						View all<FaAngleRight />
					</Link>
				</div> : null
			}
			{pie}
			{dropdown}
			<div className={styles.searchBar}>
				<FaSearch />
				<AutoComplete
					onChange={setSearched}
					onSubmit={Promise.resolve}
					action=''
					options={Array.from(playerDict.values())}
					convert={getName}
					styles={styles}
					placeholder='Search'
				/>
			</div>
			<div className={['button', styles.expandAll].join(' ')} onClick={() => {
				if (matches.length === expanded.length) setExpanded([]);
				fetchGames().then(() => setExpanded(matches.map(m => m.id)));
			}}>
				<FaEye />{matches.length === expanded.length ? 'Hide' : 'View'} all scores
			</div>
			{!isLoggedIn ? null : <div className={['button', styles.jumpTo].join(' ')} onClick={() => document.getElementsByClassName('highlighted')[0].scrollIntoView()}>
				<FaAngleDown />Find my pairing
			</div>}
			<div className={['jumpToTop'].join(' ')} onClick={() => {
				window.scrollTo(0, 0);
				window.dispatchEvent(new Event('scroll'));
			}}>
				<FaAngleUp />
			</div>
		</div>
	</Sticky>;
}