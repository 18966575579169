import React, { ReactElement, useMemo, useCallback, useState, useContext, useReducer } from 'react';
import { TournamentStatus, Player as PlayerInterface, TournamentSettings } from '../interfaces';
import Chartist from 'react-chartist';
import ChartistMain, { ILineChartOptions } from 'chartist';
import tooltip from 'chartist-plugin-tooltips-updated';
import ctAxisTitle from 'chartist-plugin-axistitle';
import '../css/chart.css';
import pairingStyles from './Pairings/css/pairings.module.css';
import styles from '../css/stats.module.css';
import { randBetween } from '../utils/prototype';
import { FaCaretDown, FaCaretUp, FaMinus, FaAngleUp, FaAngleDown } from 'react-icons/fa';
import { TiebreakContext } from '../Contexts';
import usePlayers from './Standings/Players';
import Player from '../models/Player';
import ReactSlider from 'react-slider';
import { isMobile } from '../utils/auth';
import FullScreen from '../components/FullScreen';

interface TableProps {
	direction: string
	status: TournamentStatus
	players: PlayerInterface[]
	settings: TournamentSettings
	round: number
}

export default function Chart(props: TableProps): ReactElement {

	const [size, setSize] = useState(15);
	const players = usePlayers(props);

	const columns = useMemo((): {[key: string]: {mobile: string, name: string, value: (p: Player, round: number) => number, show?: (s: TournamentSettings) => boolean}} => {
		return {
			rating: {
				mobile: 'Rating',
				name: 'Rating',
				value: (p: Player): number => p.rating,
				show: (s: TournamentSettings) => false
			},
			score: {
				mobile: 'Match Points',
				name: 'MP',
				value: (p: Player, round: number) => p.histories.slice(1, round + 1).reduce((acc, curr) => (curr && typeof curr.match === 'number') ? acc += curr.match || 0 : acc, 0 as number)
			},
			gamePoints: {
				mobile: 'Game Points',
				name: 'GP',
				value: (p: Player, round: number) => p.histories.slice(1, round + 1).reduce((acc, curr) => (curr && typeof curr.match === 'number') ? acc += curr.game || 0 : acc, 0 as number),
				show: (s: TournamentSettings) => s.gamePointTotal !== 1
			},
			gameDifference: {
				mobile: 'Game Point Difference',
				name: 'G. +/-',
				value: (p: Player, round: number) => {
					let matchPoints = 0;
					let played = 0;
					p.histories.slice(1, round + 1).forEach((curr) => {
						if (!curr) return;
						matchPoints += curr.game || 0;
						played += matchPoints !== null ? curr.played : 0;
					});
					return matchPoints * 2 - played;
				},
				show: (s: TournamentSettings) => s.gamePointTotal !== 1
			},
			matchPercentage: {
				mobile: 'Match Point Percentage',
				name: 'M. %',
				value: (p: Player, round: number) => {
					let matchPoints = 0;
					let played = 0;
					p.histories.slice(1, round + 1).forEach((curr) => {
						if (!curr) return;
						matchPoints += curr.match || 0;
						played += 1 || 0;
					});
					if (played === 0) return -1;
					return matchPoints * 100 / played;
				}
			},
			gamePercentage: {
				mobile: 'Game Point Percentage',
				name: 'G. %',
				value: (p: Player, round: number) => {
					let gamePoints = 0;
					let played = 0;
					p.histories.slice(1, round + 1).forEach((curr) => {
						if (!curr) return;
						gamePoints += curr.game || 0;
						played += curr.played || 0;
					});
					if (played === 0) return -1;
					return gamePoints * 100 / played;
				},
				show: (s: TournamentSettings) => s.gamePointTotal !== 1
			},
			performanceRating: {
				mobile: 'Performance Rating',
				name: 'Perf',
				value: (p: Player) => p.performanceRating,
				show: (s: TournamentSettings) => false
			},
			ratingDifference: {
				mobile: 'Performance Rating Difference',
				name: 'P. +/-',
				value: (p: Player): number => {
					if (!p.performanceRating) return 0;
					return p.performanceRating - p.rating;
				},
				show: (s: TournamentSettings) => false
			},
			expectedScore: {
				mobile: 'Expected Score',
				name: 'Expected',
				value: (p: Player, round: number): number => p.histories.slice(1, round + 1).reduce((acc, curr) => (curr && typeof curr.expected === 'number') ? acc += curr.expected || 0 : acc, 0 as number),
				show: (s: TournamentSettings) => false
			},
			expectedDifference: {
				mobile: 'Score to Expected',
				name: 'E. +/-',
				value: (p: Player, round: number): number => p.histories.slice(1, round + 1).reduce((acc, curr) => (curr && typeof curr.expected === 'number' && typeof curr.match === 'number') ? acc += (curr.match - curr.expected) || 0 : acc, 0 as number),
				show: (s: TournamentSettings) => s.showRatings
			}
		};
	}, []);

	const totalRounds = useMemo((): number => {
		let totalRounds: number;
		if (props.status.round > props.settings.totalRounds) totalRounds = props.status.round;
		else if (props.settings.totalRounds === 'Infinity') totalRounds = props.status.round;
		else totalRounds = props.settings.totalRounds;
		return totalRounds;
	}, [props.status.round, props.settings.totalRounds]);
	
	const [labels, setLabels] = useReducer((s: string[], a: string[]) => {
		let res = [] as string[];
		for (let i = 0; i < Math.max(s.length, a.length); i++) {
			if (a[i]) res.push(a[i]);
			else if (s[i]) res.push(s[i]);
		}
		return res;
	}, [] as string[]);
	const table = useMemo((): {[key: string]: (number | undefined)[][]} => {
		let res = {} as {[key: string]: (number | undefined)[][]};
		let l = new Array(totalRounds + 1) as string[];
		let p = players.filter(p => p.id !== 'bye');
		for (let i = 0; i < p.length; i++) {
			let player = p[i];
			for (let [k, meta] of Object.entries(columns)) {
				if (!res[k]) res[k] = [];
				let row = [];
				for (let j = 1; j < totalRounds + 1; j++) {
					l[j - 1] = j.toString();				//label them assuming starting at round at the start of the array
					if (j >= player.histories.length) row.push(undefined);
					else row[j] = meta.value(player, j);
				}
				res[k][i] = row;
			}
		}
		setLabels(l);
		return res;
	}, [players, columns, totalRounds]);

	const tiebreaks = useContext(TiebreakContext);
	const [detailed, setDetailed] = useState(true);
	const [highPerf, setPerf] = useState(true);
	const [fullScreen, setFullScreen] = useState(false);

	const rank = useCallback((table: (number | undefined)[][], order: boolean): {meta: string, value: (number | undefined)}[][] => {
		let length = table[0].length;
		let rotated = [] as {meta: Player, value: number | undefined}[][];
		let ids = [] as [string, number | undefined][][];
		for (let j = 1; j < length; j++) {
			rotated[j] = [];
			for (let i = 0; i < table.length; i++) {
				rotated[j][i] = {
					meta: players[i],
					value: table[i][j]
				};
			}
			rotated[j] = rotated[j].sort((a, b) => {
				if (a.value === undefined) return 1;
				else if (b.value === undefined) return -1;
				if (a.value !== b.value) return b.value - a.value;
				let allTb = tiebreaks.slice(0);
				let useTb = allTb.shift();
				while (useTb) {
					if (!useTb[1]) break;
					let res = useTb[1].func(a.meta, b.meta);
					if (res) return res;
					useTb = allTb.shift();
				}
				return 0;
			});
			ids[j] = rotated[j].map(c => c.value !== undefined ? [c.meta.id, c.value] : ['', undefined]);
		}
		let ranks = [] as {meta: string, value: (number | undefined)}[][];
		for (let i = 0; i < table.length; i++) {
			ranks[i] = [];
			for (let j = 1; j < length; j++) {
				let p = players[i];
				let index = ids[j].findIndex(id => id[0] === p.id);
				ranks[i][j - 1] = {			// move the final row conversion back one column
					meta: p.firstName + ' ' + p.lastName,
					value: (p.histories[j] && typeof p.histories[j].match === 'number' && index >= 0) ?
						(order ? -index - 1 : ids[j][index][1]) :
						undefined
						
				};
			}
		}
		return ranks;
	}, [players, tiebreaks]);

	const [key, setKey] = useState([] as JSX.Element[]);
	const [order, setOrder] = useState(true);
	const [lastColumn, setLastColumn] = useState(1);
	const [defGraph, setGraph] = useState('score');

	const GraphOptions = useMemo(() => {
		return Object.entries(columns).map(([k, meta], i) => {
			if (meta.show && !meta.show(props.settings)) return null;
			return (
				<div
					className={['header-button', pairingStyles.pairingRound, defGraph === k ? pairingStyles.roundSelected : ''].join(' ')}
					onClick={() => setGraph(k)}
					key={['round', i].join('.')}
				>
					<div className='full-text'><h4>{meta.mobile}</h4></div>
				</div>
			);
		});
	}, [columns, defGraph, setGraph, props.settings]);


	const OrderOptions = useMemo(() => {
		return <>
			<div
				className={['header-button', pairingStyles.pairingRound, order ? pairingStyles.roundSelected : ''].join(' ')}
				onClick={() => setOrder(true)}
				key={['round', 'order'].join('.')}
			>
				<div className='full-text'><h4>Ranking</h4></div>
			</div>
			<div
				className={['header-button', pairingStyles.pairingRound, !order ? pairingStyles.roundSelected : ''].join(' ')}
				onClick={() => setOrder(false)}
				key={['round', 'unorder'].join('.')}
			>
				<div className='full-text'><h4>Points</h4></div>
			</div>
		</>;
	}, [order, setOrder]);

	const tooltipFnc = useCallback((meta: string, value: number) => {
		return [
			meta,
			order ? ('#' + (-Number(value)).toString()) : ''
		].join('\n');
	}, [order]);

	const orderedChart = useMemo(() => {
		let k = defGraph;
		if (!table[k]) return null;

		let ranked = rank(table[k], order);

		let half = Math.ceil(ranked.length /  2);
		for (let j = totalRounds; j >= 0; j--) {
			if (ranked.reduce((acc, curr, i) => {
				if (j < props.status.round && typeof curr[j - 1]?.value !== 'number') {
					//if (curr[j - 1]) curr[j - 1].value = -(i + 1);
				}
				return typeof curr[j]?.value === 'number' ? acc += 1 : acc;
			}, 0) > half) {
				setLastColumn(j);
				break;
			}
		}
		let sorted = ranked.sort((a, b) => {
			return (b[lastColumn]?.value || -Infinity) - (a[lastColumn]?.value || -Infinity);
		});
		let keys = sorted.map((c, i): ReactElement => {
			if (!order) return <>{c[lastColumn]?.meta}</>;
			if (!lastColumn) return <>{c[lastColumn]?.meta}</>;
			if (lastColumn === props.status.round) return <>#{i} {c[lastColumn]?.meta}</>;
			if (c[lastColumn]?.value === undefined || c[lastColumn]?.value === undefined) return <>
				<span className={['chartArrow'].join(' ')}>
					<div>{'\u200b'}</div>
					<div>{'\u200b'}</div>
				</span>					
				{c[lastColumn]?.meta}
			</>;
			let diff = (c[lastColumn]?.value as number) - (c[lastColumn - 1]?.value as number);
			let arrow = diff > 0 ?
				<FaCaretUp /> :
				diff < 0 ?
					<FaCaretDown /> :
					<FaMinus />;
			return (
				<>
					<span className={['chartArrow', diff > 0 ? 'chartPositive' : diff < 0 ? 'chartNegative' : 'chartNeutral'].join(' ')}>
						<div>{Math.abs(diff) || '\u200b'}</div>
						{arrow}
					</span>					
					{c[lastColumn]?.meta}
				</>
			);
		});
		setKey(keys);

		if (order) ranked.push([{
			meta: 'placeholder',
			value: -ranked.length
		}]);

		tooltip({
			tooltipFnc
		});
		ChartistMain.plugins.tooltip({ tooltipFnc });

		ctAxisTitle();
		return (
			<Chartist
				data={{
					labels,
					series: ranked
				}}
				options={{
					fullWidth: true,
					divisor: 0.5,
					// As this is axis specific we need to tell Chartist to use whole numbers only on the concerned axis
					axisY: {
						offset: 25,
						onlyInteger: order,
						labelInterpolationFnc: (value: number) => order ? value ? '#' + (-value).toString() : '' : value,
					},
					width: fullScreen ? window.innerWidth * 0.8 : '100%',
					height: fullScreen ? window.innerHeight - 150 :
						order && detailed ? size * (table[k].length + (size < 21.7 ? 4.2 : 0)):
							window.innerHeight * 0.8,
					plugins: [
						ChartistMain.plugins.tooltip({
							tooltipFnc
						}),
						ChartistMain.plugins.ctAxisTitle({
							axisX: {
								axisTitle: 'Round',
								axisClass: 'ct-axis-title',
								textAnchor: 'middle'
							}
						})
					]
				} as ILineChartOptions}
				type='Line'
			/>
		);
	}, [table, defGraph, lastColumn, rank, labels, totalRounds, order, tooltipFnc, props.status.round, size, detailed, fullScreen]);

	const css = useMemo(() => {
		let text = '';
		for (let i = 'p'.charCodeAt(0); i < 'z'.charCodeAt(0) + 1; i++) {
			let colour = randBetween(0, 65536).toString(16);
			text += `.ct-series.ct-series-${String.fromCharCode(i)} {
				stroke: #${'0'.repeat(6 - colour.length)}${colour};
			}`;
			text += `.ct-series.ct-series-${String.fromCharCode(i)} > path {
				stroke: #${'0'.repeat(6 - colour.length)}${colour};
			}`;
		}
		return text;
	}, []);
	
	return (
		<>
			<section className={['container', 'bodyContainer', 'animated-' + props.direction].join(' ')}>
				<div className='button-list'>
					{GraphOptions}
				</div>
			</section>
			<section className={['container', 'bodyContainer', 'animated-' + props.direction].join(' ')}>
				<div className='button-list'>
					{OrderOptions}
				</div>
			</section>
			<section className={'animated-' + props.direction}>
				<style>
					{css}
				</style>
				<div className={pairingStyles.description}>
					This chart shows the rankings for each team throughout the rounds, beginning with round 1 on the left.<br />
					Switch to 'Points' above to see each team's points progression.<br />
					
				</div>
				<div className={'sliderContainer container'}>
					<div>Size</div>
					<ReactSlider
						className={pairingStyles.slider}
						thumbClassName={pairingStyles.thumb}
						trackClassName={pairingStyles.track}
						min={5}
						max={!detailed || !order ? undefined : Math.max(30, window.innerHeight / players.length)}
						step={highPerf ? 0.5 : 5}
						value={!detailed || !order ? window.innerHeight * 0.8 / players.length : size}
						disabled={!detailed || !order}
						onChange={(n) => typeof n === 'number' ? setSize(n) : null}
						renderThumb={(p, s) => <div {...p}>{s.valueNow}</div>}
					/>
					<div className='increment' onClick={() => size >= 6 ? setSize(size - 1) : null}><FaAngleDown /></div>
					<div className='increment'  onClick={() => size <= 29 ? setSize(size + 1) : null}><FaAngleUp /></div>
				</div>
				<div className={'optionsContainer container'}>
					<div className={['header-button', pairingStyles.pairingRound, 'switchContainer'].join(' ')}>
						<label>
							<div>{detailed && order ? 'Detailed' : 'Fit'}</div>
							<div>{detailed && order ? 'Switch off to view on one screen' : order ? 'Switch to view player names' : 'Detailed view is only available with Ranking'}</div>
						</label>
						<div className='toggleSwitch' onClick={() => setDetailed(!detailed)}>
							<input type='checkbox' className='check' checked={detailed && order}/>
							<div className='toggleSlider round' />
						</div>
					</div>
					<div className={['header-button', pairingStyles.pairingRound, 'switchContainer'].join(' ')}>
						<label>
							<div>{highPerf ? 'High' : 'Low'} Performance</div>
							<div>Switch off if slow on Size change</div>
						</label>
						<div className='toggleSwitch' onClick={() => setPerf(!highPerf)}>
							<input type='checkbox' className='check' checked={highPerf}/>
							<div className='toggleSlider round' />
						</div>
					</div>
					<div className={['header-button', pairingStyles.pairingRound, 'switchContainer'].join(' ')}>
						<label>
							<div>Full Screen</div>
						</label>
						<div className='toggleSwitch' onClick={() => setFullScreen(!fullScreen)}>
							<input type='checkbox' className='check' checked={fullScreen}/>
							<div className='toggleSlider round' />
						</div>
					</div>
				</div>
				<div className={pairingStyles.description}>Hover to view team names.</div>
				<div className={['chartContainer', 'rankings'].join(' ')}>
					{orderedChart}
					{order && detailed ? <div className='chartKey' style={isMobile() ? {} : {
						visibility: size < 10 ? 'hidden' : 'visible',
						gridTemplateRows: `repeat(${key.length}, ${size}px)`,
						left: `calc(${(lastColumn + 0) / (totalRounds - 1) } * 100%)`,
						fontSize: (Math.min(size, 25) - 3) + 'px',
						marginTop: size > 21.7 ? '2px' : (size + 6) + 'px'
					}}>
						{key.map((meta: ReactElement, i: number) => {
							return (
								<div key={['id', i].join('.')} className='playerLabel'>
									<hr style={{
										display: isMobile() || lastColumn >= totalRounds - 1 ? 'none' : 'block',
										width: 0.8 / (totalRounds + 2) * (window.innerWidth - 60)
									}}/>
									<div>{meta}</div>
								</div>
							);
						})}
					</div> : null}
				</div>
			</section>
			<FullScreen className={styles.fsChart} useState={[fullScreen, setFullScreen]}>
				<div className={styles.header}>
					<div className={styles.title}>
						{props.status.name}
					</div>
					<div className={styles.subtitle}>
						{columns[defGraph].mobile}
					</div>
				</div>
				{orderedChart}
				{order && detailed && size > 15 ? <div className='chartKey' style={isMobile() ? {} : {
					visibility: size < 10 ? 'hidden' : 'visible',
					gridTemplateRows: `repeat(${key.length}, ${size}px)`,
					left: `calc(${(lastColumn + 0) / (totalRounds - 1) } * 100%)`,
					fontSize: (Math.min(size, 25) - 3) + 'px',
					marginTop: size > 21.7 ? '2px' : (size + 6) + 'px'
				}}>
					{key.map((meta: ReactElement, i: number) => {
						return (
							<div key={['id', i].join('.')} className='playerLabel'>
								<hr style={{
									display: isMobile() || lastColumn >= totalRounds - 1 ? 'none' : 'block',
									width: 0.8 / (totalRounds + 2) * (window.innerWidth - 60)
								}}/>
								<div>{meta}</div>
							</div>
						);
					})}
				</div> : null}
				<div className={styles.name}>
					Results by ScorchChess
				</div>
				<div className={styles.url}>
					https://scorchapp.co.uk
				</div>
			</FullScreen>
		</>
	);
}