import React, { useCallback, useContext, useState, useEffect, useReducer } from 'react';
import cx from 'classnames';
import { Redirect } from 'react-router-dom';
import styles from './css/user.module.css';
import { ProfileData, Team, SmallTeam } from '../interfaces';
import { FaToggleOn, FaToggleOff } from 'react-icons/fa';
import { POST, GET, cookies } from '../utils/requests';
import { AlertContext, LoginContext } from '../Contexts';
import * as regexes from '../utils/regexes';
import { capitalise } from '../utils/prototype';

export default function Settings(props: {
	updateProfile: () => void
	updateBio: () => void
}) {

	const { profile } = useContext(LoginContext);
	const setAlert = useContext(AlertContext);

	const toggleTheme = useCallback(() => POST({
		url: '/profile/updateSetting',
		data: {
			theme: profile?.theme === 'light' ? 'dark' : 'light'
		}
	}).then(props.updateProfile), [profile, props.updateProfile]);

	const updateBackground = useCallback((url: string) => POST({
		url: '/profile/updateSetting',
		data: {
			backgroundURL: url
		}
	}).then(props.updateProfile), [props.updateProfile]);
	const handleBackground = useCallback(() => {
		setAlert({
			title: 'Add background image',
			message: 'Add a link to the background image you want to use. Leave empty to clear.',
			type: 'prompt',
			resolve: updateBackground,
			prompt: {
				validate: (v: string | undefined) => v ? regexes.link.test(v) : true,
				errorMessage: 'Must be a valid link to an image',
				allowNull: true,
				defaultValue: profile?.backgroundURL
			}
		});
	}, [setAlert, updateBackground, profile]);

	const [noAnimations, toggleNoAnimations] = useState(!!cookies.getLocal('noAnimations'));
	const toggleAnimations = useCallback(() => {
		cookies.setLocal('noAnimations', !noAnimations);
		toggleNoAnimations(!noAnimations);
	}, [noAnimations, toggleNoAnimations]);
	
	if (!profile) return null;

	return (<>
		<section className={['container', styles.wrapper].join(' ')}>
			<div className={['header-button', styles.darkMode, 'switchContainer'].join(' ')}>
				<label>
					<div>
						{profile.theme === 'light' ? 
							'Light Mode' :
							'Dark Mode'
						}
					</div>
				</label>
				<div className='toggleSwitch' onClick={toggleTheme}>
					{profile.theme === 'light' ? 
						<FaToggleOn /> :
						<FaToggleOff />
					}
				</div>
			</div>
			<div className={['header-button', styles.darkMode, 'switchContainer'].join(' ')} onClick={handleBackground}>
				{profile.backgroundURL ?
					<>
						<a href={profile.backgroundURL} target='_blank' rel='noopener noreferrer'>
							Background set
						</a>{'\u200b'} | Change
					</> :
					'Add background image'
				}
			</div>
			<div className={['header-button', styles.darkMode, 'switchContainer'].join(' ')}>
				<label>
					<div>
						Animations
					</div>
				</label>
				<div className='toggleSwitch' onClick={toggleAnimations}>
					{!noAnimations ? 
						<FaToggleOn /> :
						<FaToggleOff />
					}
				</div>
			</div>
		</section>
		<section className={['container'].join(' ')}>
			<div className={['header-button', styles.darkMode, 'switchContainer'].join(' ')} onClick={props.updateBio}>
				Update bio
			</div>
		</section>
	</>);
}

export function TeamSettings(props: {
	user: ProfileData
	profile: Team & ProfileData
	updateProfile: () => void
	updateBio: () => void
}) {

	const { profile } = useContext(LoginContext);
	const setAlert = useContext(AlertContext);

	const [showMerge, toggleMerge] = useReducer((state) => !state, false);
	const [redirectTo, setRedirect] = useState('');

	const [teams, setTeams] = useState([] as SmallTeam[]);
	const updateTeams = useCallback(() => GET({ url: '/user/' + profile?.username })
		.then((data: ProfileData) => setTeams(data.teams)), [profile, setTeams]);
	useEffect(() => { updateTeams(); }, [updateTeams]);

	const [keepFields, setField] = useReducer((state: string[], action: { k: string, set: boolean }) => {
		let fields = state.slice(0);
		if (action.set && !fields.includes(action.k)) fields.push(action.k);
		else if (!action.set) {
			if (fields.includes(action.k)) fields.splice(fields.indexOf(action.k), 1);
		}
		return fields;
	}, []);

	const handleMerge = useCallback(async (smallTo: SmallTeam) => {
		const from = props.profile;
		const to = await GET({ url: ['', 'team', smallTo.id].join('/') }) as Team & ProfileData;

		const originalFields = ['description', 'ecf', 'fide'].filter(k => from[k as keyof typeof from]);
		setAlert({
			title: `Confirm the merger of ${from.name} into ${to.name}?`,
			message: <>
				{originalFields.length ? 'Please select which fields to keep' : ''}
				{originalFields.map((k, i) => {
					if (from[k as keyof typeof from] === to[k as keyof typeof to]) return null;
					return <>
						Which {capitalise(k)} to keep?
						<div key={[k, i].join('.')}>
							<div onClick={() => setField({ k, set: true })}>{from[k as keyof typeof from]}</div>
							<div onClick={() => setField({ k, set: false })}>{to[k as keyof typeof to]}</div>
						</div>
					</>;
				})}
			</>,
			type: 'confirm',
			resolve: () => POST({
				url: ['', 'team', from.id, 'merge'].join('/'),
				data: {
					to: to.id,
					keepFields
				}
			}).then(() => setAlert({ message: '', title: ''})).then(() => setRedirect('/team/' + to.id))
		});
	}, [setAlert, props.profile, keepFields, setField, setRedirect]);

	if (!profile) return null;
	if (redirectTo) return <Redirect to={redirectTo} />;

	return (<>
		<section className={['container', styles.wrapper].join(' ')}>
			<div className={['header-button', styles.merge].join(' ')} onClick={toggleMerge}>
				Merge team into...
			</div>
			{!showMerge ? null :
				teams.length ? teams.map((t, i) => {
					if (t.id === props.profile.id) return null;
					return <div key={cx(t.id, i)} className={['header-button', styles.teamMerge].join(' ')}onClick={() => handleMerge(t)}>
						{t.name}
					</div>;
				}) :
					'No teams available into which to merge. You must be admin of both teams to create a merge request.'
			}
		</section>		
		<section className={['container'].join(' ')}>
			<div className={['header-button', styles.darkMode, 'switchContainer'].join(' ')} onClick={props.updateBio}>
				Update description
			</div>
		</section>
	</>);
}