import { useState, useCallback, useEffect, useReducer } from 'react';
import PlayerData from '../../models/Player';
import { Bonus, Player, TournamentStatus, TournamentSettings } from '../../interfaces';
import { GET, server } from '../../utils/requests';
import url from 'url';

interface Props {
	status: TournamentStatus
	players: Player[]
	settings: TournamentSettings
	round: number
}

const def = [] as PlayerData[];

export default function usePlayers(props: Props): PlayerData[] {

	const [hasLoaded, load] = useState(false);
	const [bonuses, setBonuses] = useState([] as Bonus[]);
	const reducePlayers = useCallback((state: PlayerData[], action: 'props' | 'round' | 'settings' | 'bonuses'): PlayerData[] => {
		switch (action) {
		case 'props': {
			if (!props.players.length) return state;
			return props.players.map(p => new PlayerData(p, props.round, props.settings));
		}
		case 'round': {
			if (!props.round) return state;
			if (!state.length) return state;
			let players = state.slice(0);
			for (let p of players) {
				p.setRound(props.round);
			}
			return players;
		}
		case 'settings': {
			if (!props.settings.winReward) return state;
			return props.players.map(p => new PlayerData(p, props.round, props.settings));
		}
		case 'bonuses': {
			if (!bonuses.length) return state;
			if (!state.length) return state;
			let set = false;
			let players = state.slice(0);
			for (let b of bonuses) {
				let index  = players.findIndex(p => p.id === b.id);
				if (index < 0) continue;
				if (!players[index].bonuses) {
					players[index].setBonuses(bonuses);
					set = true;
				}
			}
			if (set) return players;
			else return state;
		}
		default: {
			return state;
		}
		}
	}, [props.players, props.round, props.settings, bonuses]);
	const [players, updatePlayers] = useReducer(reducePlayers, def);

	useEffect(() => {
		updatePlayers('props');
	}, [updatePlayers, props.players]);
	useEffect(() => {
		updatePlayers('settings');
	}, [updatePlayers, props.settings]);
	useEffect(() => {
		updatePlayers('round');
	}, [updatePlayers, props.round]);
	useEffect(() => {
		if (!bonuses.length) return;
		updatePlayers('bonuses');
	}, [updatePlayers, bonuses]);

	const updateBonuses = useCallback(() =>  props.status.id && !hasLoaded ? GET({ url: url.resolve(server, ['tournament', props.status.id, 'fetchBonuses'].join('/'))})
		.then(setBonuses).finally(() => load(true)) : null, [props.status.id, setBonuses, load, hasLoaded]);
	useEffect(() => {
		if (hasLoaded) return;
		updateBonuses();
	}, [updateBonuses, hasLoaded, load]);

	if (!hasLoaded) return def;
	return players;

}