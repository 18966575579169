import React, { useState, useCallback, useMemo, useEffect, useContext, useReducer } from 'react';
import cx from 'classnames';
import { TournamentStatus, TournamentSettings } from '../../interfaces';
import { Result } from '../../resources/result';
import { DisplayNameContext } from '../../Contexts';
import { FaCircle, FaAngleRight, FaAngleLeft } from 'react-icons/fa';
import { useScore } from '../hooks/useScore';

import styles from './css/knockout.module.css';

interface KnockoutProps {
	status: TournamentStatus
	settings: TournamentSettings
	results: Result[]
	stage: number
	start?: number
	finish?: number
	poolNames?: string[]
}
const w = 5; //circleWidth
export default function Knockout(props: KnockoutProps) {

	const results = useMemo(() => props.results, [props.results]);
	const totalRounds = useMemo(() => props.settings.totalRounds === 'Infinity' ? results.reduce((acc, curr) => curr.round > acc ? curr.round : 0, 0) : props.settings.totalRounds, [props.settings, results]);
	const start = useMemo(() => props.start ?? 1, [props.start]);
	const finish = useMemo(() => props.finish ?? props.status.round, [props.finish, props.status.round]);

	const columns = useMemo(() => {
		let arr = [] as Result[][];
		if (!totalRounds) return arr;
		for (let i = start; i < finish + 1; i++) {
			//let power = (finish) - i;
			//let total = 2 ** power;
			if (!arr[i]) arr[i] = [];
			for (let r of results) {
				if (r.round !== i) continue;
				arr[i].push(r);
				//if (arr[i].length) break;
			}
		}
		return arr;
	}, [results, start, finish]);

	const [stage, setStage] = useState(null as null | HTMLElement);
	const getDisplayName = useContext(DisplayNameContext);

	const [coords, setCoords] = useReducer((state: [DOMRect, DOMRect][][], action: [DOMRect, DOMRect][][]) => {
		for (let i = 0; i < action.length; i++) {
			if (!state[i]) return action;
			for (let j = 0; j < action[i].length; j++) {
				let s = state[i][j];
				let a = action[i][j];
				if (!s) return action;
				if (s[0].x !== a[0].x) return action;
				if (s[0].y !== a[0].y) return action;
				if (s[1].x !== a[1].x) return action;
				if (s[1].y !== a[1].y) return action;
			}
		}
		return state;
	}, []);
	const [hasRenderedLines, setRenderedLines] = useState(false);
	const renderLines = useCallback(() => {
		if (hasRenderedLines) return;
		if (!results.length) return;
		let starts = Array.from(document.getElementsByClassName(styles.winner));
		let ends = Array.from(document.getElementsByClassName(styles.all));

		let c = [] as [DOMRect, DOMRect][][];
		let tos = [] as {[key: string]: HTMLDivElement}[];
		for (let to of ends) {
			let arr = to.getAttribute('data-svg') || '';
			let [stage, round, id] = arr.split('.');
			if (Number(stage) !== props.stage) continue;
			if (!tos[Number(round)]) tos[Number(round)] = {};
			tos[Number(round)][id] = to as HTMLDivElement;
		}
		let hasRendered = false;
		for (let from of starts) {
			let arr = from.getAttribute('data-svg') || '';
			let [stage, round, id] = arr.split('.');
			if (Number(stage) !== props.stage) continue;
			if (!tos[Number(round)]) continue;
			let to = tos[Number(round)][id];
			if (!from || !to) continue;
			if (!c[Number(round)]) c[Number(round)] = [];
			c[Number(round)].push([
				from.getBoundingClientRect(),
				to.getBoundingClientRect()
			]);
			if (!hasRendered) hasRendered = true;
		}
		setCoords(c);
		setRenderedLines(hasRendered);
	}, [results.length, totalRounds, setCoords, hasRenderedLines, setRenderedLines, props.start, props.finish, props.stage]);
	const tL = useMemo(() => coords.flat(2).reduce((acc, curr) => [curr.left < acc[0] ? curr.left : acc[0], curr.top < acc[1] ? curr.top : acc[1]], [100000, 100000]), [coords]);
	const bR = useMemo(() => coords.flat(2).reduce((acc, curr) => [curr.left > acc[0] ? curr.left : acc[0], curr.top > acc[1] ? curr.top : acc[1]], [0, 0]), [coords]);

	// Work out the position of the containing div on the page and get it's top-left point. Ex: [79.5, 434.765625]
	const [min, calculateMin] = useReducer(() => {
		if (!stage) return [0, 0];
		let r = stage.getBoundingClientRect();
		return [r.left, r.top];
	}, [0, 0]);
	useEffect(() => {
		renderLines();
	});
	useEffect(() => {
		calculateMin();
	}, [calculateMin, stage]);
	useEffect(() => {
		let x = () => {
			calculateMin();
			setRenderedLines(false);
		};
		window.addEventListener('resize', x);
		return () => window.removeEventListener('resize', x);
	}, [setRenderedLines, calculateMin]);
	useEffect(() => {
		let f = () => window.dispatchEvent(new Event('resize'));
		f();
		let x = setTimeout(f, 1000);
		return () => clearTimeout(x);
	}, [stage]);

	useEffect(() => {
		let main = document.getElementById('root');
		if (!main) return;
		if (stage) {
			main.style.overflowY = '';
			main.style.maxHeight = '';
		} else {
			main.style.overflowY = 'hidden';
			main.style.maxHeight = '100vh';
		}
	}, [stage]);

	const reduceScroll = useCallback(() => {
		if (!stage) return [false, true];
		let l = false, r = false;
		if (stage.scrollLeft > 10) l = true;
		if (stage.scrollLeft + stage.offsetWidth < stage.scrollWidth - 10) r = true;
		return [l, r];
	}, [stage]);
	const [canScroll, updateScroll] = useReducer(reduceScroll, [false, true]);
	useEffect(() => {
		if (!stage) return;
		updateScroll();
		stage.addEventListener('scroll', updateScroll);
		return () => stage.removeEventListener('scroll', updateScroll);
	}, [stage, updateScroll]);

	const scroll = useCallback((direction: 'left' | 'right') => {
		if (!stage) return;
		const amount = 100;
		stage.scrollBy({
			left: amount * (direction === 'left' ? -1 : 1),
			behavior: 'smooth'
		});
	}, [stage]);

	const { getScores } = useScore({ settings: props.settings });

	return <section className={styles.container} data-embed-id='knockout'>
		<div className={styles.scroll} id={styles.left} onClick={() => scroll('left')} style={{ opacity: canScroll[0] ? undefined : 0}}>
			<FaAngleLeft />
		</div>
		<div className={styles.scroll} id={styles.right} onClick={() => scroll('right')} style={{ opacity: canScroll[1] ? undefined : 0}}>
			<FaAngleRight />
		</div>
		<div className={[styles.stage, 'scrollable'].join(' ')} ref={setStage}>
			<div className={styles.columns}>
				{columns.map((c, i, arr) => {
					if (!c) return null;
					let nth = arr.slice(0, i + 1).filter(v => v).length;
					let paddingTop = !c.length ? 0 : (45 * (2 ** (nth - 1)) - 45) + 'px';
					return <>
						<div className={styles.roundNumber}>
							Round {i}
						</div>
						<div key={['column', i].join('.')} className={styles.column} style={{
							paddingTop,
							paddingBottom: paddingTop
							//height: 'calc(100% - ' + marginTop + ')'
						}}>
							{/*<div style={{ height: (2 * (i - 1)) + 'em' }} />*/}
							{c.map((r, j) => {
								return <>
									{r.pool && r.pool !== c[j - 1]?.pool ?
										<div className={cx(styles.poolTitle, {[styles.visible]: nth <= 1 })}>
											{props.poolNames?
												props.poolNames[r.pool] ?? 'Bracket ' + r.pool :
												'Bracket ' + r.pool
											}
										</div> :
										null
									}
									<div key={['matchUp', j].join('.')} className={styles.matchUp}>
										<div className={[styles.white].join(' ')}>
											{i > start ? <div data-svg={[props.stage, i - 1, r.idW].join('.')} className={styles.all} /> : null}
											<FaCircle style={{color: 'white'}} />
											<div
												className={['playerInfo', styles.playerInfo, r.idW === 'bye' ? styles.bye : ''].join(' ')}
											>{getDisplayName(r.idW)}</div>
											<div className={cx(styles.score, {[styles.win]: r.matchW})}>
												{getScores(r)[0]}
											</div>
											{r.matchW && i < finish ? <div data-svg={[props.stage, i, r.idW].join('.')} className={styles.winner} /> : null}
										</div>
										<div className={[styles.black].join(' ')}>
											{i > start ? <div data-svg={[props.stage, i - 1, r.idB].join('.')} className={styles.all} /> : null}
											<FaCircle style={{color: 'black'}} />
											<div
												className={['playerInfo', styles.playerInfo, r.idB === 'bye' ? styles.bye : ''].join(' ')}
											>{getDisplayName(r.idB)}</div>
											<div className={cx(styles.score, {[styles.win]: r.matchB})}>
												{getScores(r)[1]}
											</div>
											{r.matchB && i < finish ? <div data-svg={[props.stage, i, r.idB].join('.')} className={styles.winner} /> : null}
										</div>
									</div>
									{j < c.length - 1 ? <div className={styles.spacer} /> : null}
								</>;
							})}
							{/*<div style={{ height: (2 * (i - 1)) + 'em' }} />*/}
						</div>
					</>;
				})}
			</div>
			<svg className={styles.connectors} style={{left: tL[0] - min[0] + w / 2, top: tL[1] - min[1] + w / 2 }} width={bR[0] < tL[0] ? 0 : bR[0] - tL[0]} height={bR[1] < tL[1] ? 0 : bR[1] - tL[1]}>						
				{coords.map((round, i) => <g key={['lines', i].join('.')} className={['ct-series', 'ct-series-' + String.fromCharCode(i + 65 - 1)].join(' ')}>
					{round.map(([from, to], j) => {
						const [a, b] = [1 / 3, 2 / 3];
						const q = 1 / 2;
						
						let f = [from.left - tL[0], from.top - tL[1]];
						let t = [to.left - tL[0], to.top - tL[1]];
						let m = [(f[0] + t[0]) / 2, (f[1] + t[1]) / 2];
						let c1 = [f[0] + (m[0] - f[0]) * b, f[1] + (m[1] - f[1]) * 0];
						let c2 = [f[0] + (m[0] - f[0]) * 1, f[1] + (m[1] - f[1]) * a];
						let c3 = [m[0] + (t[0] - m[0]) * a, m[1] + (t[1] - m[1]) * 1];

						let q1 = [f[0] + (t[0] - f[0]) * q, f[1] + (t[1] - f[1]) * 0];
						let q2 = [f[0] + (t[0] - f[0]) * 1, f[1] + (t[1] - f[1]) * q];

						return (
							<path
								key={['path', j].join('.')}
								className={[styles.line, 'ct-line'].join(' ')}
								d={Object.entries({
									/* Straight Lines */
									//M: [from.left - tL[0], from.top - tL[1]],
									//L: [to.left - tL[0], to.top - tL[1]]

									/* Single Cubic */
									M: f.join(' '),
									C: [
										q1,
										q2,
										t
									].map(l => l.join(' ')).join(', ')

									/* Cubic Beziers *//*
									M: f.join(' '),
									C: [
										c1,
										c2,
										m
									].map(l => l.join(' ')).join(', '),
									S: [
										c3,
										t
									].map(l => l.join(' ')).join(', ')*/
								}).map(([k, v]) => k + v).join(' ')}
							/>
						);
					})}
				</g>)}		
			</svg>
		</div>
	</section>;
}