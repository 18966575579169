import React, { ReactElement, createRef, RefObject, useState, Dispatch, SetStateAction } from 'react';
import { ModalGateway, Modal } from 'react-images';
import styles from '../css/fullscreen.module.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../css/chart.css';

interface FSProps {
	children: ReactElement | (ReactElement | null)[]
	className?: string
	useState?: [boolean, Dispatch<SetStateAction<boolean>>]
}

export default function FullScreen(props: FSProps) {

	const [full, setF] = useState(false);
	let [fullScreen, setFullScreen] = props.useState || [full, setF];
	const fullScreenChart = createRef() as RefObject<HTMLDivElement>;

	if (!fullScreen) return null;
	return (
		<ModalGateway>
			<Modal onClose={() => setFullScreen(false)}>
				<div className={['chartContainer', 'rankings', styles.container, props.className].join(' ')} ref={fullScreenChart}>
					<div className={[styles.fullScreenHeader, 'header--isModal css-111ute9 css-1ycyyax'].join(' ')}>
						<span />
						<span>
							<button type="button" title="Enter fullscreen (f)" className="header_button __header_button--fullscreen css-m6j0xf css-1ycyyax" onClick={() => !document.fullscreenElement && fullScreenChart.current ? (fullScreenChart.current as HTMLElement).requestFullscreen() : document.exitFullscreen()}>
								<svg role="presentation" viewBox="0 0 24 24" className="css-1rkjyjh">
									<path d="M14.016 5.016h4.969v4.969h-1.969v-3h-3v-1.969zM17.016 17.016v-3h1.969v4.969h-4.969v-1.969h3zM5.016 9.984v-4.969h4.969v1.969h-3v3h-1.969zM6.984 14.016v3h3v1.969h-4.969v-4.969h1.969z" />
								</svg>
							</button>
							<button type="button" title="Close (esc)" className="react-images__header_button react-images__header_button--close css-m6j0xf css-1ycyyax" onClick={() => setFullScreen(false)}>
								<svg role="presentation" viewBox="0 0 24 24" className="css-1rkjyjh">
									<path d="M18.984 6.422l-5.578 5.578 5.578 5.578-1.406 1.406-5.578-5.578-5.578 5.578-1.406-1.406 5.578-5.578-5.578-5.578 1.406-1.406 5.578 5.578 5.578-5.578z" />
								</svg>
							</button>
						</span>
					</div>
					<div className={styles.stage}>
						{props.children}

					</div>
				</div>
			</Modal>
		</ModalGateway>
	);
}