import React, { Context, ReactNode, ReactElement, createContext, Dispatch, SetStateAction } from 'react';
import { Location } from 'history';
import { TieBreaks } from './resources/tiebreaks';
import { Player, Notif, ProfileData, TournamentSettings } from './interfaces';
import { AlertProps } from './components/NotifBox';

type ContextValue<T> = [Context<T>, T];

export default function Contexts({ values, children }: {
	values: ContextValue<any>[]
	children?: ReactNode
}) {

	return values.reduce((acc, [context, value]) => {
		return (
			<context.Provider value={value}>
				{acc}
			</context.Provider>
		);
	}, children) as any as ReactElement;
	
}

export const SettingsContext = createContext({} as TournamentSettings);

export const LoginContext = createContext({
	isLoggedIn: undefined as boolean | undefined,
	profile: undefined as ProfileData | null | undefined,
	updateProfile: () => Promise.resolve()
});
export const AuthContext = createContext({
	isAuthed: undefined as boolean | undefined,
	authLevel: 0
});
export const LocationContext = createContext({} as Location<{
	direction: string
	referrer: string
	currentPath: string
}>);

export const LiveContext = createContext({} as {
	channel: string
	site: 'twitch' | 'youtube'
	isLive: boolean
});

export const ThemeContext = createContext('light' as 'light' | 'dark');
export const TiebreakContext = createContext(Object.entries(TieBreaks));
export const DisplayNameContext = createContext((() => '') as (id: string | Player) => string);
export const NotifContext = createContext((() => null) as Dispatch<SetStateAction<Notif>>);
export const AlertContext = createContext(((props: AlertProps) => {}));