import React, { useEffect } from 'react';
import cx from 'classnames';
import { MdAnnouncement } from 'react-icons/md';
import * as regexes from '../utils/regexes';
import styles from './css/meta.module.css';
import { Notif } from '../interfaces';
import { getLogo, NotifContent } from '../components/NotifBox';

export default function Announcements(props: {
	announcements: Notif[]
}) {

	const { announcements } = props;

	useEffect(() => {
		if (!announcements.length) return;
		if (!window.location.hash) return;
		if (window.location.hash !== '#latest') return;
		window.location.hash = '#' + announcements[0].createdAt;
	}, [announcements]);

	return <div className={styles.container}>
		{!announcements.length ?
			null :
			<div className={[styles.nav].join(' ')}>
				<div className={[].join(' ')}>
					{announcements
						.sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf())
						.map((a, i) => {
							return <a key={cx('announcement', i)} href={'#' + a.createdAt} className={styles.date}>
								{new Date(a.createdAt).toString().slice(0, 21)}
							</a>;
						})}
				</div>
			</div>
		}
		{announcements.length ?
			announcements.sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()).map((a) => {
				let icon = <MdAnnouncement /> as JSX.Element | null;
				let domain = (a.link || '').match(regexes.link);
				if (domain) icon = getLogo(domain[1]);
				return <>
					<section className={styles.announcement} id={new Date(a.createdAt).valueOf().toString()} data-aos='fade-right'>
						<NotifContent notif={a} select={() => {}} styles={styles} />
					</section>
					<hr className={styles.divider} data-aos='fade' />
				</>;
			}) :
			<section className={styles.announcement}>
				This tournament has no announcements at this time.
			</section>
		}
	</div>;
}