import { useState, useMemo, useReducer, useCallback } from 'react';
import { Match } from '../../../models';
import { MatchConstructor } from '../../../models/Match';
import { Result, Player, TournamentSettings } from '../../../interfaces';
import * as regexes from '../../../utils/regexes';
import { shuffle } from '../../../utils/prototype';
import { Chess } from '../../../chess/ChessCom';

export default function useMatches({
	playerDict,
	results,
	settings
}: {
	playerDict: Map<string, Player>
	results: Result[]
	settings: TournamentSettings
}) {

	const [matchDict, setMatch] = useReducer((state: {[key: string]: Match}, action: MatchConstructor | {id: string, scored: null}) => {
		let res = Object.assign({}, state);
		if (action.scored === null) {
			res[action.id] = null as any as Match;
			return res;
		}
		let match = action;
		res[action.id] = new Match(match, match.round);
		return res;
	}, {});
	const matches = useMemo(() => Object.values(matchDict), [matchDict]);

	const populatematchDict = useCallback(async (results: Result[], round: number) => {
		for (let r of results) {
			let { id, idW, idB, fetched, board, pool} = r;
			let w = playerDict.get(idW.trim()) as Player;
			let b = playerDict.get(idB.trim()) as Player;
			if (!w && !b) {
				setMatch({id, scored: null});
				continue;
			}
			let [link, teamID] = getLink(r, r.linkSource, r.linkType);
			let { startTime, endTime } = r.fetched || {};
			let scored = Boolean(w?.histories[round]?.match || b?.histories[round]?.match);
			let match = Object.assign(r, {
				teamID, link, w, b, scored, startTime, endTime,
				scores: (w?.histories[round]?.game || b?.histories[round]?.game) ? [
					w?.histories ? w?.histories[round]?.game : null,
					b?.histories ? b?.histories[round]?.game : null,
				] : fetched?.scores || [null, null],
				bodyStatus: fetched?.isFinished ? 'finished' : ''
			} as MatchConstructor);
			setMatch(match);				
		}
	}, [setMatch, playerDict]);

	const beforeSetResults = useMemo(() => {
		if (settings.source === 'chessCom') {
			if (settings.competitors === 'team') {
				return Chess.clearQueue.bind(Chess);
			}
		}
		return () => {};
	}, [settings]);

	/* Match Fetching API */

	const [fetchedMatch, fetchMatch] = useState(null as null | Match);

	/**
	 * Matches which have already been fetched via client-side API call
	 */
	const [fetched, hasFetched] = useReducer((state: string[], action: string) => {
		let s = state.slice(0);
		s.push(action);
		return s;
	}, []);

	const canFetch = useMemo((): boolean => {
		let length = Object.values(matchDict).length;
		if (!length) return false;
		if (!results.length) return false;
		if (length < results.length) return false;
		return true;
	}, [matchDict, results]);

	const fetchNext = useCallback(() => {
		if (!canFetch) return;
		if (fetchedMatch && !fetched.includes(fetchedMatch.id)) return fetchMatch(fetchedMatch);
		let found = shuffle(Object.values(matchDict)).find((m: Match) => {
			if (!m) return false;
			if (!m.link) return false;
			if (fetched.includes(m.id)) return false;
			if (!m.startTime && !m.endTime) return true;
			if (m.games && !Array.isArray(m.games)) return true;
			if (m.endTime) {
				if (!m.scores || m.scores.every((s: number | undefined) => !s)) return true;
				if (Math.random() < 0.01) return true;
				return false;
			} else
			if (m.startTime) {
				if (Date.now() > m.startTime) {
					if (Math.random() < 0.25) return true;
					return false;
				}
				if (Math.random() < 0.01) return true;
				return false;
			} return true;
		});
		if (found) fetchMatch(found);
		else fetchMatch(null);
	}, [matchDict, fetchedMatch, fetchMatch, fetched, canFetch]);

	return {
		matches, matchDict, setMatch, populatematchDict,
		fetchedMatch, fetchNext, hasFetched,
		beforeSetResults
	};
}

function getLink(r: Result, source: 'lichess' | 'chessCom' | null, type: 'team' | 'individual'): [string, string] | [undefined, undefined] {
	if (source === 'chessCom') {
		if (type === 'team') {
			let matches = r.link?.match(regexes.chessCom) as RegExpMatchArray;
			if (!matches) return [undefined, undefined];
			return [
				'https://www.chess.com/club/matches/live/' + matches[1].trim(),
				matches[1]
			];
		}
	}
	return [undefined, undefined];
}