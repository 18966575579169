import React, { ReactElement, useCallback, useState, useEffect, useMemo } from 'react';
import { AxiosError } from 'axios';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import AOS from 'aos';
import { FaExclamationTriangle } from 'react-icons/fa';
import { Location } from 'history';

import 'aos/dist/aos.css';
import './App.css';
import './css/Table.css';
import './css/weglot.css';

import Tournament from './tournament/Tournament';
import NotFound from './404';
import { socket, GET, cookies, POST, setErrorCallback, isDev, restart } from './utils/requests';
import Home from './pages/Home';
import { Login } from './tournament/';
import { Community, Game, MDPage, Teams } from './pages/';
import { Team, User } from './user/';
import { isMobile } from './utils/auth';
import Contexts, { NotifContext, AlertContext, LoginContext, ThemeContext, LocationContext } from './Contexts';
import { ProfileData, Notif } from './interfaces';
import NotifBox, { AlertBox, AlertProps } from './components/NotifBox';
import * as regexes from './utils/regexes';

import Header, { defPathDict } from './components/Header';
import Footer from './components/Footer';
import News from './pages/News';
import Contact from './pages/Contact';
import { Feedback, GameLink } from './components/Floated';
import Embed from './pages/Embed';

AOS.init();

let lastTime = Date.now();

const slide = ['/', 'pairings', 'standings', 'stats', 'tv', 'community', 'team','player', '@', 'login'];
function handleEnter(referrer: string) {
	let index = findPageIndex(referrer);
	let section;
	if (index === 0.5) section = 'home';
	else if (index === 0.5) section = 'tournament';
	else section = slide[index];
	if (socket.connected) socket.emit('public_page', {
		section,
		tournament: index > 0 && index < 5 ? referrer.split('/')[1] : referrer.split('/')[2],
		time: (Date.now() - lastTime) / 60000,
		mobile: isMobile()
	});
	lastTime = Date.now();
}

function findPageIndex(pathname: string): number {
	for (let i = 0; i < slide.length; i++) {
		if (slide[i] === pathname) return i;
	}
	for (let i = slide.length; i > 0; i--) {
		if (pathname.includes(slide[i])) return i;
	}
	return 0.5;
}
function findIndex(pathname: string): number {
	return 0;
}

function direction(_from: string, _to: string): string {
	let res = '';
	let from = findIndex(_from);
	let to = findIndex(_to);
	if (to > from) res = 'right';
	else if (to < from) res = 'left';
	let [nFrom, nTo] = [_from, _to].map(p => Number(p.split('/').pop() as string));
	if (nFrom && nTo && !isNaN(nFrom) && !isNaN(nTo)) {
		if (nTo > nFrom) res = 'right';
		else if (nFrom > nTo) res = 'left';
	}
	if (res && to >= 1 && to <= 4 && from >= 1 && to <= 4) res = 'small-' + res;
	return res;
}

interface State {
	direction: string
	referrer: string
	currentPath: string
}

let state: State = {
	direction: '',
	referrer: '',
	currentPath: ''
};

function App(): ReactElement {

	const [selected, select] = useState({} as Notif);
	const [profile, setProfile] = useState({} as ProfileData);

	const [session, setSession] = useState(undefined as undefined | string);
	const isLoggedIn = useMemo(() => session === undefined ? undefined : !!session, [session]);
	const endSession = useCallback(async (): Promise<void> => {
		try {
			await POST({ url: '/endSession' });
		} catch (e) {
			console.error(e);
		} finally {
			await cookies.remove('sessionID');
			setSession('');
		}
	}, [setSession]);

	const [dark, setDark] = useState(false);
	useEffect(() => {
		let root = document.getElementById('root');
		if (!root) return;
		if (profile.theme === 'dark' || profile.backgroundURL) {
			root.className += ' dark';
			setDark(true);
		} else {
			root.className = root.className.replace(regexes.dark, '');
			setDark(false);
		}
		if (profile.backgroundURL) root.style.backgroundImage = `url(${profile.backgroundURL})`;
		else root.style.backgroundImage = '';
	}, [profile, setDark]);

	const updateProfile = useCallback(() => GET({ url: '/profile', timeout: isDev ? 50000 : 5000 })
		.then(setProfile)
		.catch(async (e: AxiosError) => {
			if (e.response?.status === 404) endSession();
			else await cookies.remove('sessionID');
		})
	, [setProfile, endSession]);

	const checkSession = useCallback(async () => {
		let c = await cookies.getAll();
		let sessionID = c.sessionID;
		/* eslint-disable-next-line no-throw-literal */
		if (!sessionID) throw c;
		setSession(sessionID);
	}, [setSession]);
	useEffect(() => {
		checkSession().catch(() => setSession(''));
	}, [checkSession]);

	useEffect(() => {
		restart(session as string);
	}, [session]);

	useEffect(() => {
		if (session) updateProfile().catch(console.error);
	}, [session, updateProfile]);

	const [alertProps, setAlert] = useState({
		title: '',
		message: '' as string | JSX.Element
	} as AlertProps);

	useEffect(() => {
		setErrorCallback((title: string, message: string) => {
			setAlert({
				icon: <FaExclamationTriangle />,
				title,
				message: typeof message === 'string' ? message.startsWith('<!DOCTYPE html>') ? <div dangerouslySetInnerHTML={{__html: message }} /> : message : JSON.stringify(message, null, 4),
				type: 'error'
			});
		});
	}, [setAlert]);

	return <>
		<Router basename={process.env.PUBLIC_URL}>
			<Contexts values={[
				[AlertContext, setAlert],
				[LoginContext, { isLoggedIn, profile, updateProfile }],
				[NotifContext, select],
				[ThemeContext, dark ? 'dark' : 'light']
			]}>
				<Route render={({location, history}) => {
					if (state.currentPath.split('?').shift() !== location.pathname) state = Object.assign(state || {}, {
						direction: direction(state.currentPath || location.pathname + location.search, location.pathname + location.search) || state.direction,
						referrer: state.currentPath || location.pathname + location.search,
						currentPath: location.pathname + location.search
					}) as State || {};
					location.state = state;
					handleEnter(state.referrer);
					let search = new URLSearchParams(location.search);
					return (
						<Contexts values={[
							[LocationContext, location]
						]}>
							{search.get('embed') ? <Embed {...{search}} /> : null}
							<Header setID={() => {}} profile={profile} referrer={state?.referrer} currentPath={location.pathname} />
							<Switch location={location}>
								<Route exact path='/' render={(props) => {
									return <Home {...props} />;
								}} />
								<Route exact path='/teams' component={Teams} />
								<Route exact path='/news' component={News} />
								<Route exact path='/contact' component={Contact} />
								<Route exact path='/community' render={(props) => {
									return <Community {...props} />;
								}} />
								<Route path='/me/' render={(props) => {
									if (isLoggedIn === false) return <Redirect to={{
										pathname: '/login',
										search: props.location.search,
										state: props.history.location.state
									}}/>;
									if (!profile.username) return null;
									return <Redirect push to={{
										pathname: props.location.pathname.replace('/me', '/@/' + profile.username),
										search: props.location.search,
										state: props.history.location.state
									}} />;
								}} />
								<Route path='/@/:id' render={(props) => {
									return <User {...props} id={props.match.params.id} profile={profile} updateProfile={updateProfile} endSession={endSession} />;
								}} />
								<Route path='/reset/password' render={(props) => {
									return <Login {...props} isLoggedIn={isLoggedIn} mode='reset/password' setSession={setSession} updateProfile={updateProfile}/>;
								}} />
								<Route path='/login' render={(props) => {
									let [pathname, search] = state.referrer.split('?');
									return isLoggedIn ?
										<Redirect push to={{
											pathname: pathname === '/login' ? '/me' : pathname,
											search,
											state: props.history.location.state
										}}/> :
										<Login {...props} isLoggedIn={isLoggedIn} mode='login' setSession={setSession} updateProfile={updateProfile}/>;
								}} />
								<Route path='/register' render={(props) => {
									let [pathname, search] = state.referrer.split('?');
									return isLoggedIn ?
										<Redirect push to={{
											pathname: pathname === '/register' ? '/me' : pathname,
											search,
											state: props.history.location.state
										}}/> :
										<Login {...props} isLoggedIn={isLoggedIn} mode='register' setSession={setSession} updateProfile={updateProfile}/>;
								}} />
								<Route path='/player/:id/' render={(props) => {
									return <Team {...props} id={props.match.params.id} profile={profile} type='player' />;
								}} />
								<Route path='/team/:id/' render={(props) => {
									return <Team {...props} id={props.match.params.id} profile={profile} type='team' />;
								}} />
								<Route path='/g/:id' render={(props) => {
									return <Game {...props} />;
								}} />
								<Route path='/t/:id/' render={(props) => {
									return <Tournament {...props} profile={profile} />;
								}} />
								<Route path='/tournament/:id/' render={(props) => {
									let [pathname, search] = state.referrer.split('?');
									return <Redirect push to={{
										pathname: '/t/' + pathname.slice('/tournament/'.length),
										search,
										state: props.history.location.state
									}}/>;
								}} />
								{['privacy', 'tos'].map((p, i) => {
									return <Route exact path={'/' + p} key={[p, i].join('.')} render={() => <MDPage page={p} />} />;
								})}
								{
									<Route path='/:id/' render={(props) => {
										if ([...Object.keys(defPathDict).map(v => v.split('/')[1]), 'tournament'].includes(props.match.params.id)) return <Route path='*' component={NotFound} status={404} />;
										return <Redirect push to={{
											pathname: '/t' + props.location.pathname,
											search: state.referrer.split('?')[1],
											state: props.history.location.state
										}}/>;
									}} />}
								<Route path='*' component={NotFound} status={404} />
							</Switch>
							<Footer />
						</Contexts>
					);
				}}/>
				<Feedback />
				<GameLink />
				<AlertBox {...alertProps} />
				<NotifBox notif={selected} select={select}/>

			</Contexts>
		</Router>
	</>;
}

export default App;