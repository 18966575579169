
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import * as regexes from './regexes';

import htmlParser from 'react-markdown/plugins/html-parser';
import HtmlToReact from 'html-to-react';

// See https://github.com/aknuds1/html-to-react#with-custom-processing-instructions
// for more info on the processing instructions
const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
export const parseHtml = htmlParser({
	isValidNode: (node: parseHTMLElement) => node.type !== 'script',
	processingInstructions: [
		{
			// Anything else
			shouldProcessNode: function (node: parseHTMLElement): boolean {
				return true;
			},
			processNode: processNodeDefinitions.processDefaultNode
		}
	]
});


interface parseHTMLElement extends HTMLElement {
	name: string
	attribs: {
		[key: string]: any
	}
	type: string
}

interface aProps {
	href: string,
	children: HTMLElement[]
}

export function RouterLink(props: aProps): ReactElement {
	if (regexes.link.test(props.href)) {
		return <a href={props.href} target='_blank' rel='noopener noreferrer' onClick={(e) => {
			e.stopPropagation();
		}}>{props.children}</a>;
	} else
	if (props.href.startsWith('https://results.scorchapp.co.uk')) {
		return <Link to={props.href.slice('https://results.scorchapp.co.uk'.length)}>{props.children}</Link>;
	} else return <Link to={props.href}>{props.children}</Link>;
}