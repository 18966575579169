import React, { useEffect, useState, useCallback, useContext, useMemo, useReducer } from 'react';
import { StaticContext } from 'react-router';
import { Link, RouteComponentProps } from 'react-router-dom';
import { NotifContext } from '../Contexts';

import styles from './css/home.module.css';
import '../css/reddit.css';

import * as regexes from '../utils/regexes';
import { GET, socket } from '../utils/requests';
import { Notif, TournamentStatus } from '../interfaces';
import { FaAngleRight } from 'react-icons/fa';
import { GoPrimitiveDot } from 'react-icons/go';
import { MdAnnouncement } from 'react-icons/md';
import { getLogo } from '../components/NotifBox';
import useRotating, { Rotator, TournamentAdd } from '../components/RotatingTournaments';
import NotFound from '../404';
import RedditPoll from '../components/RedditPoll';

function HomeNotifications({ n, i }: { n: Notif, i: number }) {

	const select = useContext(NotifContext);
	
	let icon = <MdAnnouncement /> as JSX.Element | null;
	let domain = (n.link || '').match(regexes.link);
	if (domain) icon = getLogo(domain[1]);
	return (
		<tr className={styles.notificationRow} key={['notification', i].join('.')}>
			<td>
				<div onClick={() => select(n)}>
					{n.link ?
						<a className={styles.iconLink} href={n.link} rel='noopener noreferrer' target='_blank'>
							{icon}
						</a> :
						<div>icon</div>
					}
					<div>
						<span className='bold'>{n.title}</span>
					</div>
					<div className={styles.notifContent}>
						{n.content}
					</div>
					<div className={styles.expando}>
						<FaAngleRight />
					</div>
				</div>
			</td>
		</tr>
	);
}


const Additional = [
	{
		id: 'ecf-award',
		logoURL: '/images/ecf.jpg',
		name: 'English Chess Federation: Online Contribution of the Year',
		description: 'ScorchChess is proud to be announced the winner of English Chess Federation\'s award for \'Online Chess Contribution of the Year\' 2020. As the national federation in our home country, the ECF\'s recognition is quite the honour.',
		button: 'View More',
		active: true,
		href: 'https://www.englishchess.org.uk/ecf-awards-2020-citations/'
	}
] as (TournamentStatus & TournamentAdd)[];

export default function Home(props: RouteComponentProps<any, StaticContext, any>) {

	const [failed, fail] = useReducer(() => true, false);
	const [connected, setConnected] = useState(0);
	const [tournaments, setTournaments] = useState([] as (TournamentStatus & { logoURL: string })[]);

	const [posts, setPosts] = useState([] as Notif[]);

	const updatePosts = useCallback(() => GET({ url: '/announcements' })
		.then(setPosts), [setPosts]);

	const updateTournaments = useCallback(() => GET({ url: '/tournaments' })
		.then(setTournaments)
		.catch(() => fail()), [fail, setTournaments]);

	useEffect(() => {
		updateTournaments();
		updatePosts();
	}, [updateTournaments, updatePosts]);

	useEffect(() => {
		socket.on('connected_count', (count: number) => setConnected(count));
	}, [setConnected]);

	useEffect(() => {
		if (connected) return;
		let x = setTimeout(() => socket.emit('request_online'), 1000);
		return () => clearTimeout(x);
	}, [connected]);

	const [upcoming, live, finished] = useMemo(() => {
		let [u, l, f] = [[], [], []] as TournamentStatus[][];
		for (let t of tournaments) {
			if (!t.round) u.push(t);
			else if (t.active) l.push(t);
			else f.push(t);
		}
		return [u, l, f];
	}, [tournaments]);

	const concat = useMemo(() => Additional.concat(tournaments), [tournaments]);

	const { gradients, palettes } = useRotating({ tournaments: concat });

	if (failed) return <NotFound />;

	return (<>
		<div id='main' >
			<div className={styles.container}>
				<Rotator {...{gradients, palettes, tournaments: concat }} />
				<div className={styles.col1}>
					<div className={['container', styles.title].join(' ')} data-aos='fade-right'>
						<h1><b>Scorch</b>Results<small className={styles.tiny}> from ScorchChess</small></h1>
						<h3>Your universal platform for results and pairings. Attractive. Automated. Effortless.</h3>
						<div className={styles.wrapper}>
							<div className={socket.connected ? styles.connected : styles.disconnected}>
								<GoPrimitiveDot /> {connected ?? '-'} user{connected !== 1 ? 's' : ''} online
							</div>
							<a href='https://beta.scorchapp.co.uk' rel='noopener noreferrer' target='_blank' className={[styles.new].join(' ')}>
								Create New Tournament
								<FaAngleRight />
							</a>
						</div>
					</div>
					<div className='container' data-aos='fade-right'>
						How are you liking our new changes?<br />
						<Link to='/contact#feedback'>
							Write some feedback here
						</Link>
					</div>
					{/* <RedditPoll /> */}
					<div className={['container', styles.announcements].join(' ')} data-aos='fade-right'>
						<table className={[styles.tableAnnounce].join(' ')}>
							<thead>
								<tr>
									<th className={styles.sectionHeader}>
										Announcements
									</th>
								</tr>
							</thead>
							<tbody>
								{posts.map((n, i) => <HomeNotifications key={[n.id, i].join('.')} n={n} i={i} />)}
							</tbody>
						</table>
					</div>
				</div>
				<div className={styles.col2}>
					<div className={['container', styles.tournaments].join(' ')} data-aos='fade-left'>
						<table className={[styles.tableAnnounce].join(' ')}>
							<thead>
								<tr className={styles.sizer}><th /><th /><th /><th /><th /></tr>
								<tr><th colSpan={5} className={[styles.sectionHeader, styles.live].join(' ')}>Live Tournaments</th></tr>
								<tr className={styles.sectionHeader}>
									<th>Name</th>
									<th>Started</th>
									<th>Round</th>
									<th>Players</th>
									<th />
								</tr>
							</thead>
							<tbody>
								{live
									.sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf())
									.map((t, i) => <HomeTournament key={[t.id, i].join('.')} t={t} i={i} />)
								}
							</tbody>
						</table>
					</div>
					<div className={['container', styles.tournaments].join(' ')} data-aos='fade-right'>
						<table className={[styles.tableAnnounce].join(' ')}>
							<thead>
								<tr className={styles.sizer}><th /><th /><th /><th /><th /></tr>
								<tr><th colSpan={5} className={styles.sectionHeader}>Upcoming Tournaments</th></tr>
								<tr className={styles.sectionHeader}>
									<th>Name</th>
									<th>Started</th>
									<th>Round</th>
									<th>Players</th>
									<th />
								</tr>
							</thead>
							<tbody>
								{upcoming
									.sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf())
									.map((t, i) => <HomeTournament key={[t.id, i].join('.')} t={t} i={i} />)
								}
							</tbody>
						</table>
					</div>
					<div className={['container', styles.tournaments].join(' ')} data-aos='fade-left'>
						<table className={[styles.tableAnnounce].join(' ')}>
							<thead>
								<tr className={styles.sizer}><th /><th /><th /><th /><th /></tr>
								<tr><th colSpan={5} className={styles.sectionHeader}>Finished Tournaments</th></tr>
								<tr className={styles.sectionHeader}>
									<th>Name</th>
									<th>Started</th>
									<th>Round</th>
									<th>Players</th>
									<th />
								</tr>
							</thead>
							<tbody>
								{finished
									.sort((a, b) => new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf())
									.map((t, i) => <HomeTournament key={[t.id, i].join('.')} t={t} i={i} />)
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</>);
}

function HomeTournament({ t, i }: { t: TournamentStatus, i: number }) {

	let dateStarted = new Date(t.createdAt).toString();

	return (
		<tr key={[t.id, i].join('.')} className={styles.rowLink}>
			{[
				t.name || t.id,
				dateStarted.slice(0, 11),
				t.round,
				t.players,
				<FaAngleRight key='angle-right' />
			].map((elem, j) => {
				return <td key={['elem', i, j].join('.')}><Link className={styles.tournamentLink} to={'/t/' + t.id}>
					{elem}
				</Link></td>;
			})}
		</tr>
	);
}