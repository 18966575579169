import React, { useState, useMemo, ReactElement, useEffect, useCallback, useReducer, useRef } from 'react';
import cx from 'classnames';
import PlayerData from '../../models/Player';
import { isMobile } from '../../utils/auth';
import { getFlag } from '../../utils/prototype';
import { Link } from 'react-router-dom';

import styles from './css/registered.module.css';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';

interface RegPlayersProps {
	players: PlayerData[]
	height: number
}

export default function RegisteredPlayers(props: RegPlayersProps) {

	const [numberOfRows, setNumberOfRows] = useState(0);
	const calculateRows = useCallback(() => {
		setNumberOfRows(Math.trunc(props.height / 36) - 3);
	}, [props.height, setNumberOfRows]);
	useEffect(() => {
		calculateRows();
	}, [calculateRows]);

	const lastRowRef:any = useRef(null);

	const reduceFull:any = useCallback((full: boolean) => {
		if (!lastRowRef.current) return !full;
		lastRowRef.current.focus();
		return !full;
	}, []);
	const [isFull, setFull] = useReducer(reduceFull, false);

	// TODO: Implemented here
	useEffect(() => {
		if (!lastRowRef.current) return; 
		lastRowRef.current.focus();
		console.log(document.activeElement);
		console.log(lastRowRef.current);
		console.log('focused', isFull);
	}, [setFull]);

	const headers = ['Rank', 'Fed','Team'];
	const [showMore, setMore] = useState(false);

	useEffect(() => {
		window.addEventListener('resize', calculateRows);
		return () => window.removeEventListener('resize', calculateRows);
	}, [calculateRows]);

	// Generates the table rows of players (teams) in standings order
	const renderRows: ReactElement[] = useMemo(() => {
		if (!props.players.length) return [];  // No players (teams)
		let rowsData: [string, string, string][] = [];
		let rowsToRender = (props.players.length < numberOfRows) || isFull ? props.players.length : numberOfRows;
		// Iterating through players
		for (let i = 0; i < rowsToRender; i++) {
			let p = props.players[i];
			// Skip player if tournament format is pool-like or player is a bye
			if (p.id === 'bye') continue;
			// Push to a players array
			rowsData.push([p.nationality, p.firstName, p.id ? '/team/'+ p.id : '']);
		}

		const x = rowsData.map(([nationality, name, link], i, arr) => {
			return (
				<tr key={i} id={i === arr.length - 1 && !isFull ? styles.rowLast : undefined} ref={i === arr.length - 1 && !isFull ? lastRowRef : undefined} className={cx(styles.tr, { even: i % 2 === 0, odd: i % 2 })}>
					<td 
						className={ cx(styles.cell, 
							{ [styles.moreHidden]: !showMore && !isMobile(),
								hidden: false})}
					> 
						{i + 1} 
					</td>
					
					<td className={ cx(styles.cell, styles.flag, {
						[styles.moreHidden]: !showMore && !isMobile(),
						hidden: false
					})}>
						{getFlag(nationality) || nationality}
					</td>
					<td className={ cx(styles.cell, {
						[styles.moreHidden]: !showMore && !isMobile(),
						hidden: false
					})}>
						<Link to={link}>
							{name}
						</Link>
					</td>
				</tr>
			);
		});
		return x;
	}, [props.players, numberOfRows, isFull]);

	return (
		<>
			<table className={cx('download-table full-width', styles.table, 'standings')}>
				{/* Header */}
				<thead>
					<tr>
						{headers.map((key, i) => {
							return <th
								scope='column'
								key={['column', i].join('.')}
								className={cx(styles.th, styles.stickyheader)}
								id={key}
							>
								<div className={[styles.cell, styles.headerCell].join(' ')}>
									{key}
								</div>
							</th>;
						})}
						{showMore && !isMobile() ?
							<th className={cx(styles.cell, styles.spacer)} /> :
							null
						}
					</tr>
				</thead>
				{/* Main rows body */}
				<tbody>
					{
						props.players.length ?
							renderRows :
							<tr>
								<td colSpan={42}>
								No players entered yet
								</td>
							</tr>
					}
				</tbody>
			</table>
			
			{props.players.length > numberOfRows ?
				<button onMouseDown={ () => {
					console.log(document.activeElement);
					setFull();
				} } className={styles.showAllButton}> 
					{isFull ? <FaAngleUp /> : <FaAngleDown />}
				</button> :
				null
			}
		</>
	);
}