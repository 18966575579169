import { useCallback, useContext } from 'react';
import { AlertContext } from '../../Contexts';
import { ProfileData, Team } from '../../interfaces';
import { POST } from '../../utils/requests';

interface BioProps {
	profile?: Team & ProfileData | null
	updateProfile: () => Promise<void>
}

export default function useBio({
	profile,
	updateProfile
}: BioProps) {

	const setAlert = useContext(AlertContext);

	const setField = useCallback((k: string, v: any) => POST({
		url: '/profile/updateAccount',
		data: {
			account: k,
			data: v
		}
	}).then(updateProfile), [updateProfile]);

	const updateBio = useCallback(() => {
		setAlert({
			title: 'Add a description',
			message: 'Keep it short and sweet. Maximum length 280 characters.',
			type: 'prompt',
			prompt: {
				type: 'textarea',
				defaultValue: profile?.bio || '',
				allowNull: true,
				additionalInputProps: {
					maxLength: 280
				}
			},
			resolve: (v: string) => setField('bio', v)
		});
	}, [setAlert, setField, profile]);

	return { updateBio };

}