import { Dispatch, SetStateAction } from 'react';
import Axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import config, { useProd, prodServer, server as configServer } from '../config.json';
import Cookies from './cookies';

import io from 'socket.io-client';

let errorCallback: (header: string, message: string) => void = () => {};

export const dev = process.env.NODE_ENV === 'development'; //detects a react dev build
export const isDev = dev && !useProd;
console.log('isDev', dev);
export const server = !isDev ? prodServer : configServer;
export const domain = !isDev ? config.prodDomain : undefined;
export const lichess = !isDev ? config.lichess : config.lichessBeta;
export const lichessCallback = !isDev ? config.lichessCallback : config.lichessCallbackBeta;

export const axios = Axios.create({
	baseURL: server
});
(window as any).axios = axios;

export const resultsServer = '';
export const saltRounds = 10;
export let cookies = new Cookies();
(window as any).devCookies = cookies;

// alter all requests to use cookies only from AsyncStorageCookieStore
axios.interceptors.request.use(async (c) => {

	// the device will have it's own cookie store, and attempt to
	// send cookies itself.
	// this has been documented to be unreliable, so we wipe these cookies
	// and implement the cookie store ourselves.
	//await AsyncStorage.clear()
	return Object.assign(c, {
		headers: Object.assign(c.headers, {
			Authorization: JSON.stringify(await cookies.getAll())
		})
	});

});

export function GET(options: AxiosRequestConfig): Promise<any> {
	options = Object.assign(options, {
		method: 'GET'
	});
	return axios(options)
		.then((res: AxiosResponse<any>) => res.data)
		.catch((e: AxiosError) => {
			console.error(e);
			//if (e.response) errorCallback(`${e.response.status} ${e.response.statusText}: /${e.response.config.url?.split('/').pop()}`, e.response.data);
			throw e;
		});
}

export function POST(options: AxiosRequestConfig & {
	noCatch?: boolean
}): Promise<any> {
	options = Object.assign(options, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		}
	});
	return axios(options)
		.then((res: AxiosResponse<any>) => res.data)
		.catch((e) => {
			if (options.noCatch) throw e;
			if (e.response) {
				if (e.response.status === 400) {
					errorCallback('Error:', e.response.data);
				} else {
					errorCallback(`${e.response.status} ${e.response.statusText}: /${e.response.config.url.split('/').pop()}`, e.response.data);
				}
			}
			throw e;
		});
}

export function FORM(e: React.FormEvent<HTMLFormElement>): Promise<any> {
	if (e.preventDefault) e.preventDefault();
	if (e.persist) e.persist();
	let form = e.target as HTMLFormElement;
	while (form.constructor.name !== 'HTMLFormElement' && form.parentElement) {
		form = form.parentElement as HTMLFormElement;
	}
	if (form.constructor.name !== 'HTMLFormElement') throw new Error('Couldn\'t find valid form');
	let inputs = Array.from(form) as HTMLInputElement[];
	let data = inputs.reduce((acc, curr) => {
		if (!curr.name) return acc;
		if (curr.name === 'submit') return acc;
		if (curr.type === 'number') acc[curr.name] = Number(curr.value);
		else acc[curr.name] = curr.value;
		return acc;
	}, {} as {[key: string]: any});
	let options = {
		data,
		url: form.action
	} as AxiosRequestConfig;
	return POST(options);
}

export function setErrorCallback(c: (header: string, message: string) => void) {
	errorCallback = c;
}

export let socket = io(server, {
	reconnection: true,
	reconnectionDelay: 1000,
	reconnectionDelayMax : 100000,
	reconnectionAttempts: 5
});

export function restart(sessionID: string) {
	socket.close();
	socket = io(server, {
		reconnection: true,
		reconnectionDelay: 1000,
		reconnectionDelayMax : 100000,
		reconnectionAttempts: 5,
		query: {
			sessionID
		}
	});
}

export function connect(setConnected: Dispatch<SetStateAction<boolean>>): void {

	setConnected(socket.connected);

	socket.on('connect', () => {
		setConnected(true);
	});
	socket.on('connect_error', (e: Error) => {
		console.error(e);
		setConnected(false);
	});
	socket.on('connect_timeout', (e: Error) => {
		console.error(e);
		setConnected(false);
	});
}

export function emit(event: string, ...args: any[]): void {

	socket.emit(event, ...args);
}

/**
 * 

    startRequests: async function() {
        try {
            while (this._requests.length > 0) {
                if (!this._running) this._running = true;
                let request = this.dequeue();
                let method = request.method;
                let callback = request.callback;
                let error = null;
                let response = await method(...request.parameters, request.options)
                    .catch((e) => error = e);
                callback(response, error, ...request.callbackParamerters);
            }
            this._running = false;
        } catch(error) {
            throw error;
        }
    },
 */