import React, { useState, useEffect, useMemo, useContext, useCallback, useReducer, ReactElement, useRef, RefObject, CSSProperties } from 'react';
import Vibrant from 'node-vibrant';
import cx from 'classnames';

import { Palette } from 'node-vibrant/lib/color';
import { TournamentStatus } from '../interfaces';

import styles from './css/rotating.module.css';
import { FaAngleRight } from 'react-icons/fa';
import { ThemeContext } from '../Contexts';
import { randBetween } from '../utils/prototype';
import { Link } from '../utils/link';
import Colour from '../utils/colours';

export interface TournamentAdd {
	id: string
	href?: string
	logoURL?: string
	description?: string
	button?: string
}

export default function useRotating({ tournaments }: { tournaments: (TournamentStatus & TournamentAdd)[] }) {

	const [palettes, setPalettes] = useState({} as {[key: string]: Palette});

	const renderPalettes = useCallback(() => {
		if (!tournaments.length) return;
		let promises = [] as Promise<Palette | void>[];
		let ids = [] as string[];
		for (let t of tournaments) {
			if (!t.logoURL) continue;
			if (!t.name) continue;
			promises.push(Vibrant.from(t.logoURL)
				.getPalette()
				.catch(() => {}));
			ids.push(t.id);
		}
		Promise.all(promises)
			.then((p) => {
				return p;
			})
			.then((arr: (Palette | void)[]) => {
				let obj = {} as {[key: string]: Palette};
				for (let i = 0; i < arr.length; i++) {
					let p = arr[i];
					if (!p) continue;
					obj[ids[i]] = p;
				}
				setPalettes(obj);
			});
	}, [tournaments, setPalettes]);

	useEffect(() => {
		renderPalettes();
	}, [renderPalettes]);

	const gradients = useMemo(() => {
		let g = {} as {[key: string]: string};
		for (let [k, v] of Object.entries(palettes)) {
			let colours = [v.DarkVibrant?.hex, v.DarkMuted?.hex, v.Vibrant?.hex, v.Muted?.hex, ];
			g[k] = `linear-gradient(160deg, ${colours.filter(v => v).join(', ')})`;
		}
		return g;
	}, [palettes]);

	return { palettes, gradients };

}

export function Background({ id, gradients, withContainer, style, img, faded }: {
	id: string
	gradients: {[key: string]: string}
	withContainer?: boolean
	faded?: boolean
	style?: CSSProperties
	img?: string
}) {

	const theme = useContext(ThemeContext);

	if (img) return <div
		className={cx(styles.parent, {[styles.fixed]: withContainer, [styles.lighter]: theme === 'light' })}
		style={{ ...style }}
	>
		<div className={cx(styles.logoBackground, {[styles.lighter]: theme === 'light'} )}>
			<img src={img} alt='\u200b' />
		</div>
		<div className={cx(styles.background, {[styles.faded]: styles.faded, [styles.fixed]: withContainer, [styles.lighter]: theme === 'light' })}
			style={{ backgroundImage: gradients[id] }}
		/>
	</div>;

	return <div
		className={cx(styles.parent, styles.background, {[styles.faded]: styles.faded, [styles.fixed]: withContainer, [styles.lighter]: theme === 'light' })}
		style={{ backgroundImage: gradients[id], ...style }}
	/>;
}

export function Rotator({ tournaments, gradients, palettes }: {
	tournaments: (TournamentStatus & TournamentAdd)[]
	gradients: {[key: string]: string}
	palettes: {[key: string]: Palette}
}) {
	const [sharedIndex, setIndex] = useReducer((state: number, action: number | undefined) => action ?? state + 1, 0);

	if (!tournaments.length) return null;

	return <>
		<TextGallery key='background' className={styles.backContainer} index={sharedIndex} showDots={false}>
			{Object.keys(gradients).map((id, i) => {
				let t = tournaments.find(v => v.id === id);
				if (!t) return null;
				if (!t.active) return null;				
				return <Background key={['background', i].join('.')} id={id} gradients={gradients} />;
			})}
		</TextGallery>
		<TextGallery key='foreground' setIndex={setIndex} autoAdvance={8000} >
			{Object.keys(gradients).map((id, i) => {
				let t = tournaments.find(v => v.id === id);
				if (!t) return null;
				return <React.Fragment key={cx('foreground', i)}>
					<div className={styles.curr}>
						<div className={styles.description}>
							{t.description ? `"${t.description}"` : `${t.round} round${t.round !== 1 ? 's' : ''}. ${t.players} player${t.players !== 1 ? 's' : ''}.`}
						</div>
						<div className={styles.title}>
							{t.name}
						</div>
						<div className={styles.palette}>
							<div className={styles.box} style={{backgroundColor: palettes[id].Vibrant?.hex }} />
							{[palettes[id].DarkVibrant?.hex, palettes[id].DarkMuted?.hex, palettes[id].Vibrant?.hex, palettes[id].Muted?.hex, palettes[id].LightVibrant?.hex, palettes[id].LightMuted?.hex].map((backgroundColor, i) => {
								if (!backgroundColor) return null;
								return <div key={['box', i].join('.')} className={styles.box} style={{backgroundColor }} />;
							})}
						</div>
						<Link to={t.href || ['', 't', t.id].join('/')} className={styles.frame}>
							<img src={t.logoURL} alt={t.name} className={styles.logo} />
						</Link>
						
					</div>
					<Link to={t.href || ['', 't', t.id].join('/')} style={{
						backgroundColor: palettes[id].Vibrant?.hex,
						color: new Colour(palettes[id].Vibrant?.rgb).getText()
					}} className={cx('button', styles.new)}>
						{t.button || 'View Tournament'}<FaAngleRight />
					</Link>
				</React.Fragment>;
			})}
		</TextGallery>
	</>;
}

interface TextGalleryProps {
    children?: (JSX.Element | null)[]
	autoAdvance?: number
	showDots?: boolean
	className?: string
	index?: number
	setIndex?: (i: number | undefined) => void
}

export function TextGallery(props: TextGalleryProps): ReactElement {

	const propsSet = props.setIndex;
	const [index, setIndex] = useReducer((state: number, action: number | undefined) => action ?? state + 1, 0);
	const [hasClicked, setHasClicked] = useState(false);
	const [offset, setOffset] = useState(0);

	useEffect(() => {
		if (!hasClicked) return;
		if (props.index !== undefined) return;
		let f = () => setHasClicked(false);
		let x = setTimeout(f, 5 * (props.autoAdvance || 10000));
		return () => clearTimeout(x);
	}, [props.autoAdvance, props.index, hasClicked, setHasClicked]);
	
	let length = props.children ? props.children.length : 0;
	
	const dots = useMemo(() => {
		let d = [] as ReactElement[];
		for (let i = 0; i < length; i++) {
			d.push(<span
				key={['textGallery', 'dot', i].join('.')}
				className={cx(styles.dot, { [styles.dotSelected]: i === (index % length) })}
				onClick={() => {
					setIndex(i);
					if (propsSet) propsSet(i);
					setHasClicked(true);
				}}
			/>);
		}
		return d;
	}, [setIndex, propsSet, length, index, setHasClicked]);

	const updateIndex = useCallback(() => {
		if (!props.autoAdvance) return;
		let f = () => {
			setIndex(undefined);
			if (propsSet) propsSet(undefined);
		};
		let x: NodeJS.Timeout;
		if (!hasClicked) x = setInterval(f, props.autoAdvance);
		return () => clearInterval(x);
	}, [props.autoAdvance, hasClicked, setIndex, propsSet]);

	useEffect(updateIndex, [updateIndex]);
	useEffect(() => {
		if (!props.children) return;
		let start = Math.floor(randBetween(0, props.children.length));
		setIndex(start);
		if (propsSet) propsSet(start);
	}, [props.children, setIndex, propsSet]);
	
	const rotator = useRef() as RefObject<HTMLDivElement>;
	const updateOffset = useCallback(() => {
		if (!rotator.current) return;
		setOffset(rotator.current.getBoundingClientRect().left);
	}, [rotator, setOffset]);
	useEffect(() => {
		updateOffset();
		window.addEventListener('resize', updateOffset);
		return () => window.removeEventListener('resize', updateOffset);
	}, [updateOffset]);
	const left = useMemo(() => `calc((-100vw * ${(props.index ?? index) % length}) - ${offset}px)`, [props.index, index, length, offset]);

	return (
		<div className={cx(styles.rotator, props.className)} ref={rotator}>
			<div className={styles.stage} style={{ left }}>
				{props.children?.map((c, i) => {
					return <div className={styles.child} key={cx('textGallery', 'child', i)}>
						{c}
					</div>;
				})}
			</div>
			{props.showDots !== false ? <div className={styles.selection}>
				{dots}
			</div> : null}
		</div>
	);
}