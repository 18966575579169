import React from 'react'
import PropTypes from 'prop-types'
import { FaDownload } from 'react-icons/fa'

class Download extends React.Component {

  _handleDownload = () => {
    const { onDownload } = this.props

    if(typeof onDownload !== 'function') return

    onDownload()
  }

  render() {
    return (
      <div onClick={this._handleDownload} style={this.props.iconStyles}>
        <FaDownload />
      </div>
    )
  }
}

Download.propTypes = {
  onDownload: PropTypes.func.isRequired,
  iconStyles: PropTypes.object.isRequired
}

export default Download
