import { useCallback } from 'react';
import { FacebookCallback } from '../../interfaces';
import { POST } from '../../utils/requests';

export function useFacebook(props: {
	updateProfile: () => void
}) {
	
	const handleFacebook = useCallback((res: FacebookCallback) => {
		if (!res || !res.id) return;
		POST({
			url: '/profile/facebook/link',
			data: {
				id: res.userID,
				name: res.name,
				email: res.id,
				accessToken: res.accessToken
			}
		})
			.then(props.updateProfile);
	}, [props.updateProfile]);

	return handleFacebook;

}