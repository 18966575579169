import React, { ReactElement, useCallback, useState, useContext} from 'react';
import { Redirect } from 'react-router-dom';
import { TournamentSettings } from '../../resources/settings';
import { FaAngleDoubleRight, FaPlusCircle, FaTachometerAlt } from 'react-icons/fa';
import { AiOutlineTable } from 'react-icons/ai';
import { IoIosStats } from 'react-icons/io';
import { LoginContext } from '../../Contexts';
import { watch } from '../../assets/icons';
import Switcher from './Switcher';
import styles from '../../tournament/Pairings/css/pairings.module.css';

export default function Mode(props: {
	id: string,
	section: 'pairings' | 'standings' | 'stats' | 'tv'
	settings: TournamentSettings
	round: number
	loaded: {[key: string]: boolean}
}): ReactElement {

	const { isLoggedIn } = useContext(LoginContext);
	const [shouldRedirect, redirectTo] = useState('');

	const handleJoin = useCallback(() => {
		if (!isLoggedIn) return redirectTo('/login');
		return null;
	}, [isLoggedIn, redirectTo]);

	if (shouldRedirect) return <Redirect to={shouldRedirect} />;

	return <>
		<Switcher {...props}	loaded={props.loaded.all} sections={{
			dashboard: {
				name: 'Dashboard',
				link: `/t/${props.id}/dashboard`,
				icon: <FaTachometerAlt />
			},
			pairings: {
				name: 'Pairings',
				link: `/t/${props.id}/pairings`,
				icon: <FaAngleDoubleRight />
			},
			standings: {
				name: 'Standings',
				link: `/t/${props.id}/standings`,
				icon: <AiOutlineTable />,
				index: props.settings.pairingSystem === 'knockout' ? 0 : undefined
			},
			stats: {
				name: 'Stats',
				link: `/t/${props.id}/stats`,
				icon: <IoIosStats />
			}
		}} />
		{props.settings.allowRegistration && props.settings.allowRegistration !== 'closed' ? <div className={['header-button', styles.join].join(' ')} onClick={handleJoin}>
			<div><FaPlusCircle />Join this tournament</div>
		</div> : null}
	</>;
}
