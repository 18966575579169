import { TournamentSettings, Result } from '../../interfaces';

export function useScore({
	settings
}: {
	settings: TournamentSettings
}) {

	function getScores(r: Result): [number, number] | [null, null] {
		if (settings.displayPoints === 'match') {
			if (r.matchW != null || r.matchB != null) {
				return [r.matchW, r.matchB] as [number, number];				
			} else
			if (r.fetched?.scores) {
				return r.fetched.scores;
			} else {
				return [null, null];
			}
		} else
		if (r.resultWhite != null || r.resultBlack != null) {
			return [r.resultWhite, r.resultBlack] as [number, number];
		} else 
		if (r.fetched?.scores) {
			return r.fetched.scores;
		} else {
			return [null, null];
		}
	}

	return { getScores };
}