import React from 'react';
import { LinkProps, Link as L } from 'react-router-dom';
import * as regexes from './regexes';

export function Link(props: {
	to: string
} & LinkProps & React.RefAttributes<HTMLAnchorElement>) {

	if (!regexes.link.test(props.to) && !props.to.startsWith('#')) {

		return <L {...props}>
			{props.children}
		</L>;

	} else {

		return <a href={props.to} rel='noopener noreferrer' target='_blank' {...props}>
			{props.children}
		</a>;
		
	}
}