import React, { useState, useCallback, useEffect, useReducer, useRef, RefObject, useMemo } from 'react';
import styles from './css/teams.module.css';
import { GET } from '../utils/requests';
import { Team as TeamInterface, ProfileData } from '../interfaces';
import Info from '../user/Info';
import { FaTh, FaColumns, FaSearch } from 'react-icons/fa';
import PlayerLine from '../components/PlayerLine';
import Sticky from '../components/Sticky';
import AutoComplete from '../../src/components/AutoComplete';

export default function Teams() {

	const [mode, setMode] = useState('grid' as 'compact' | 'grid');  // Tracks teams list display mode
	const [teams, setTeams] = useState([] as (TeamInterface & ProfileData)[]);
	const [searched, setSearched] = useState([] as TeamInterface[]);

	// Teams search bar
	const searchBar = useMemo(() => {
		return (
			<div className={styles.searchBarDiv}>
				<div className={styles.searchBar}>
					<FaSearch className={styles.searchIcon}/>
					<AutoComplete
						onChange = {setSearched}
						onSubmit={Promise.resolve}
						action=''
						options={teams}
						convert={t => t.name}
						styles={styles}
						placeholder='Search'
					/>
				</div>
			</div>
		);
	}, [teams, setSearched]);

	const [preview, setPreview] = useReducer((state: null | TeamInterface & ProfileData, action: null | TeamInterface & ProfileData) => {
		if (state?.id === action?.id) return null;
		return action;
	}, null as null | TeamInterface & ProfileData);
	const updateTeams = useCallback(() => GET({ url: '/teams' })
		.then(setTeams), [setTeams]);
	useEffect(() => {
		updateTeams();
	}, [updateTeams]);

	// Teams display render
	const teamsDisplay = useMemo(() => {
		// Alphabetical team sort
		teams.sort((a, b) => a.name?.toLowerCase().charCodeAt(0) - b.name?.toLowerCase().charCodeAt(0));
		// Map teams list to JSX element
		let teamsElement: JSX.Element[] = mode === 'compact' ? 
			teams.filter( team => {  // Compact display
				return searched.length === 0 || searched.find(searchedTeam => searchedTeam.id === team.id);
			}).map((u, i) => {
				return <PlayerLine key={['team', i].join('.')} profile={u} i={i} type='team' onClick={() => setPreview(u)} />;
			}) : 
			teams.filter( team => {  // Grid display
				return searched.length === 0 || searched.find(searchedTeam => searchedTeam.id === team.id);
			}).map((u, i) => {
				return <Info key={['team', i].join('.')} id={u.id} profile={u} type='team' i={i} /> ;
			});
		return teamsElement;
	}, [teams, mode, searched, setPreview]);

	const mounted = useRef() as RefObject<HTMLDivElement>;

	return (
		<div id='main'>
			<section className={styles.container}>
				{/* Header div */}
				<div className={styles.title}>
					{/* Title and number of teams */}
					<div><b>Teams</b></div>
					<div className={'light'}>{teams.length}</div>
					{/* Teams search bar */}
					{ searchBar }
					{/* Display mode selector */}
					<div className={styles.menu}>
						<label>Display</label>
						<FaColumns onClick={() => setMode('compact')} className={mode === 'compact' ? styles.inactive : styles.active} />
						<FaTh onClick={() => setMode('grid')} className={mode === 'grid' ? styles.inactive : styles.active} />							
					</div>
				</div>
				{/* Compact teams display mode */}
				{mode === 'compact' ?
					<div className={styles.compactContainer}>
						{/* Compact List (PlyaerLine) of teams in alphabetical order */}
						<div className={styles.compact}>
							{teamsDisplay}
						</div>
						{/* Sticky with large team card when team is clicked */}
						<Sticky
							absoluteStyles={{ top: '-40px' }}
							fixedStyles={{ top: '8vh' }}
							upperLimit={window.innerHeight * 10 / 100}
							lowerLimit={275}
						>
							<div className={[styles.preview].join(' ')} >
								{preview ?
									<Info id={preview.id} profile={preview} type='team' small={false} showLinks /> :
									null
								}
							</div>
						</Sticky>
					</div> :
					<div className={styles.userTable} ref={mounted}> {/* Grid mode (mini team cards) */}
						{teamsDisplay}
					</div>
				}
			</section>
		</div>
	);
}