import React, { ReactElement, useMemo, useCallback, useReducer, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { TournamentStatus, Player, TournamentSettings } from '../../interfaces';
import PlayerData from '../../models/Player';
import usePlayers from './Players';

import styles from './css/crosstable.module.css';
import { columns, ColumnData } from '../../resources/columns';
import { TiebreakContext } from '../../Contexts';
import { convertPoolDisplay } from '../../utils/prototype';
import { isMobile } from '../../utils/auth';
import Sticky from '../../components/Sticky';
import { FaAngleRight } from 'react-icons/fa';

export interface StandingProps {
	status: TournamentStatus
	players: Player[]
	settings: TournamentSettings
	round: number
	state: string[]
	pool: number

	// Sometimes we explicityl specify the round to begin and end at, when there's a multi-stage tournament
	start?: number
	finish?: number

	// Search bar props 
	searched: Player[]
}

export default function Standings(props: StandingProps) {
	
	const players: PlayerData[] = usePlayers(props);
	const [showMore, setMore] = useState(false);

	const totalRounds = useMemo((): number => {
		if (props.finish) return props.finish;
		let totalRounds: number;
		if (props.status.round > props.settings.totalRounds) totalRounds = props.status.round;
		else if (props.settings.totalRounds === 'Infinity' || props.settings.totalRounds === Infinity) totalRounds = props.status.round;
		else totalRounds = props.settings.totalRounds;
		return totalRounds;
	}, [props.finish, props.status.round, props.settings.totalRounds]);

	const roundHeaders = useMemo(() => {
		let h = [] as string[];
		for (let i = props.start ?? 1; i <= totalRounds; i++) {
			h.push('round.' + i.toString());
		}
		return h;
	}, [totalRounds, props.start]);

	const headers: string[] = useMemo(() => [...Object.keys(columns.pre), ...roundHeaders, ...Object.keys(columns.post)], [roundHeaders])
		.filter((key, i) => {
			let meta = columns.pre[key] || columns.post[key];
			if (!meta) return true;
			if (meta.show && !meta.show(null as any as PlayerData, i, props.settings)) return false;
			return true;
		});

	const setSortHeader = useCallback((state: [string, number], action: string): [string, number] => {
		if (!headers.includes(action)) return ['', 0];
		if (state[0] !== action) return [action, 0];
		else return [action, state[1] + 1];
	}, [headers]);
	const [sort, sortBy] = useReducer(setSortHeader, ['score', 0]);

	const tiebreaks = useContext(TiebreakContext);
	
	// Generates the table rows of players (teams) in standings order
	const renderRows: JSX.Element[] = useMemo(() => {
		if (!players.length) return [];  // No players (teams)
		let rowsData = [] as [number, ColumnData, PlayerData][][];
		// Iterating through players
		for (let i = 0; i < players.length; i++) {
			let p = players[i];
			// Skip player if tournament format is pool-like or player is a bye
			if ((p.pool || props.pool) && p.calcPool(props.start, totalRounds + 1) !== props.pool) continue;
			if (p.id === 'bye') continue;

			// Filtering only if search query entered
			if (props.searched.length !== 0) {
				let matchingSearch = props.searched.find(obj => obj.id === p.id);
				// Skip player (team) if ID not matched by searched
				if (!matchingSearch) continue;
			}

			// Populating 'left-of-round' column information
			let pres = [] as [number, ColumnData, PlayerData][];
			for (let [k, meta] of Object.entries(columns.pre)) {
				if (meta.show && !meta.show(p, i, props.settings)) continue;
				pres.push([p[k as keyof Player] as number, meta, p]);
			}

			// Populating round and score information
			let _rounds = p.rounds.slice(props.start ?? 1, totalRounds + 1);
			for (let j = _rounds.length + 1; j <= totalRounds; j++) _rounds.push('');
			let rounds = _rounds.map(entry => [entry as any as number, columns.roundColumn, p]) as [number, ColumnData, PlayerData][];

			// Populating 'right-of-round' column information
			let post = [] as [number, ColumnData, PlayerData][];
			for (let [k, meta] of Object.entries(columns.post)) {
				if (meta.show && !meta.show(p, i, props.settings)) continue;
				post.push([p[k as keyof Player] as number, meta, p]);
			}

			// Concatenate and push the completed row
			let rowData = [...pres, ...rounds, ...post];
			rowsData.push(rowData);
		}

		// Row sorting logic
		let sortFunc = (a: number, b: number): number => b - a;
		let sortMeta = sort[0].startsWith('round') ? columns.roundColumn : (columns.pre[sort[0]] || columns.post[sort[0]]);
		if (sortMeta && sortMeta.sort !== false) {
			if (sortMeta.sort) sortFunc = sortMeta.sort;
			let sortCol = headers.indexOf(sort[0]);
			if (sortCol !== -1)
				rowsData = rowsData.sort((a, b): number => {
					let x: number;
					if (sort[1] % 2) x = sortFunc(b[sortCol][0], a[sortCol][0]);
					else x = sortFunc(a[sortCol][0], b[sortCol][0]);
					if (x) return x;
					if (props.round === totalRounds) return props.state.indexOf(a[sortCol][2].id) -  props.state.indexOf(b[sortCol][2].id);
					let allTb = tiebreaks.slice(0);
					let useTb = allTb.shift();

					while (useTb) {
						if (!useTb[1]) break;
						let res = useTb[1].func(a[sortCol][2], b[sortCol][2]);
						if (res) return res;
						useTb = allTb.shift();
					}
					return 0;
				});
		}

		// Formatting rows for rendering
		let rows = rowsData.map((rowData, i) => {
			let x = (
				// Defining table row with key and identifying background color
				<tr key={'row.' + i} data-aos='fade-up' data-aos-once className={cx(styles.tr, { even: i % 2 === 0, odd: i % 2 })}>
					{/* Parsing Row Data Information */}
					{rowData.map(([value, meta, p]: [number, ColumnData, PlayerData], j: number) => {
						let display: string | JSX.Element = value ? value.toString() : '';
						// Convert data if not converted
						if (meta.convert) display = meta.convert(value, p, props.settings, headers[j]);
						else if (meta.postConvert) display = meta.postConvert(display, i);
						// Zero width space if undefined display type or empty display
						if (typeof display === 'undefined' || display === '') display = '\u200b';

						let className = typeof meta.className === 'function' ? meta.className(typeof display === 'string' ? display : '', j, props.settings) : meta.className;
						let name = typeof meta.name === 'function' ? meta.name((headers[j] || '').toString(), j) : meta.name;
						let link = meta.id ? meta.id(p, parseInt(name), props.settings) : '';
						let title = meta.title ? meta.title(p, parseInt(name), players) : '';
						return <>
							<th className={[styles.cell, styles.headerCell].join(' ')} key={['cellHeader', j].join('.')}>
								<div>
									{meta.mobile ? meta.mobile : name}
								</div>
							</th>
							<td 
								key={['cellBody', j].join('.')} 
								className={ cx(styles.cell, 
									{ [styles.moreHidden]: !showMore && meta.more && !isMobile(),
										hidden: false})
								}
							>
								{display ?
									link ?
										<Link
											to={link}
											className={[styles.cell, className, styles[className]].join(' ')}
											title={title}
										>
											{display}
										</Link> :
										<div
											key={[value, j].join('.')}
											className={[styles.cell, className, styles[className]].join(' ')}
											title={title}
										>
											{display}
										</div>
									: null
								}							
								
							</td>
						</>;
					})}
					{/* Larger spacing for desktop experience */}
					{showMore && !isMobile() ?
						<td className={cx(styles.cell, styles.spacer)} /> :
						null
					}
				</tr>
			);
			return x;
		});
		return rows;
	}, [players, sort, headers, props.round, props.state, totalRounds, props.settings, tiebreaks, props.pool, showMore, props.searched, props.start, totalRounds]);

	if (props.pool && !renderRows.length) return null;

	return <>
		<section className={styles.container}>
			{props.pool ? <div className={styles.pool}>Pool {convertPoolDisplay(props.pool, props.settings.poolDisplay)}</div> : null}
			
			{/* JSX for Standings Table */}
			<table className={cx('download-table full-width', styles.table, 'standings')}>
				{/* Header with column labels Fed, Name.. etc */}
				<thead>
					<tr data-aos='fade-up' data-aos-once>
						{headers.map((key, i) => {
							let meta = key.startsWith('round') ? columns.roundColumn : (columns.pre[key] || columns.post[key]);
							return <th
								scope='column'
								key={['column', i].join('.')}
								className={cx(styles.th, {
									[styles.round]: key.startsWith('round'),
									[styles.moreHidden]: !showMore && meta.more && !isMobile()
								})}
								id={key}
								onClick={() => sortBy(key)}
							>
								<div className={[styles.cell, styles.headerCell].join(' ')}>
									{typeof meta.name === 'function' ? meta.name(key, i) : meta.name}
								</div>
							</th>;
						})}
						{showMore && !isMobile() ?
							<th className={cx(styles.cell, styles.spacer)} /> :
							null
						}
					</tr>
				</thead>
				{/* Main rows body */}
				<tbody>
					{
						renderRows.length ?
							renderRows :
							<tr>
								<td colSpan={42}>
								No players entered yet
								</td>
							</tr>
					}
				</tbody>
			</table>
			<Sticky>
				<div className={styles.col2}>
					{isMobile() ? null : !showMore ?
						<div className={styles.button}>
							<FaAngleRight />
						</div> :
						null
					}
				</div>
			</Sticky>
		</section>
	</>;
}