import { Player } from '.';
import { ChessTeam } from 'chess-web-api';
import { Game } from '../resources/result';

export interface MatchConstructor {
	id: string
	w: Player
	b: Player
	tournamentId: string
	board?: number
	round: number
	link?: string
	linkSource?: 'chessCom' | 'lichess'
	linkType?: 'team' | 'individual'
	teamID?: number
	scores: [number, number] | [undefined, undefined]
	aborted?: boolean
	bodyStatus?: 'scheduled' | 'finished' | 'in_progress'
	metaStatus?: 'Completed' | 'In Progress' | ''
	registrationTime?: number
	startTime?: number
	endTime?: number
	players?: number
	scored?: boolean,
	pool?: number
	teams: ChessTeam[]
	games?: Game[]
}

export type MatchStatus = 'finished' | 'live' | 'registered' | 'scheduled' | 'pending' | 'aborted';

export default class Match {

	public id!: string
	public w!: Player
	public b!: Player
	public tournamentId!: string

	public board!: number
	public teamID?: number
	public link?: string
	public linkSource?: 'chessCom' | 'lichess'
	public linkType?: 'team' | 'individual'
	public scores!: [number, number] | [undefined, undefined]

	public aborted?: boolean
	public bodyStatus?: 'scheduled' | 'finished' | 'in_progress'
	public metaStatus?: 'Completed' | 'In Progress' | ''
	public registrationTime?: number
	public startTime?: number
	public endTime?: number
	public players?: number
	public scored?: boolean
	public teams!: ChessTeam[]
	public pool?: number

	public isFeatured?: boolean;

	games?: Game[]

	public debugStatus!: string
	constructor(m: MatchConstructor, public round: number) {
		for (let prop of Object.keys(m)) {
			Object.defineProperty(this, prop, {
				value: m[prop as keyof MatchConstructor]
			});
		}
		this.debugStatus = this.status;
	}

	public get status(): MatchStatus {
		if (this.aborted) return 'aborted';
		for (let v of ['Confirmed', 'Finished', 'Live', 'Registered', 'Scheduled']) {
			if (!(('is' + v) in this)) continue;
			if (this[('is' + v) as keyof Match] as boolean) return v.toLowerCase() as  'finished' | 'live' | 'registered' | 'scheduled';
		}
		return 'pending';
	}

	get isConfirmed(): boolean {
		if (this.scored) return true;
		return false;
	}

	get isFinished(): boolean {
		if (this.isConfirmed) return true;
		if (this.bodyStatus === 'finished') return true;
		if (Date.now() > (this.endTime || Infinity)) return true;
		return false;
	}

	get isLive(): boolean {
		if (this.metaStatus === 'In Progress') return true;
		if (this.bodyStatus === 'in_progress') return true;
		if (!this.isFinished) {
			if (this.startTime && Date.now() > this.startTime) return true;
		}
		return false;
	}

	get isRegistered(): boolean {
		if (Date.now() > (this.registrationTime || Infinity)) return true;
		if (this.players) return true;
		if (this.isLive) return true;
		if (this.isFinished) return true;
		return false;
	}

	get isScheduled(): boolean {
		if (Date.now() > (this.startTime || Infinity)) return true;
		if (this.startTime) return true;
		if (this.bodyStatus === 'scheduled') return true;
		if (this.isRegistered) return true;
		if (this.isFinished) return true;
		return false;
	}

}