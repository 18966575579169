import React, { ReactElement, useContext, useCallback, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { EnhancedPlayer, EnhancedHistory, SmallTeam, EnhancedMatch } from '../interfaces';
import { Player, Match } from '../models';
import bcrypt from 'bcryptjs';

import { columns } from '../resources/columns';
import { FaEllipsisH, FaEye, FaFlag, FaChessPawn, FaChessBoard } from 'react-icons/fa';
import styles from '../css/profile.module.css';
import userStyles from './css/user.module.css';
import { GET, POST, saltRounds } from '../utils/requests';
import { watch } from '../assets/icons';
import { FaChess, FaLink, FaBell, FaPlusCircle } from 'react-icons/fa';
import { AlertContext, AuthContext } from '../Contexts';
import { defaultSettings } from '../resources/settings';
import * as regexes from '../utils/regexes';
import { getFlag } from '../utils/prototype';
import ChessCom from '../chess/ChessCom';

export function RenderTournament({ p, className }: { p: EnhancedPlayer, className?: string }): JSX.Element {	

	const [playerNames, setNames] = useState({} as {[key: string]: string});
	const [shouldFetch, setFetch] = useState([] as string[]);
	useEffect(() => {
		GET({
			url: ['tournament', p.tournamentId, 'fetchPlayers'].join('/'),
			params: { ids: shouldFetch.join(',') }
		}).then((list: Player[]) => {
			let names = {} as {[key: string]: string};
			for (let p of list) {
				names[p.id] = p.name;
			}
			setNames(names);
		});
	}, [p.tournamentId, shouldFetch, setNames]);

	const player = new Player(p, p.tournament.round, p.tournament.setting);
	
	let totalRounds = typeof p.tournament.setting.totalRounds as string | number !== 'string' ?
		p.tournament.setting.totalRounds !== Infinity ?
			p.tournament.setting.totalRounds :
			p.histories.length + 1 :
		p.histories.length + 1;
	let histories = p.histories.slice(0);

	useEffect(() => {
		let f = [] as string[];
		p.histories.forEach((h, i) => {
			if (!i) return null;
			if (!h) h = {} as any;
			let { id } = h;
			if (id && id.toLowerCase() !== 'bye' && !(id in playerNames)) f.push(id);
		});
		if (f.length) setFetch(f);
	}, [p.histories, playerNames, setFetch]);

	for (let i = 0; i <= totalRounds; i++) if (!histories[i]) histories[i] = null as any;

	let [last, next] = [null, null] as [EnhancedHistory | null, EnhancedHistory | null];
	for (let i = 1; i < histories.length; i++) {
		if (!histories[i]) {
			next = null;
			break;
		}
		let h = Object.assign(histories[i], {
			round: i,
			w: histories[i].colour === 'W' ? p.id : histories[i].id,
			b: histories[i].colour === 'W' ? histories[i].id : p.id
		});
		if (h.match === null) {
			next = h;
			break;
		}
		last = h;
	}
	
	return (
		<section key={['section', p.id].join('.')} className={['container', styles.background, className].join(' ')}>
			<div className={styles.stage}>
				<Link to={`/t/${p.tournament.setting.vanityURL || p.tournament.id}`} className={[styles.name, styles.box].join(' ')}>{p.tournament.name}</Link>
				<div className={[styles.scores, styles.box].join(' ')}>
					{Object.entries(columns.post).map(([k, meta], i) => {
						if (meta.show && !meta.show(player, i, p.tournament.setting, 'results')) return null;
						return <div className={styles.row} key={['row', i].join('.')}>
							<div className={styles.key}>{meta.brief || meta.mobile}</div>
							<div className={styles.value}>
								{meta.convert ?
									meta.convert(player[k as keyof Player], player, p.tournament.setting, 'results') :
									player[k as keyof Player]?.toString()}
							</div>
						</div>;
					})}
				</div>
				<div className={styles.midButtons}>
					<Link to={`/t/${p.tournament.setting.vanityURL || p.tournament.id}`} className={[styles.currRound, styles.box, 'header-button'].join(' ')}>
						Round: {p.tournament.round}
					</Link>
					<div className={[styles.nextRound, styles.box, 'header-button'].join(' ')}>
						Time: {p.tournament.setting.timeControl}
					</div>
					<div className={[styles.nextRound, styles.box, 'header-button'].join(' ')}>
						Started: {new Date(p.tournament.createdAt).toString().slice(0, 15)}
					</div>
				</div>
				<div className={[styles.history, styles.box].join(' ')} style={p.tournament.setting.totalRounds < 10 ? {gridTemplateRows: `1.5em repeat(${p.tournament.setting.totalRounds}, auto)`} : {}}>
					<div className={styles.subtitle}>
						Results
					</div>
					{histories.map((h, i) => {
						if (!i) return null;
						if (!h) h = {} as any;
						let { id, colour } = h;
						let game = h.game as number | null;
						let match = h.match as number | null;
						let showGame = h.game !== null && p.tournament.setting.displayPoints === 'game';
						let w = colour === 'W' ? p.id : h.id;
						let b = colour === 'W' ? h.id : p.id;
						if (id && id.toLowerCase() !== 'bye' && !(id in playerNames)) shouldFetch.push(id);
						let data = [
							i,
							playerNames[id] || (id === 'bye' ? 'BYE' : id),
							colour || '\u200b',
							game !== null ? game : <FaEllipsisH />,
							match !== null ? match : <FaEllipsisH />
						];
						return (
							<div className={[styles.resultsRow, showGame ? '' : styles.small].join(' ')} key={['row', 'history', i].join('.')}>
								{data.map((entry, j) => {
									let className = j === 1 ? styles.playerName : styles.fieldBox;
									let to = j === 1 ?`/${p.tournament.setting.competitors === 'team' ? 'team' : 'player'}/${id}`  : `/${p.tournament.id}/pairings/${i}#${w}.${b}`;
									if (id === 'bye') to = '';
									if (j === 3 && !showGame) return null;
									if (!id) {
										return <div className={className} key={['entry', j].join('.')}>{entry}</div>;
									} else {
										return <Link to={to} className={className} key={['entry', j].join('.')}>{entry}</Link>;
									}
								})}
							</div>
						);
					})}
				</div>
				{p.active && p.tournament.active ?
					<div className={styles.action}>
						{last ?
							<Link className='header-button' to={`/${p.tournament.id}/pairings/${last.round}#${last.w}.${last.b}`}>
								<img src={watch} alt='watch' />Analyse last game
							</Link>
							: null}
						{next ?
							<Link className='header-button' to={`/${p.tournament.id}/pairings/${next.round}#${next.w}.${next.b}`}>
								<FaEye />View next game
							</Link>
							: null}
					</div> :
					null}
			</div>
		</section>
	);

}

export function renderTeam(t: SmallTeam): ReactElement {
	
	return (
		<section className={['container', styles.background].join(' ')}>
			<div className={styles.teamStage}>
				<Link to={`/team/${t.id}`} className={[styles.name, styles.box].join(' ')}>
					{t.name}
				</Link>{/*
				<div className={[styles.scores, styles.box].join(' ')}>
					{Object.entries(columns.post).map(([k, meta], i) => {
						return <div className={styles.row} key={['row', i].join('.')}>
							<div className={styles.key}>{meta.brief || meta.mobile}</div>
							<div className={styles.value}>
								{'\u200b'}
							</div>
						</div>;
					})}
				</div>
				<div className={[styles.currRound, styles.box, 'header-button'].join(' ')}>
					Round
				</div>
				<div className={[styles.nextRound, styles.box, 'header-button'].join(' ')}>
					{'\u200b'}
				</div>
				<div className={[styles.history, styles.box].join(' ')}>
					<div className={styles.subtitle}>
						Results
					</div>
					{[].map((h, i) => {
						let entry = '\u200b';
						return (
							<div className={[styles.resultsRow].join(' ')} key={['row', 'history', i].join('.')}>
								<div className={styles.fieldBox}>{entry}</div>
								<div className={styles.fieldBox}>{entry}</div>
								<div className={styles.fieldBox}>{entry}</div>
								<div className={styles.fieldBox}>{entry}</div>
							</div>
						);
					})}
				</div>*/}
				<div className={styles.action}>
					<div className='header-button'>
						<FaFlag style={{color: 'var(--col-alert1)' }} />Founded: {new Date(t.createdAt).toString().slice(4, 15)}
					</div>
					<div className='header-button'>
						<FaChessPawn />Last online: {new Date(t.updatedAt).toString().slice(4, 15)}
					</div>
				</div>
			</div>
		</section>
	);

}

export function RenderMatch({u, update, className}: {u: EnhancedMatch, update: () => void, className?: string}) {
	const setAlert = useContext(AlertContext);
	const { authLevel } = useContext(AuthContext);

	let {prop, opp, colour, round, id, result, startTime, endTime, games} = u;

	let swap = colour === 'B' && prop.tournament.setting.gamePointTotal === 1;
	let w = (swap ? opp : prop) || Player.bye(prop.tournamentId, u.round, prop.tournament.setting);
	let b = (swap ? prop : opp) || Player.bye(prop.tournamentId, u.round, prop.tournament.setting);
	let type = prop.tournament.setting.competitors === 'individual' ? 'player' : 'team';
	let link = result.link as string;
	
	const [match, setMatch] = useState(new Match({
		w: w as any,
		b: b as any,
		tournamentId: prop.tournamentId,
		scores: (!w || !b) ? [0, 0] : [w.histories[round]?.game, b.histories[round]?.game] as [number, number],
		teams: [],
		id, round, link, startTime, endTime, games,
		...result.fetched
	}, round));
	const source = prop.tournament.setting.source ? (defaultSettings.automation.source.convert as ((v: string) => string))(prop.tournament.setting.source) : '';

	const handleRemove = useCallback(() => {
		setAlert({
			title: 'Remove game link',
			message: <>Remove this game link for match: {prop.name} vs {opp.name}?</>,
			type: 'confirm',
			resolve: () => POST({
				url: ['', 'submit', 'removeGameLink'].join('/'),
				data: {
					id: result.id,
					playerId: prop.id,
					team: prop.tournament.setting.competitors === 'team'
				}
			})
		});
	}, [setAlert, prop.id, prop.name, opp.name, result.id, prop.tournament.setting.competitors]);
	
	const handleAdd = useCallback(() => {
		setAlert({
			title: 'Add game link',
			message: `Input your ${type} game link${source ? ' from ' + source : ''}:`, 
			icon: prop.tournament.setting.source === 'chessCom' ? 'chess.com' : undefined,
			type: 'prompt',
			resolve: async (link: string) => {
				let W = colour === 'B' ? opp : prop;
				let B = colour === 'B' ? prop : opp;
				let key = await bcrypt.hash([W.id, B.id, match.tournamentId, round].join('.'), saltRounds);
				POST({
					url: '/submit/gameLink',
					data: {
						idW: W.id,
						idB: B.id,
						tournamentId: match.tournamentId,
						round,
						key,
						link
					}
				}).then(update);
			},
			prompt: {
				validate: (v: string) => prop.tournament.setting.source === 'chessCom' ? 
					type === 'team' ?
						regexes.chessCom.test(v) :
						true :
					true,
				errorMessage: type === 'team' ? 'Link must be a valid from ' + source : '',
				additionalInputProps: {
					autoComplete: 'game-link',
					spellCheck: false
				}
			}
		});
	}, [setAlert, type, match, round, update, colour, opp, prop, source]);
	
	return <section className={['container', userStyles.upcomingBattle, className].join(' ')}>
		<div className={[userStyles.stage, swap ? userStyles.reversed : ' '].join(' ')}>
			<Link to={w.id === 'bye' ? '#' : `/${type}/${w.id}`} className={userStyles.name} style={{gridArea: 'wName'}}>
				{w.name}
			</Link>
			{w?.nationality ? <div className={styles.flag} style={{gridArea: 'wFlag'}}>
				{getFlag(w.nationality)}					
			</div> : null}
			<Link to={['', 't', prop.tournament.setting.vanityURL || prop.tournamentId, 'pairings', round].join('/')} className={[userStyles.vs, userStyles.round].join(' ')}>
				{round}
			</Link>
			<div className={userStyles.vs}>
				VS
			</div>
			{b?.nationality ? <div className={styles.flag} style={{gridArea: 'bFlag'}}>
				{getFlag(b.nationality)}					
			</div> : null}
			<Link to={b.id === 'bye' ? '#' : `/${type}/${b.id}`} className={userStyles.name} style={{gridArea: 'bName'}}>
				{b.name}
			</Link>
			<div className={userStyles.scoreBox}>
				{match.isFinished && match.games ?
					<ChessCom
						id={match.id}
						match={match}
						setMatch={setMatch}
						setFinished={() => {}}
						sync={() => {}}
						fetch={false}
						expanded={true}
						enforceBoards={match.players ? Number(match.players) : 0}
						round={round}
						teams={match.teams}
						showButtons={false}
					/> :
					<div className={userStyles.warning}>
						{match.startTime ?
							'Match is scheduled to start at ' + new Date(match.startTime).toString().slice(0, 15) :
							'Match has not yet started'
						}
					</div>
				}
			</div>
			<div className={userStyles.infoBox}>
				{prop.histories && prop.histories[round] && match.scores.some((s: number | undefined) => s) ?
					<>
						<div>Result:</div>
						<div style={{fontWeight: 700}}>{(() => {
							switch (prop.histories[round].match) {
							case 1:										
								return 'Win';
							case 0.5:										
								return 'Draw';
							case 0:										
								return 'Loss';
							}
						})()}</div>
					</> :
					null
				}
				<div>Scores:</div>
				<div>{match.scores.join('-')}</div>
				<div>{match.startTime && new Date(match.startTime).valueOf() < Date.now() ? 'Started:' : 'Starting:'}</div>				
				{match.startTime ? new Date(match.startTime).toString().slice(0, 10) + ' ' + new Date(match.startTime).toString().slice(16, 21):
					// eslint-disable-next-line no-constant-condition
					(authLevel && false ? //TODO
						<div className={userStyles.clickButton}>Add starting time</div> :
						<div className={[userStyles.null, userStyles.clickButton].join(' ')}>No starting time added</div>
					)
				}
			</div>
			<div className={userStyles.buttons}>
				{match.isFinished ?
					<Link to={['', 't', prop.tournament.setting.vanityURL || prop.tournamentId, 'pairings', round].join('/') + '#' + id} className={['header-button', 'link', userStyles.join].join(' ')}>
						<FaChessBoard />
						Scores
					</Link> :
					match.link ?
						<>
							<a href={match.link as string} rel='noopener noreferrer' target='_blank' className={['header-button', 'link', userStyles.join].join(' ')}>
								<FaLink />
								Join game on {source}
							</a>
							{authLevel >= 2 ?
								<div className={['header-button', 'link', userStyles.remove].join(' ')} onClick={handleRemove}>
									Remove link
								</div> :
								null
							}
						</> :
						authLevel ?
							<div className={['header-button', 'link', userStyles.join, userStyles.gameLink].join(' ')} onClick={handleAdd}>
								<FaPlusCircle />
								Add game link
							</div> :
							<div className={['header-button', userStyles.join, userStyles.null].join(' ')}>
								No game link added
							</div>
				}						
				<Link to={['', 't', prop.tournament.setting.vanityURL || prop.tournamentId].join('/')} className={['header-button', 'link'].join(' ')}>
					<FaChess />{prop.tournament.name}
				</Link>
				{match.startTime && Date.now() < match.startTime ?
					<div className={['header-button', 'link', userStyles.null].join(' ')}>
						<FaBell />Set reminder
					</div> :
					null
				}
			</div>
		</div>
	</section>;
}