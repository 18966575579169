import { useContext, useMemo } from 'react';
import { SettingsContext } from '../../Contexts';
import { TieBreaks } from '../../resources/tiebreaks';

export default function useTiebreaks() {
	
	const settings = useContext(SettingsContext);

	const tieBreaks = useMemo(() => {
		let entries = Object.entries(TieBreaks);
		if (settings.tieBreaks) {
			let arr = settings.tieBreaks.split(',');
			entries = entries.sort((a, b) => arr.indexOf(a[0]) - arr.indexOf(b[0]));
		}
		return entries;
	}, [settings]);

	return { tieBreaks };

}