import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import styles from '../css/components.module.css';


function MouseEvents(props: {
	bottom: number
	right: number
	setBottom: (y: number) => void
	setRight: (x: number) => void
}): void {

	const [click, setClick] = useState(false);
	const [startBottom, setStartBottom] = useState(0);

	const handleMouseMove = useCallback((e: MouseEvent, initialX: number, initialY: number) => {
		if (!click) return;
	}, [click, props.setBottom, props.setRight]);

	useEffect(() => {
		if (!click) return;
		let callback = (e: MouseEvent) => handleMouseMove(e, props.right, props.bottom);
		document.addEventListener('mousemove', callback);
		return () => document.removeEventListener('mousemove', callback);
	}, [click, handleMouseMove, props.right, props.bottom]);
	useEffect(() => {
		let callback = () => setClick(true);
		//document.addEventListener('mousedown', callback);
		//return () => document.removeEventListener('mousedown', callback);
	}, [setClick]);
	useEffect(() => {
		let callback = () => setClick(false);
		document.addEventListener('mouseup', callback);
		return () => document.removeEventListener('mouseup', callback);
	}, [setClick]);
}

interface DraggableProps {
	children?: ReactNode
	margin: number
}

export default function Draggable(props: DraggableProps) {

	const [bottom, setBottom] = useState(10);

	if (!props.children) return null;

	//MouseEvents({ bottom, right, setBottom, setRight });

	return <div className={['popped', styles.draggable].join(' ')} style={{ bottom }}>
		{props.children}
	</div>;
}