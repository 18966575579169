import React, { ReactElement, RefObject, useRef, useContext, useCallback, useReducer } from 'react';
import Markdown from 'react-markdown';
import { MdAnnouncement } from 'react-icons/md';
import cx from 'classnames';
import { Notif } from '../interfaces';
import * as regexes from '../utils/regexes';
import { FaFacebook, FaTwitter, FaTwitch, FaGithub, FaTimesCircle, FaCheckCircle } from 'react-icons/fa';
import styles from '../css/components.module.css';

import { capitalise } from '../utils/prototype';
import { parseHtml, RouterLink } from '../utils/plugins';
import contactFields from '../resources/contact';
import { AlertContext } from '../Contexts';

export function getLogo(brand: string): ReactElement | null {
	switch (brand) {
	case 'chess.com': {
		return <img src={contactFields.chessCom.icon[1] as string} alt='chessCom' className={styles.icon} /> ;
	}
	case 'facebook.com': {
		return <FaFacebook style={{color: '#3b5998'}} />;
	}
	case 'twitter.com': {
		return <FaTwitter style={{color: '#00aced'}} />;
	}
	case 'twitch.tv': {
		return <FaTwitch style={{color: '#6441a5'}} />;
	}
	case 'github.com': {
		return <FaGithub style={{color: '#333'}} />;
	}
	default: {
		return <MdAnnouncement />;
	}
	}
}

export interface AlertProps {
	title: string
	icon?: JSX.Element | string
	link?: string
	message: JSX.Element | string
	resolve?: (value: string) => void
	reject?: () => void
	type?: 'error' | 'confirm' | 'prompt'
	prompt?: {
		type?: 'text' | 'number' | 'textarea'
		validate?: (value: string) => boolean
		errorMessage?: string
		allowNull?: boolean
		defaultValue?: string
		placeholder?: string
		additionalInputProps?: {[key: string]: any}
	}
}

export function AlertBox(props: AlertProps) {

	const setAlert = useContext(AlertContext);
	const setMessage = useCallback((message: string) => setAlert({ title: '', message }), [setAlert]);
	const [error, toggleError] = useReducer((state: boolean) => !state, false);

	const input = useRef(null) as RefObject<HTMLInputElement>;

	const handleSubmit = useCallback(() => {
		if (props.type === 'confirm' && props.resolve) return props.resolve('');
		let value = input.current?.value.trim();
		if (!value && !props.prompt?.allowNull) return;
		if (props.prompt?.validate && !props.prompt.validate(value as string)) return toggleError();
		if (props.type === 'prompt' && props.resolve) props.resolve(value as string);
		setMessage('');
	}, [input, props, setMessage]);
	
	const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		switch (e.keyCode) {
		case 27:
			if (input.current?.value) input.current.value = '';
			break;
		case 13:
			handleSubmit();
			break;
		}
	}, [handleSubmit, input]);

	if (!props.message) return null;
	let domain = (props.link || '').match(regexes.link);
	const icon = typeof props.icon !== 'string' ?
		props.icon :
		props.icon ?
			getLogo(props.icon) :
			domain ?
				getLogo(domain[1]) :
				null;

	return <div className={styles.shade} onClick={() => setMessage('')}>
		<div className={[styles.section].join(' ')}>
			<div className={[styles.lightBoxTitle, props.type === 'error' ? styles.error : ''].join(' ')} onClick={() => setMessage('')}>
				<div>
					{props.link ? <a className={styles.iconLink} href={props.link} rel='noopener noreferrer' target='_blank'>
						{icon}
					</a> :
						<div>{icon}</div>}
				</div>
				<div>{props.title}</div>
			</div>
			<div className={styles.lightBoxContent} onClick={(e) => e.stopPropagation()}>
				{props.message}
				{props.link ? <><a href={props.link} rel='noopener noreferrer' target='_blank'>{props.link}</a></> : null}
				{props.type === 'prompt' ?
					props.prompt?.type === 'textarea' ?
						<textarea
							ref={input as any}
							onKeyDown={handleKeyDown}
							autoFocus
							defaultValue={props.prompt?.defaultValue}
							{...props.prompt?.additionalInputProps}
						/> :
						<input
							ref={input}
							onKeyDown={handleKeyDown}
							type={props.prompt?.type || 'text'}
							autoFocus
							defaultValue={props.prompt?.defaultValue}
							{...props.prompt?.additionalInputProps}
						/> :
					null
				}
				{error ?
					<div className={styles.error}>{props.prompt?.errorMessage}</div> :
					null
				}
			</div>
			{props.type === 'confirm' || props.type === 'prompt' ?
				<div className={styles.dateTime} onClick={(e) => e.stopPropagation()}>
					<div className={styles.reject} onClick={() => {
						if (props.reject) props.reject();
						setMessage('');
					}} >
						<FaTimesCircle />
					</div>
					<div className={styles.resolve} onClick={handleSubmit}>
						<FaCheckCircle />
					</div>
				</div> :
				<div className={styles.dateTime}>
					<div className={styles.footer}>
						{new Date(Date.now()).toString().slice(0, 21)}
					</div>
				</div>
			}
		</div>
	</div>;
}

export default function NotifBox(props: {
	notif: Notif
	select: (n: Notif) => void
}) {

	let n = props.notif;
	if (!n.content) return null;

	return <div className={styles.shade} onClick={() => props.select({} as Notif)}>
		<div className={[styles.section].join(' ')}>
			<NotifContent {...props} styles={styles} />
		</div>
	</div>;
}

export function NotifContent(props: {
	notif: Notif
	select: (n: Notif) => void
	styles?: {[key: string]: string}
}) {

	let nStyles = props.styles || styles;

	let n = props.notif;
	if (!n.content) return null;
	let icon = <MdAnnouncement /> as JSX.Element | null;
	let domain = (n.link || '').match(regexes.link);
	if (domain) icon = getLogo(domain[1]);

	return <>
		<div className={cx(nStyles.lightBoxTitle, nStyles.title)}>
			<div>
				{n.link ? <a className={nStyles.iconLink} href={n.link} rel='noopener noreferrer' target='_blank'>
					{icon}
				</a> :
					<div>{icon}</div>}
			</div>
			<div>
				{n.title}
			</div>
		</div>
		<div className={nStyles.lightBoxContent} onClick={(e) => e.stopPropagation()}>
			<Markdown
				source={n.content.trim() + (n.link ? `\n\n[${n.link}](${n.link})` : '')}
				escapeHtml={false}
				astPlugins={[parseHtml]}
				renderers={{
					link: RouterLink
				}}
			/>
		</div>
		<div className={nStyles.dateTime}>
			<div className={nStyles.footer}>
				{capitalise(n.role)} Announcement | {new Date(n.createdAt).toString().slice(0, 21)}
			</div>
		</div>
	</>;
}