import React, { ReactElement } from 'react';
import leven from 'leven';
import countries from '../assets/countries.json';
import { flags } from '../config.json';
const specifyFlags = [
	'BGD',
	'BLZ',
	'GBR',
	'GIB',
	'FJI'
];

export function count<T extends any>(arr: T[]): {[key: string]: number} {
	return arr.reduce((acc: {[key: string]: number}, c: any) => {
		if (c == null) return acc;
		let curr = c.toString() as string;
		if (!(curr in acc)) acc[curr] = 0;
		acc[curr]++;
		return acc;
	}, {});
}

export function avMode<T extends any>(arr: T[]): string | void {
	let obj = count(arr);
	let entries = Object.entries(obj);
	let sorted = entries.sort((a, b) => b[1] = a[1]);
	let first = sorted[0] as [string, number] || [];
	return first[0];
}

export function px(...arr: (string | number)[]): string {
	return ['', ...arr].join('/');
}

export function capitalise(str: string): string {
	if (!str) return str;
	return str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
}

export function randBetween(min: number, max: number): number {
	return Math.floor(Math.random() * (max - min + 1) + min);
}

export function compare(_myName: string, _pulledName: string): number {
	let myName = _myName.toLowerCase().trim();
	let pulledName = _pulledName.toLowerCase().trim();
	if (!myName || !pulledName) return 1;
	if (myName === pulledName) return 0;
	if (myName.length > 6) {
		if (pulledName.startsWith(myName)) return 0;
		if (pulledName.endsWith(myName)) return 0;
		if (myName.startsWith(pulledName)) return 0;
		if (myName.endsWith(pulledName)) return 0;
	}
	let l = leven(myName, pulledName) / myName.length;
	return l;
}

export function shuffle(array: any[]) {
	var currentIndex = array.length, temporaryValue, randomIndex;
	
	// While there remain elements to shuffle...
	while (0 !== currentIndex) {
	
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;
	
		// And swap it with the current element.
		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}
	
	return array;
}

export function timeBetween(from: Date, to: Date) {
	const units = ['year', 'month', 'day', 'hour', 'minute', 'second'];
	let fromArr = [from.getUTCFullYear(), from.getUTCMonth(), from.getUTCDate(), from.getUTCHours(), from.getUTCMinutes(), from.getUTCSeconds()];
	let toArr = [to.getUTCFullYear(), to.getUTCMonth(), to.getUTCDate(), to.getUTCHours(), to.getUTCMinutes(), to.getUTCSeconds()];
	for (let i = 0; i < units.length; i++) {
		if (fromArr[i] !== toArr[i]) {
			let value = toArr[i] - fromArr[i];
			let unit = units[i];
			if (value >= 2) unit += 's';
			return [value, unit, 'ago'].join(' ');
		}
	}
}

export function getFlag(v: string): ReactElement | null {
	if (!v) return null;
	let title = countries[v as keyof typeof countries];
	let src = flags.replace('|', v);
	if (specifyFlags.includes(v)) src = '/images/|.svg'.replace('|', v);
	return <img src={src} alt={v} title={title} />;
}


export function convertPoolDisplay(pool: number, setting: number) {
	if (setting === 0) return letterise(pool);
	if (setting === 1) return pool;
	if (setting === 2) return romanise(pool);
	return pool;
}

export function letterise(num: number): string {
	let n = num - 1;
	let base = String.fromCharCode(65 + (n % 26));
	let res = base.repeat(Math.floor(n / 26) + 1);
	return res;
}

export function romanise(num: number) {
	if (isNaN(num)) return NaN;
	let digits: string[] = num.toString().split('');
	let key = ['','C','CC','CCC','CD','D','DC','DCC','DCCC','CM',
		'','X','XX','XXX','XL','L','LX','LXX','LXXX','XC',
		'','I','II','III','IV','V','VI','VII','VIII','IX'];
	let roman = '';
	let i = 3;
	while (i--) {
		let d = parseInt(digits.pop() as string);
		roman = (key[d + (i * 10)] || '') + roman;
	}
	return Array(+digits.join('') + 1).join('M') + roman;
}

export function truncate(s: string, size: number): string {
	if (!s.length || size < 1) return s;
	if (s.length > size) return s.substring(0, size - 3) + '...';
	return s;
}

export function getDocumentHeight(): number {
	var D = document;
	return Math.max(
		D.body.scrollHeight, D.documentElement.scrollHeight,
		D.body.offsetHeight, D.documentElement.offsetHeight,
		D.body.clientHeight, D.documentElement.clientHeight
	);
}

export function getAbsoluteTop(elem: HTMLElement): number {
	let parent: HTMLElement | null = elem;
	while (parent) {
		parent = parent.parentElement;
		if (!parent) break;
		if (window.getComputedStyle(parent).position === 'static') continue;

		let h = elem.getBoundingClientRect().top;
		let j = parent.getBoundingClientRect().top;
		return h - j;
	}
	return 0;
}

(window as any).getDocumentHeight = getDocumentHeight;

export function isVisible(elem: HTMLElement | null | undefined): boolean {
	if (!elem) return false;
	let rect = elem.getBoundingClientRect();
	if (window.innerHeight < rect.top) return false;
	if (rect.y + rect.height < 0) return false;
	//if (parseFloat(window.getComputedStyle(elem).opacity || '1') === 0) return false;
	return true;
}
(window as any).isVisible = isVisible;