import React, { useCallback, useContext, useRef, RefObject, useEffect, useState } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { FaAngleRight, FaBan, FaCode } from 'react-icons/fa';

import { AlertContext } from '../../Contexts';
import { POST } from '../../utils/requests';
import { capitalise, px } from '../../utils/prototype';
import { SingleProps } from '../Info';
import styles from '../css/user.module.css';
import { ContactMeta } from '../../resources/contact';
import { ContactEntry } from '../../interfaces';
import { useClub } from '../callbacks/ChessCom';

export default function useCreate(props: SingleProps & { fields: [ContactMeta, ContactEntry][]}) {

	const setAlert = useContext(AlertContext);
	const { getClubName, chessComClub } = useClub();
	const { fields } = props;

	const createTrigger = useCallback(() => {
		setAlert({ title: '', message: '' });
		POST({ url: [props.type, props.id, 'create'].join('/') })
			.then(props.updateProfile);
	}, [props.id, props.type, props.updateProfile, setAlert]);

	const input = useRef(null) as RefObject<HTMLInputElement>;

	const handleSubmit = useCallback(() => {
		if (!input.current) return Promise.resolve();
		return POST({
			url: px(props.type, props.id, 'createCode'),
			data: {
				code: input.current?.value
			}
		})
			.then(props.updateProfile)
			.then(() => setAlert({ title: '', message: '' }));
	}, [input, props.id, props.type, props.updateProfile, setAlert]);
	const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		switch (e.keyCode) {
		case 27:
			if (input.current?.value) input.current.value = '';
			break;
		case 13:
			handleSubmit();
			break;
		}
	}, [handleSubmit, input]);

	const handleCreate = useCallback(async () => {
		let someAuth = false;
		if (!props.profile) return;
		setAlert({
			title: 'Claim ' + props.type,
			message: <>
				{capitalise(props.type as string)} <b>{props.profile.name}</b> has the following available authentication methods:
				<div className={styles.auth}>
					<input
						ref={input}
						onKeyDown={handleKeyDown}
						autoFocus
						spellCheck={false}
						placeholder='xxxx-xxxx'
					/>
					<div
						className={cx('button', 'link', styles.submit, styles.account, {[styles.null]: input.current?.value })}
						onClick={handleSubmit}
					>
						<FaCode className={styles.icon} />
						Claim via code
						<FaAngleRight />
					</div>
					{fields.map(([meta, entry], i) => {
						let userF = props.profile && props.profile[meta.id === 'email' ? 'emailMeta' : meta.id as keyof typeof props.profile] as ContactEntry;
						let name = getClubName(meta, entry);
						let canAuth: boolean | null | undefined = (() => {
							if (!userF) return null;
							if (entry.id && entry.id === userF.id) return true;
							if (entry.username && entry.username.toLowerCase() === userF.username?.toLowerCase()) return true;
							if (meta.id === 'chessCom' && chessComClub && chessComClub.admin) {
								if (chessComClub.admin.some(a => a.split('/').pop() === userF?.username?.toLowerCase())) return true;
							}
							return false;
						})();
						if (canAuth !== false) someAuth = true;
						if (canAuth === null) return <>
							<Link
								to={'/me/accounts'}
								id={meta.id}
								className={['button', 'link', styles.account].join(' ')}
								onClick={() => setAlert({ title: '', message: '' })}
							>
								{typeof meta.icon[1] === 'string' ? <img src={meta.icon[1]} alt={meta.id} className={styles.icon} /> : React.cloneElement(meta.icon[1], { className: styles.icon })}
								Link {meta.name || capitalise(meta.id as string)} account
								<FaAngleRight />
							</Link>
							<div />
						</>;
						return <>
							<div
								id={meta.id}
								className={['button', 'link', styles.account, canAuth === false ? styles.null : ''].join(' ')}
								onClick={canAuth ? createTrigger : () => {}}
							>
								{typeof meta.icon[1] === 'string' ? <img src={meta.icon[1]} alt={meta.id} className={styles.icon} /> : React.cloneElement(meta.icon[1], { className: styles.icon })}
								{canAuth ?
									<>Claim via {meta.name || capitalise(meta.id as string)}</>
									: name
								}
								{!canAuth ? <FaBan /> : <FaAngleRight />}
							</div>
							<div className={styles.authMessage}>
								{!canAuth ?
									canAuth === false ?
										<small>
											{meta.authMessage}
										</small> :
										<small>
											You need to link your {meta.name || capitalise(meta.id as string)} account to use it as an authentication method here.
										</small> :
									null}
							</div>
						</>;
					})}
				</div>
				{!someAuth ?
					Object.values(fields).length ?
						<>You do not have any available authentication methods for this {props.type}.<br />Do you definitely own it?</> :
						<>There are no available authentication methods for this {props.type}.<br />Submit a request to your Tournament director.</> :
					null
				}
			</>
		});
	}, [createTrigger, fields, props, handleSubmit, getClubName, setAlert, chessComClub, handleKeyDown, input]);

	const [hasRun, setRun] = useState(false);
	useEffect(() => {
		if (!window.location.search) return;
		let params = new URLSearchParams(window.location.search);
		let code = params.get('claim');
		if (!code) return;
		if (hasRun) return;
		handleCreate();
		if (!input.current) return;
		input.current.value = code;
		setRun(true);
	}, [handleCreate, input, hasRun, setRun]);

	return { handleCreate, fields };

}