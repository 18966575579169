import React, { useState, useReducer } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { FaCommentDots } from 'react-icons/fa';

import styles from '../css/floated.module.css';

export default function Feedback() {

	const [showFeedback, toggleFeedback] = useReducer(s => !s, false);

	return (
		<div className={cx(styles.floated, styles.bottom, styles.left)}>
			<div className={styles.dotExpando} onClick={toggleFeedback}>
				<FaCommentDots />
			</div>
			<div className={styles.wrapper} style={{ maxWidth: showFeedback ? undefined : '0px' }}>
				<Link className={styles.link} to='/contact#feedback'>
					Like the new design? Leave feedback here
				</Link>
				<div className={cx(styles.expando)} onClick={toggleFeedback}>
					Not now
				</div>
			</div>
		</div>
	);
}