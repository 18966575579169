import React, { useMemo, ReactElement, useState, useEffect } from 'react';
import cx from 'classnames';
import { StaticContext } from 'react-router';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import styles from './css/crosstable.module.css';

import Standings from './Standings';
import Leaderboard from './Leaderboard';
import { Player, TournamentSettings, TournamentStatus } from '../../interfaces';
import Knockout from './Knockout';

import { useDisplay } from '../Pairings/hooks';
import AutoComplete from '../../components/AutoComplete';
import { FaSearch } from 'react-icons/fa';
import useResults from './hooks/useResults';

export default function StandingsRouter({
	players,
	settings,
	status,
	state,
	rProps,
	direction
}: {
	players: Player[]
	settings: TournamentSettings
	status: TournamentStatus
	state: string[]
	rProps: RouteComponentProps<any, StaticContext, any>
	direction: string
}) {

	const { getName } = useDisplay(settings);                  // Access getName function with hook for Sidebar
	const [searched, setSearched] = useState([] as Player[]);  // Searching function for standings

	// Search bar above standings table
	const searchBar = useMemo(() => {
		return (
			<section className={styles.container}>
				<div className={styles.searchBarDiv}>
					<div className={styles.searchBar}>
						<FaSearch />
						<AutoComplete
							onChange = {setSearched}
							onSubmit={Promise.resolve}
							action=''
							options={players}
							convert={getName}
							styles={styles}
							placeholder='Search'
						/>
					</div>
				</div>
			</section>
		);
	}, [setSearched, players, getName]);

	const { results, updateResults } = useResults({ status });
	useEffect(() => {
		if (settings.pairingSystem === 'knockout') updateResults();
		else if (status.systems.some(s => s.system === 'knockout')) updateResults();
	}, [updateResults, settings, status]);

	// Final round redirect
	if (rProps.match.params.round === 'final') return <Redirect push to={{
		pathname: (rProps.match.url + (rProps.match.url.endsWith('/') ? '' : '/')).split('/').slice(0, -2).join('/') + '/' + settings.totalRounds,
		state: rProps.history.location.state
	}} />;

	// If individual standings requested
	if (rProps.match.params.round === 'individual') {
		return <Leaderboard status={status} />;
	}

	let round = parseInt(rProps.match.params.round);
	// Edge case checks in case round number error
	if (isNaN(round)) return null;
	if (round < 0 || round > settings.totalRounds) return null;

	// Knockout component
	if (!status.systems.length) status.systems.push({
		from: 1,
		to: status.round + 1,
		system: settings.pairingSystem
	});
	return <>
		{status.systems.slice(0).reverse().map(({from, to, system, poolNames}, i, arr) => {

			if (system === 'knockout') {
				return <Knockout key={cx('knockout', i + 1)} {...{ status, settings}}
					stage={i + 1}
					start={from}
					finish={to - 1}
					results={results.filter(r => {
						if (r.round < from) return false;
						if (r.round > to) return false;
						return true;
					})}
					poolNames={poolNames}
				/>;
			}

			// Function determines how many times a player appears in the 'pool'
			let poolDistribution = players.reduce((acc, curr) => {
				let pool = curr.pool || 0;
				if (!acc[pool]) acc[pool] = 0;
				acc[pool]++;
				return acc;
			}, [] as number[]);

			return <>
				{arr.findIndex(s => s.system !== 'knockout') === i ? searchBar : null}
				{poolDistribution.map((count, i) => {
					if (!count) return null;
					return <Standings
						key={cx('standings', i)}
						pool={i}
						start={from}
						finish={to - 1}
						{...{ status, players, settings, state, round, searched }}
					/>;
				})}
			</>;

		})}
	</>;
}