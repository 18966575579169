import { useCallback, useEffect, useState } from 'react';
import { Result, TournamentStatus } from '../../../interfaces';
import { GET } from '../../../utils/requests';

export default function useResults({ status }: { status: TournamentStatus }) {

	const [results, setResults] = useState([] as Result[]);
	const updateResults = useCallback(() => {
		if (!status.id) return;
		if (!status.round) return;
		return GET({
			url: '/tournament/' + status.id + '/getPairings',
			params: { round: null, withResults: true }
		}).then(setResults);
	}, [setResults, status.id, status.round]);

	return { results, updateResults };

}