import React, { useMemo, RefObject, useContext, useRef } from 'react';
import { TwitchPlayer } from 'react-twitch-embed';

import styles from '../../css/streams.module.css';
import { LiveContext } from '../../Contexts';

interface TwitchProps {
	width?: string | number
	height?: string | number
	ref?: RefObject<HTMLDivElement>
}

export default function Twitch({
	ref,
	width,
	height
}: TwitchProps) {

	if (!width) width = '100%';
	let r = useRef() as RefObject<HTMLDivElement>;
	if (!ref) ref = r;

	const { channel } = useContext(LiveContext);

	const component = useMemo(() => {
		if (!channel) return null;
		if (!width) {
			if (!ref?.current) return null;
			let w = ref?.current.getBoundingClientRect();
			width = w.width;
		}
		return <TwitchPlayer
			id={styles.twitchPlayer}
			autoplay={false}
			{...{ channel, width, height }}
		/>;
	}, [channel, height, width, ref]);

	return <>
		<div
			id={styles.twitchPlayer}
			ref={ref}
			className={styles.player}
			style={{ width, height }}
		/>
		<div className='hidden'>
			{component}
		</div>
	</>;
}