import React, { useState, useReducer, useEffect } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import ChessCom from '../../chess/ChessCom';
import * as icons from '../../assets/icons';
import { FaLink, FaChessBoard } from 'react-icons/fa';
import {TournamentSettings } from '../../interfaces';
import Match from '../../models/Match';
import { getFlag } from '../../utils/prototype';
import { ChessTeam } from 'chess-web-api';
import styles from './css/pairings.module.css';

interface RowProps {
	data: any[]
	i: number
	match: Match
	pool: number
	setMatch: (match: Match) => void
	setFinished: (id: string) => void
	hidden: boolean
	fetch: boolean
	round: number
	expanded: boolean
	setExpanded: (id: string) => void
	settings: TournamentSettings
	highlight?: boolean
	fetchGames: (ids?: string[] | undefined) => Promise<void>
}

const def = [] as ChessTeam[];
export default function Row(props: RowProps) {
	
	const { match, data, pool } = props;
	const [hasExpanded, cache] = useState(match.isLive);
	useEffect(() => {
		if (props.expanded && !hasExpanded) cache(true);
	}, [props.expanded, hasExpanded, cache]);

	const [shouldSync, sync] = useReducer((state: boolean, action: boolean) => typeof action !== 'undefined' ? action : true, false);

	if (!match) return null;
	if (match.round !== props.round) return null;
	if ((match.pool || pool) && match.pool !== pool) return null;
	
	return (
		<>
			<tr id={match.id} className={cx(styles.row, {
				odd: props.i % 2,
				even: props.i % 2 === 0,
				collapsed: props.hidden,
				highlighted: props.highlight
			})} style={{
				visibility: props.hidden ? 'collapse' : undefined
			}}>
				<td>
					<div className={styles.flag}>{getFlag(data[6])}</div>
				</td>
				<td id={match.w?.id}>
					{match.w?.id === 'bye' ?
						<div>{data[0]}</div> :
						<Link to={['', props.settings.competitors === 'individual' ? 'player' : 'team', match.w?.id].join('/')} className='playerName'>{data[0]}</Link>
					}
				</td>
				<td className={styles.inner} id={match.link?.split('/').slice(-1).join('/')}>
					{match.link ? <a className='button' href={match.link} target='_blank' rel='noopener noreferrer'>
						<FaLink />
						<div>chess.com</div>
					</a> : null}
				</td>     
				<td className={styles.inner}>
					<div className={[styles.score, styles[match.status], match.status].join(' ')}>
						{match.isFinished || match.isLive ? data[1] : null}
					</div>
				</td>
				<td style={{whiteSpace: 'pre-line'}}>{data[4] || '-'}</td>
				<td className={styles.inner}>
					<div className={[styles.score, styles[match.status], match.status].join(' ')}>
						{match.isFinished || match.isLive ? data[3] : null}
					</div>
				</td>
				<td className={styles.inner}>
					{match.link && match.isScheduled ?
						<div className={['button', match.isLive ? styles.watchLive : ''].join(' ')} onClick={() => {
							if (!match.isFinished) return;
							if (!match.games?.length) props.fetchGames([match.id]).then(() => props.setExpanded(match.id));
							else props.setExpanded(match.id);
							if (window.location.hash && decodeURIComponent(window.location.hash).includes(match.id)) {
								window.history.pushState('', document.title, window.location.pathname + window.location.search);
							}
							else window.location.hash = '#' + match.id;
						}}>
							{match.isFinished ?
								<div>Scores</div> :
								<>
									{match.isLive ?
										<img className='icon' src={icons.watch} alt={'Watch games'}/> :
										<FaChessBoard />
									}
									<a className={styles.watchButton} href={'https://www.chess.com/live#tm=' + match.teamID} target='_blank' rel='noopener noreferrer'>
										{match.isLive ?
											'Watch live' :
											'Join Game'
										}
									</a>
								</>
							}
						</div> :
						null
					}
				</td>
				<td id={match.b?.id}>
					{match.b?.id === 'bye' ?
						<div>{data[2]}</div> :
						<Link to={['', props.settings.competitors === 'individual' ? 'player' : 'team', match.b?.id].join('/')} className='playerName'>{data[2]}</Link>
					}
				</td>
				<td>
					<div className={styles.flag}>{getFlag(data[7])}</div>
				</td>
			</tr>
			{match.link ? <tr key={['row', 'dropdown'].join('.')} className={[props.hidden || !props.expanded  ? 'collapsed' : ''].join(' ')} style={props.hidden || !props.expanded ? {visibility: 'collapse'} : {}}>
				<td colSpan={100}>
					{match.linkSource === 'chessCom' ?
						match.linkType === 'team' ?
							<ChessCom
								id={match.id}
								match={match}
								setMatch={props.setMatch}
								setFinished={props.setFinished}
								sync={sync as (set?: boolean) => void}
								fetch={props.fetch || shouldSync}
								expanded={props.expanded}
								enforceBoards={match.players ? parseInt(match.players as any as string) : 0}
								round={props.round}
								teams={match.teams || def}
							/> :
							null :
						null
					}
				</td>
			</tr> : null}
		</>
	);
}