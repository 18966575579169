import React, { ReactElement, useState, useContext, useRef, RefObject, useCallback, useEffect, useMemo, useReducer, ReactNode } from 'react';
import { GET } from '../../utils/requests';
import { Team, ProfileData, TournamentStatus, Player, Result } from '../../interfaces';
import { TournamentSettings } from '../../resources/settings';
import { useMatches, useDisplay, useSidebar } from '../Pairings/hooks';
import { Game } from '../../resources/result';
import Row from '../Pairings/Row';
import { LoginContext } from '../../Contexts';
import cx from 'classnames';
import { convertPoolDisplay, px } from '../../utils/prototype';
import { capitalise } from '../../utils/prototype';

import styles from './css/featured.module.css';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';

interface FeaturedProps {
	playerDict: Map<string, Player>
	settings: TournamentSettings
	id: string
	round: number
	status: TournamentStatus
	profile: ProfileData & Team | null
	direction: string
}

const defaultRows = 10;

export default function Featured(props: FeaturedProps) {

	const { getName } = useDisplay(props.settings);
	const [results, setResults] = useState([] as Result[]);
	const [gameDict, setGameDict] = useState({} as {[key: string]: Game[]});
	const { isLoggedIn } = useContext(LoginContext);
	const [loaded, hasLoaded] = useState(false);
	const headerRow = useRef() as RefObject<HTMLTableRowElement>;


	const {
		matches, matchDict, setMatch, populatematchDict,
		fetchedMatch, fetchNext, hasFetched, beforeSetResults
	} = useMatches({ playerDict: props.playerDict, results, settings: props.settings });
	const {
		searched, expanded, visible,
		setSearched, setExpanded, setVisible
	} = useSidebar();

	const fetchGames = useCallback((ids?: string[]) => {
		return GET({
			url: px('tournament', props.id, 'getPairings'),
			params: {
				round: props.round,
				ids: ids?.join(','),
				games: true
			}
		}).then(arr => setGameDict(arr.reduce((acc: {[key: string]: Game[]}, curr: { id: string, games: Game[]}) => {
			acc[curr.id] = curr.games;
			return acc;
		}, {})));
	}, [props.id, props.round, setGameDict]);

	useEffect(() => {
		beforeSetResults();
		setResults([]);
	}, [setResults, beforeSetResults, props.round]);
	
	useEffect(() => {
		populatematchDict(results, props.round);
	}, [populatematchDict, results]);
	useEffect(() => {
		fetchNext();
	}, [fetchNext]);
	
	useEffect(() => {
		GET({
			url: '/tournament/' + props.id + '/getPairings',
			params: { round: props.round }
		})
			.then((data) => {
				setResults(data);
				hasLoaded(true);
				return data;
			})
			.then((data: Result[]) => {
				if (window.location.hash) return;
				if (props.round >= props.status.round) return;
			})
			.catch(() => {});
	}, [setResults, props.round, props.id, fetchGames, props.status.round]);

	const poolDistribution = useMemo(() => results.reduce((acc, curr) => {
		let pool = curr.pool || 0;
		if (!acc[pool]) acc[pool] = 0;
		acc[pool]++;
		return acc;
	}, [] as number[]), [results]);

	const [isFull, setFull] = useReducer((full: boolean) => {
		return !full;
	}, false);

	const [matchDisplay, setMatchDiplay] = useState(0);

	const filteredMatchList = useMemo(() => {

		//Filter first so we can use this information to decide whether to display or not
		let filtered = Object.values(matchDict).filter((match) => {
			if (!match) return false;
			if (match.isFinished) return false;
			return true;
		});
		const sorted = filtered.sort((a, b) => {
			if (!a) return -1;
			if (!b) return 1;
			return a.board - b.board;
		});
		return sorted;
	}, [matchDict]);

	const Rows = useCallback(function Rows({ pool }: { pool: number }) {

		return <>
			{filteredMatchList.map((match, i) => {
				
				// Better to filter for the button specifically here because then we can say something about the aggregate ex: for finished tournaments above
				if (matchDisplay === 1 && !match.isFeatured) return null;

				// Display up to 10 matches
				if (isFull && i > defaultRows) return null;

				if (match.id in gameDict) match.games = gameDict[match.id];
				let displayTime = match.startTime || match.registrationTime;
				let date = new Date(displayTime || 0).toString();
				let data = [
					getName(match.w),
					match.scores[0] ?? ((match.isLive || match.isFinished) && match.teams && (match.w?.chessCom || match.b?.chessCom) ? match.teams[0].score : ''),
					getName(match.b),
					match.scores[1] ?? ((match.isLive || match.isFinished) && match.teams && (match.w?.chessCom || match.b?.chessCom) ? match.teams[1].score : ''),
					displayTime ? date.slice(0, 10) + '\n' + date.slice(16, 21): '',
					match.link,
					match.w ? match.w.nationality : '',
					match.b ? match.b.nationality : ''
				] as string[];

				return <Row
					key={['row', match.board].join('.')}
					i={i}
					{...{ data, match, pool, setMatch, setExpanded, fetchGames }}
					setFinished={hasFetched}
					round={props.round}
					fetch={fetchedMatch ? fetchedMatch.id === match.id : false}
					expanded={expanded.includes(match.id)}
					highlight={isLoggedIn && match.w && match.b && (match.w.id === props.profile?.username || match.b.id === props.profile?.username || props.profile?.teams?.some(t => t && (t.id === match.w.teamId || t.id === match.b.teamId)))}
					hidden={(visible !== 'all' && match.status !== visible) || (!!searched.length && !searched.some(c => c.id === match.w?.id || c.id === match.b?.id))}
					settings={props.settings}
				/>;
			})}
		</>;
	}, [matchDisplay,
		gameDict, getName, fetchedMatch, setExpanded,
		expanded, searched, matchDict, hasFetched, visible, fetchGames, setMatch,
		props.round, props.settings, props.profile, isLoggedIn
	]);

	const headerData:[string, string][] = [['Fed', '5%'], ['White', '22.5%'], ['Link', '12.5%'], ['', '5%'], ['Start Time', '10%'], ['', '5%'], ['Watch Live', '12.5%'], ['Black', '22.5%'], ['Fed', '5%']];

	const matchDisplayOptions:string[] = ['upcoming', 'featured'];
	const matchSelect = useMemo(() => {
		return (
			<>
				{matchDisplayOptions.map((value, index) => {
					return (
						<div
							key={index}
							onClick={() => setMatchDiplay(index)}
							className={['header-button', styles.menuItems, matchDisplay === index ? styles.selectedItem : ''].join(' ')}
						>
							{capitalise(value)}
						</div>
					);
				})}
			</>
		);
	}, [setMatchDiplay, matchDisplay]);

	if (!filteredMatchList.length) return null;
	if (Object.keys(matchDict).length === 0) return null;
	if (matchDict.constructor !== Object) return null;

	return (
		<>
			<div className={styles.featured}>
				<div className={['container', 'bodyContainer', styles.headingContainer].join(' ')}> 
					<div className={[styles.headerItems, 'button-list'].join(' ')}>
						{matchSelect}
					</div>
				</div>
				{(() => {
					let tables = [];
					let hasRefed = false;
					// Iterate through all pools if pool tournament
					for (let i = 0; i < poolDistribution.length; i++) {
						if (!poolDistribution[i]) continue;
						tables.push(
							<div key={['table', i].join('.')} className={cx(styles.table, { still: searched.length })}>
								{i ? <div className={styles.pool}>Pool {convertPoolDisplay(i, props.settings.poolDisplay)}</div> : null}
								<table className={cx('download-table', 'full-width', 'pairings', styles.featured_table)}>
									{/* Table headings */}
									<thead>
										<tr ref={hasRefed ? null : headerRow}>
											{headerData.map((info, index) => 	
												<td key={index} className={styles.stickyheader} style={{width: info[1]}}>{info[0]}</td>
											)}
										</tr>
									</thead>
									{/* Table rows */}
									<tbody className={styles.tbody}>
										{loaded && !results.length ? <tr><td colSpan={100}>Draw has not yet taken place!</td></tr> : null}                       
										<Rows pool={i} />
									</tbody>
								</table>

								{ filteredMatchList.length >= 10 ?
									<button onClick={()=>setFull()} className={styles.showAllButton}> 
										{ (isFull ? <FaAngleUp /> : < FaAngleDown />) } 
									</button> : 
									null
								}
							</div>
						);
						hasRefed = true;
					}
					return tables;
				})()}
			</div>
		</>
	);
}