import { Player as PlayerData } from '../models';
import { TournamentSettings, Player } from '../interfaces';
import { getFlag } from '../utils/prototype';

export interface ColumnData {
	id?: (entry: PlayerData, i: number, s: TournamentSettings) => string
	name: string | ((key: string, i: number) => string)
	className: 'blank' | 'playerName' | 'playerName left' | 'flag' | 'locked' | 'rating' | 'unset' | 'edit' | ((v: string, i: number, settings: TournamentSettings) => string),
	sort?: false | ((a: any, b: any) => number),
	show?: (entry: PlayerData, i: number, settings: TournamentSettings, h?: string) => boolean
	convert?: (v: any, p: PlayerData, s: TournamentSettings, h: string) => string | JSX.Element
	postConvert?: (v: string, i: number) => string
	mobile?: string
	brief?: string,
	title?: (p: PlayerData, i: number, players: Player[]) => string,
	more?: boolean
}

export const columns = {
	pre: {
		rank: {
			mobile: 'Rank',
			name: '',
			className: 'blank',
			sort: false,
			postConvert: (v: string, i: number): string => (1 + i).toString()
		},
		nationality: {
			name: 'Fed',
			mobile: 'Federation',
			className: 'flag',
			convert: getFlag
		},
		name: {
			name: 'Name',
			className: 'playerName left',
			id: (p: PlayerData, i: number, s: TournamentSettings): string => ['', s.competitors === 'individual' ? 'player' : 'team', p.id].join('/'),
			sort: (a: string, b: string) => {
				if (a > b) return 1;
				if (a < b) return -1;
				return 0;
			},
		},
		rating: {
			name: 'Rating',
			className: 'locked',
			show: (e: PlayerData, i: number, s: TournamentSettings) => s.showRatings
		}
	},
	roundColumn: {
		name: (key: string, i: number): string => key.slice(-1),
		id: (p: PlayerData, i: number, s: TournamentSettings): string => {
			if (!p.histories || !p.histories[i] || p.histories[i].match === null) return '';
			let h = p.histories[i];
			let { colour } = h;
			let w = colour === 'W' ? p.id : h.id;
			let b = colour === 'W' ? h.id : p.id;
			return [
				'',
				't',
				s.vanityURL || p.tournamentId,
				'pairings',
				i
			].join('/') + '#' + h.resultId;
		},
		title: (p: PlayerData, i: number, players: Player[]): string => {
			if (!p.histories || !p.histories[i]) return '';
			let h = p.histories[i];
			let opp = players.find(o => o.id === h.id);
			if (!opp) return '';
			let result = h.game !== null && h.game !== undefined ? '\n' + [h.game, h.played - h.game].join('-') : '';

			let bonuses = '';
			if (p.bonuses) for (let b of p.bonuses) if (b.round === i) bonuses = '\nBonus granted by organiser';
			return opp.name + result + bonuses;
		},
		className: (v: string, i: number, s: TournamentSettings) => {
			if (v === '*') return 'edit';
			if (v === '') return 'unset';
			else return 'playerName';
		},
		convert: (v: string, p: PlayerData, s: TournamentSettings, h: string) => {
			if (v === '') return '';
			else if (v === '*') return '*';
			let bonus = false;
			let round = Number(h.slice(-1));
			if (p.bonuses) for (let b of p.bonuses) if (b.round === round) { v += b.MP; bonus = true; }
			let nb = parseFloat(v);
			let output: string;
			if (nb === 0.5) output = '½';
			else output = nb.toString();
			if (v.endsWith('b')) output = '(' + output + ')';
			if (bonus) output += '*';
			return output;
		},
		sort: (a: string, b: string): number => {
			if (a === b) return 0;
			if (a === '') return 1;
			else if (b === '') return -1;
			if (a === '*') return 1;
			else if (b === '*') return -1;
			let [aN, bN] = [Number(a), Number(b)];
			if (a.toString().endsWith('b')) aN -= 0.001;
			if (b.toString().endsWith('b')) bN -= 0.001;
			return bN - aN;
		}
	},
	post: {
		score: {
			mobile: 'Match Points',
			name: 'MP',
			className: 'locked',
			convert: (v: number, p: PlayerData): string => Math.floor(v) === v ? v.toFixed() : v === 0.5 ? '½' : Math.floor(v).toFixed() + '½'
		},
		gamePoints: {
			mobile: 'Game Points',
			name: 'GP',
			className: 'locked',
			convert: (v: number, p: PlayerData): string => Math.floor(v) === v ? v.toFixed() : v === 0.5 ? '½' : Math.floor(v).toFixed() + '½'
		},
		gameDifference: {
			mobile: 'Game Point Difference',
			brief: 'Game Point +/-',
			name: 'G. +/-',
			className: 'locked',
			convert: (v: number): string => {
				if (!v) return '';
				if (v > 0) return '+' + v.toFixed(1);
				else return v.toFixed(1);
			},
			more: true
		},
		gamesPlayed: {
			mobile: 'Games Played',
			name: 'P',
			className: 'locked',
			convert: (v: number): string => v.toString(),
			more: true
		},
		matchPercentage: {
			mobile: 'Match Point Percentage',
			brief: 'Match Point +/-',
			name: 'M. %',
			className: 'rating',
			convert: (v: number): string => v.toFixed(),
			more: true
		},
		gamePercentage: {
			mobile: 'Game Point Percentage',
			brief: 'Game Point %',
			name: 'G. %',
			className: 'rating',
			convert: (v: number): string => v.toFixed(),
			more: true
		},
		performanceRating: {
			mobile: 'Performance Rating',
			brief: 'Perf. Rating',
			name: 'Perf',
			className: 'rating',
			convert: (v: number): string => v.toFixed(),
			show: (entry: PlayerData, i: number, s: TournamentSettings) => s.showRatings,
			more: true
		},
		ratingDifference: {
			mobile: 'Performance Rating Difference',
			brief: 'Perf. Rating +/-',
			name: 'P. +/-',
			className: 'rating',
			convert: (v: number): string => {
				if (!v) return '';
				if (v > 0) return '+' + v.toString();
				else return v.toString();
			},
			show: (entry: PlayerData, i: number, s: TournamentSettings) => s.showRatings,
			more: true
		},
		expectedScore: {
			mobile: 'Expected Score',
			name: 'Expected',
			className: 'rating',
			convert: (v: number, p: PlayerData): string => v.toFixed(1) + ' / ' + p.played,
			show: (entry: PlayerData, i: number, s: TournamentSettings) => s.showRatings,
			more: true
		},
		expectedDifference: {
			mobile: 'Score to Expected',
			brief: 'Expected +/-',
			name: 'E. +/-',
			className: 'rating',
			convert: (v: number): string => {
				if (typeof v === 'undefined') return '';
				if (v > 0) return '+' + v.toFixed(2);
				else return v.toFixed(2);
			},
			show: (entry: PlayerData, i: number, s: TournamentSettings) => s.showRatings,
			more: true
		},
		wins: {
			mobile: 'Wins',
			name: 'W',
			className: 'locked',
			show: (e, i, s, p: string) => p !== 'results',
			convert: (v: number): string => v.toString(),
			more: true
		},
		draws: {
			mobile: 'Draws',
			name: 'D',
			className: 'locked',
			show: (e, i, s, p: string) => p !== 'results',
			convert: (v: number): string => v.toString(),
			more: true
		},
		losses: {
			mobile: 'Losses',
			name: 'L',
			className: 'locked',
			show: (e, i, s, p: string) => p !== 'results',
			convert: (v: number): string => v.toString(),
			more: true
		},
		played: {
			mobile: 'Played',
			name: 'P',
			className: 'locked',
			show: (e, i, s, p: string) => p !== 'results',
			convert: (v: number): string => v.toString(),
			more: true
		}
	}
} as {
	pre: {
		[key: string]: ColumnData
	},
	roundColumn: ColumnData
	post: {
		[key: string]: ColumnData
	}

};