import { useState, useDebugValue, useMemo } from 'react';

export function useStateWithLabel(initialValue: any, name: string) {
	const [value, setValue] = useState(initialValue);
	useDebugValue(`${name}: ${value}`);
	return [value, setValue];
}

export function useMemoWithLabel(factory: () => any, deps: React.DependencyList | undefined, name: string) {
	const value = useMemo(factory, deps);
	useDebugValue(name);
	return value;
}