import React, { useContext } from 'react';
import { FaChess, FaUserCircle, FaCogs, FaUsers } from 'react-icons/fa';
import { GiCrossedSwords } from 'react-icons/gi';

import { User } from '../components/Switchers/';
import { LoginContext, AuthContext } from '../Contexts';
import { Team, ProfileData } from '../interfaces';

interface SwitcherInterface {
	type: 'team' | 'individual'
	profile: Team & ProfileData
	id: string
	section: string
}

export default function Switcher(props: SwitcherInterface) {

	const { isLoggedIn } = useContext(LoginContext);
	const { isAuthed, authLevel } = useContext(AuthContext);

	if (props.type === 'individual') return <User
		section={props.section}
		loaded
		sections={{
			tournaments: {
				link: `/@/${props.profile.username}/tournaments`,
				icon: <FaChess />
			},
			teams: {
				link: `/@/${props.profile.username}/teams`,
				icon: <FaUsers />
			},
			accounts: {
				hide: !isAuthed,
				link: `/@/${props.profile.username}/accounts`,
				icon: <FaUserCircle />
			},
			settings: {
				hide: !isAuthed,
				link: `/@/${props.profile.username}/settings`,
				icon: <FaCogs />
			}
		}}
	/>;

	return <User
		section={props.section || 'play'}
		loaded
		sections={{
			'play': {
				name: 'Play',
				link: `/${props.type}/${props.id}/`,
				icon: <GiCrossedSwords />
			},
			tournaments: {
				link: `/${props.type}/${props.id}/tournaments`,
				icon: <FaChess />
			},
			members: {
				hide: !isLoggedIn || (!props.profile.players && !props.profile.teamId),
				link: `/${props.type}/${props.id}/members`,
				icon: <FaUsers />
			},
			accounts: {
				hide: authLevel < 2 || true,	//TODO
				link: `/${props.type}/${props.id}/accounts`,
				icon: <FaUserCircle />
			},
			settings: {
				hide: authLevel < 2,
				link: `/${props.type}/${props.id}/settings`,
				icon: <FaCogs />
			}
		}}
	/>;
			
}