import React, { useState, useCallback, useEffect, ReactElement, useContext } from 'react';
import { Link } from 'react-router-dom';
import styles from './css/members.module.css';
import { GET, POST } from '../utils/requests';
import { ProfileData, Team } from '../interfaces';
import { FaCrown, FaCircle, FaPlusCircle } from 'react-icons/fa';
import AutoComplete from '../components/AutoComplete';
import { renderTeam } from './hooks';
import { AuthContext } from '../Contexts';

export default function Teams(props: {
	profile: ProfileData
	updateProfile: () => void
}): ReactElement {

	if (!props.profile.teams?.length) return <section className='container'>
		This user is not in any teams
	</section>;

	return <>
		{(props.profile.teams || []).map(renderTeam)}
	</>;
}

export function Members(props: {
	team: Team & ProfileData
	updateProfile: () => void
}) {

	const { authLevel } = useContext(AuthContext);

	const [users, setUsers] = useState([]);
	const updateUsers = useCallback(() => GET({ url: '/users' })
		.then(setUsers), [setUsers]);
	useEffect(() => {
		updateUsers();
	}, [updateUsers]);

	const [admins, setAdmins] = useState([] as ProfileData[]);
	const [members, setMembers] = useState([] as ProfileData[]);
	useEffect(() => {
		if (!props.team.users) return;
		let a = [] as ProfileData[];
		let m = [] as ProfileData[];
		for (let u of props.team.users) {
			if (u.level) a.push(u);
			else m.push(u);
		}
		setAdmins(a);
		setMembers(m);
	}, [setAdmins, setMembers, props.team]);

	const addUser = useCallback((u: ProfileData) => POST({
		url: ['team', props.team.id, 'addUser'].join('/'),
		data: { username: u.username }
	})
		.then(props.updateProfile), [props.updateProfile, props.team.id]);

	return (
		<>
			<div className={styles.columns}>
				<section className='container'>
					<h1><b>Admins</b></h1>
					<div className={styles.userTable}>
						{admins.map((u: ProfileData, i: number) => {
							return <Link key={['admin', i].join('.')} className={styles.row} to={'/@/' + u.username} draggable>
								<div className={styles.status}>
									{u.isLicensed ? <FaCrown title='Licensed' /> : <FaCircle style={{fontSize: '0.5em'}} title={u.online ? 'Online' : 'Offline'} />}
								</div>
								<div className={styles.profile}>
									{u.username}
								</div>
							</Link>;
						})}
					</div>
				</section>
				<section className='container'>
					<h1><b>Members</b></h1>
					<div className={styles.userTable}>
						{members.map((u: ProfileData, i: number) => {
							return <Link key={['member', i].join('.')} className={styles.row} to={'/team/' + u.username} draggable>
								<div className={styles.status}>
									{u.isLicensed ? <FaCrown title='Licensed' /> : <FaCircle style={{fontSize: '0.5em'}} title={u.online ? 'Online' : 'Offline'} />}
								</div>
								<div className={styles.profile}>
									{u.username}
								</div>
							</Link>;
						})}
						{
							authLevel ?
								<>
									<div />
									<div className={styles.searchBar}>
										<FaPlusCircle />
										<AutoComplete
											onChange={() => {}}
											onSubmit={addUser}
											action=''
											options={users}
											convert={(u: ProfileData) => u.username}
											styles={styles}
											placeholder='Add new member'
										/>
									</div>
								</> :
								null
						}
					</div>
				</section>
			</div>
			<section />
		</>
	);
}