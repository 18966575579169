import { useState, useReducer, useEffect } from 'react';
import { Player } from '../../../interfaces';

export default function useSidebar() {

	const [searched, setSearched] = useState([] as Player[]);
	const [visible, setVisible] = useState('all');

	/**
	 * Manage table state as to which rows are expanded
	 */
	const [expanded, setExpanded] = useReducer((state: string[], action: string[] | string) => {
		if (Array.isArray(action)) return action;
		let list = state.slice(0) || [];
		if (state.includes(action)) list.splice(list.indexOf(action), 1);
		else list.push(action);
		return list;
	}, [decodeURIComponent(window.location.hash.slice(1).split('.').slice(0, 2).join('.'))]);


	const [scrolled, setScrolled] = useState(false);
	
	useEffect(() => {
		if (scrolled) return;
		if (!window.location.hash) return;
		let id = window.location.hash.slice(1);
		let elem = document.getElementById(id);
		if (elem) {
			elem.scrollIntoView({
				behavior: 'auto'
			});
			setScrolled(true);
		}
	});

	return {
		searched, setSearched,
		expanded, setExpanded,
		visible, setVisible
	};
	
}