import React, { ReactElement } from 'react';
import './App.css';


function NotFound(): ReactElement {
	return <div id='main'>
		<div className='container' style={{
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			width: 'max-content',
			alignSelf: 'center'
		}}>
			<img src={process.env.PUBLIC_URL + '/logo512.png'} style={{width: 256, height: 256}} alt='Not-Found'/>
			<div>PAGE NOT FOUND</div>
		</div>
		<div className='container'>
			Think this is a mistake? Check out our <a href='https://twitter.com/scorchchess' rel='noopener noreferrer' target='_blank'>Twitter page</a> for the latest maintenance notices. Tweet <a href='https://twitter.com/scorchchess' rel='noopener noreferrer' target='_blank'>@scorchchess</a> if you think there's an error.
		</div>
	</div>;
}

export default NotFound;
