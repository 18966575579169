import { useState, useEffect, useReducer } from 'react';
import { GET } from '../../../utils/requests';
import { TournamentStatus, Player, TournamentPlayers } from '../../../interfaces';
import { TournamentSettings } from '../../../resources/settings';

export default function useLoad({ id }: {
	id: String
}) {

	const [loaded, setLoaded] = useReducer((state: { [key: string]: boolean }, { action, set }: { action: string, set?: boolean }) => {
		let obj = Object.assign({}, state);
		obj[action] = set !== undefined ? set : true;
		if (Object.values(obj).length >= 4) obj.all = true;
		return obj;
	}, {});
	const [settings, setSettings] = useState({} as TournamentSettings);
	const [status, setStatus] = useState({} as TournamentStatus);
	const [state, setState] = useState([] as string[]);
	const [rawPlayers, setPlayers] = useState([] as Player[]);
	const [isLive, setLive] = useState(false);
	const [announcements, setAnnouncements] = useState([] as {
		announcement: string
		createdAt: string
		updatedAt: string
	}[]);

	useEffect(() => {
		GET({ url: '/tournament/' + id + '/fetchPlayers' })
			.then(setPlayers)
			.then(() => setLoaded({ action: 'players' }))
			.catch(() => setLoaded({ action: 'players', set: false }));
	}, [setPlayers, id, setLoaded]);

	useEffect(() => {
		GET({ url: '/tournament/' + id + '/getSettings' })
			.then(setSettings)
			.then(() => setLoaded({ action: 'settings' }))
			.catch(() => setLoaded({ action: 'settings', set: false }));
	}, [setSettings, id, setLoaded]);

	useEffect(() => {
		GET({ url: '/tournament/' + id + '/getState' })
			.then(({ players }: { players: TournamentPlayers }) => {
				let order = Object.entries(players)
					.map(([k, v]) => [Number(k), v] as [number, string[]])
					.sort((a, b) => b[0] - a[0])
					.reduce((acc, curr) => acc.concat(curr[1]), [] as string[]);
				setState(order);
			})
			.then(() => setLoaded({ action: 'state' }))
			.catch(() => setLoaded({ action: 'state', set: false }));
	}, [setState, id, setLoaded]);

	useEffect(() => {
		GET({ url: '/tournament/' + id + '/getStatus' })
			.then(setStatus)
			.then(() => setLoaded({ action: 'status' }))
			.catch(() => setLoaded({ action: 'sattus', set: false }));
	}, [setStatus, setLoaded, id]);

	useEffect(() => {
		GET({ url: ['', 'tournament', id, 'getAnnouncements'].join('/') }).then(setAnnouncements);
	}, [id, setAnnouncements]);

	useEffect(() => {
		GET({ url: ['', 'tournament', id, 'isLive'].join('/') }).then(r => setLive(r.isLive));
	}, [id, setLive]);

	return { loaded, settings, status, state, rawPlayers, isLive, announcements };
}