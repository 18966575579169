import React, { useEffect, useState, useCallback, ReactElement, useContext, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';

import { GET, POST } from '../utils/requests';
import { Team as TeamInterface, ProfileData } from '../interfaces';
import NotFound from '../404';
import Info from './Info';
import Tournaments from './Tournaments';
import { Members } from './Members';
import Play from './Play';
import { TeamSettings } from './Settings';
import { AlertContext, AuthContext } from '../Contexts';
import Switcher from './Switcher';
import styles from './css/user.module.css';
import Sticky from '../components/Sticky';
import { px } from '../utils/prototype';
import useBio from './hooks/useBio';

// Actually represents the class for any tournament player
export default function Team(props: {
	id: string
	profile: ProfileData
	type: 'player' | 'team'
}): ReactElement | null {

	const [player, setPlayer] = useState(undefined as TeamInterface & ProfileData | null | undefined);
	const updateProfile = useCallback(() => GET({ url: px(props.type, props.id) })
		.then(p => {
			setPlayer(p);
			return p;
		})
		.then(p => {
			if (p.teamId) window.history.pushState('', document.title, window.location.pathname.replace(props.id, p.teamId) + window.location.search);
			else if (p.userId) window.history.pushState('', document.title, window.location.pathname
				.replace(props.id, p.userId)
				.replace('/player/', '/@/') + window.location.search);
			return p;
		})
		.catch(() => setPlayer(null)), [setPlayer, props.id, props.type]);
	useEffect(() => {
		updateProfile();
	}, [updateProfile]);

	const { updateBio } = useBio({ profile: player, updateProfile });	

	const authLevel: number | boolean = useMemo(() => {
		return props.type === 'player' ?
			props.id.toLowerCase() === props.profile.uniqueID ? 2 : 0 :
			typeof player?.permissions === 'number' ? player.permissions + 1 : 0;
	}, [props.type, props.id, props.profile, player]);
	const isAuthed = authLevel === 2;
	
	if (player === undefined) return null;
	if (player === null) return <NotFound />;
	
	return <AuthContext.Provider value={{ isAuthed, authLevel }}>
		<div id='main'>
			<div className={styles.container}>
				<div className={styles.col1}>
					<Sticky upperLimit={null}>
						<div className={styles.infoWrapper}>
							<Info
								{...props}
								profile={player}
								updateProfile={updateProfile}
								updateBio={updateBio}
								type={props.type}
							/>
						</div>
					</Sticky>
				</div>
				<div className={styles.col2}>
					<Route path={'/:type/:id/:section?'} render={(rProps) => {
						return <Switcher
							{...rProps.match.params}
							profile={player}
						/>;
					}} />
					<Route render={({location}) => {
						return (
							<Switch location={location}>
								<Route exact path={`/${props.type}/${props.id}`} render={() => {
									return <Play {...props} profile={player} type={props.type} />;
								}}/>
								<Route exact path={`/${props.type}/${props.id}/tournaments`} render={() => {
									return <Tournaments {...props} profile={player} type={props.type}/>;	
								}}/>
								<Route exact path={`/${props.type}/${props.id}/members`} render={() => {
									return <Members {...props} team={player} updateProfile={updateProfile} />;	
								}}/>
								<Route exact path={`/team/${props.id}/settings`} render={() => {
									if (!isAuthed) return null;
									return <TeamSettings
										{...props}
										user={props.profile}
										profile={player}
										updateProfile={updateProfile}
										updateBio={updateBio}
									/>;	
								}}/>
							</Switch>
						);
					}} />
				</div>
			</div>
		</div>
	</AuthContext.Provider>;
}