import React from 'react';
import { Link } from 'react-router-dom';
import styles from './css/footer.module.css';
import { FaCopyright } from 'react-icons/fa';
import Package from '../../package.json';

export default function Footer() {

	return (
		<footer className={styles.footer} data-aos='fade-up' data-aos-offset={5}>
			<div className={styles.col1}>
				<div 
					onClick={() => window.scrollTo({ top: 0 })}
					className={styles.logoWrapper}
				>
					<img
						src={process.env.PUBLIC_URL + '/favicon.png'}
						className={styles.logo}
						alt='logo'
					/>
				</div>
				<div 
					onClick={() => window.scrollTo({ top: 0 })}
					className={styles.text}>
					<b>Scorch</b>Results
				</div>
				<div className={styles.copyright}>
					ScorchChess <FaCopyright /> 2020 All Rights Reserved
				</div>
				<div className={styles.version}>
					<small>Website v.{Package.version}</small>
				</div>
			</div>
			<div className={styles.col2}>
				<div className={styles.shortcuts}>
					<Link to='/contact#feedback'>
						Feedback
					</Link>
					<Link to='/community'>
						Community
					</Link>
					<Link to='/privacy'>
						Privacy
					</Link>
					<Link to='/tos'>
						Terms of Service
					</Link>
				</div>
			</div>
		</footer>
	);
}