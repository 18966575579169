import React, { useEffect, useState, useMemo, useCallback } from 'react';
import cx from 'classnames';
import { StaticContext } from 'react-router';
import { Link, Route, Redirect, Switch, RouteComponentProps } from 'react-router-dom';
import { Player, ProfileData } from '../interfaces';
import * as Switcher from '../components/Switchers';
import NotFound from '../404';
import styles from '../css/nav.module.css';
import { FaWindowClose, FaUser, FaGlobe, FaBook, FaBullhorn, FaFileAlt, FaPlay } from 'react-icons/fa';
import { Announcements, Pairings, Results, Standings as StandingsRouter, Live, Stats as Chart, Dashboard } from '.';
import * as regexes from '../utils/regexes';
import Draggable from '../components/DraggableSticky';
import { Img } from '../components/Image';
import { capitalise, px } from '../utils/prototype';
import { TieBreaks } from '../resources/tiebreaks';
import Contexts, { TiebreakContext, DisplayNameContext, LiveContext, SettingsContext } from '../Contexts';
import usePlayers from './Standings/Players';
import PlayerData from '../models/Player';
import Circle from '../components/Circle';
import useLoad from './Pairings/hooks/useLoad';
import useRotating, { Background } from '../components/RotatingTournaments';
import Rules from './Rules';
import { isMobile } from '../utils/auth';
import { cookies } from '../utils/requests';
import useTiebreaks from './hooks/useTiebreaks';

export default function Tournament(props: RouteComponentProps<any, StaticContext, any> & {
	profile: ProfileData
}) {

	const id: string = props.match.params.id;
	const [poppedComponent, pop] = useState(null as null | HTMLDivElement);

	const { tieBreaks } = useTiebreaks();
	
	const {
		loaded, settings, status, state,
		rawPlayers, isLive, announcements
	} = useLoad({ id });
	const players: PlayerData[] = usePlayers({ status, players: rawPlayers, settings, round: status.round });
	const playerDict: Map<string, PlayerData> = useMemo(() => {
		if (!players.length) return new Map();
		let dict = new Map();
		for (let p of players) dict.set(p.id, p);
		return dict;
	}, [players]);
	const round = status.round || 0;
	
	const tournaments = useMemo(() => [Object.assign({}, status, settings)], [status, settings]);
	const { gradients } = useRotating({ tournaments });

	const liveContext = useMemo(() => {
		let matches = settings.streamURL?.match(regexes.streamLink);
		let [domain, channel] = (matches || []).slice(1);
		let site: 'twitch' | 'youtube' = domain?.split('.').shift() as 'twitch' | 'youtube';
		return { isLive, channel, site };
	}, [settings, isLive]);

	useEffect(() => {
		if (!round) return;
		let histories = new Array(round + 1);
		for (let i = 1; i < round + 1; i++) histories[i] = {
			match: 0,
			game: 0
		};
		let bye = PlayerData.bye(id, round, settings);
		bye.histories = histories;
		playerDict.set('bye', bye);
	}, [playerDict, round, id, settings]);

	const [floatContainer, setFloat] = useState('' as '' | 'tie breaks' | 'prizes' | 'description');

	const getDisplayname = useCallback((id: string | Player): string => {
		if (!id) return '\u200b';
		if (id === 'bye') return 'BYE';
		if (typeof id !== 'string') {
			let p = id as Player;
			if (settings.competitors === 'individual') return [p.firstName, p.lastName].join(' ');
			else return p.firstName;
		}
		let opp = playerDict.get(id);
		if (!opp) return id;
		let displayName: string;
		if (settings.competitors === 'individual') displayName = [opp.firstName, opp.lastName].join(' ');
		else displayName = opp.firstName;
		return displayName;
	}, [playerDict, settings.competitors]);

	useEffect(() => {
		let identifier = window.location.pathname.split('/')[2];
		if (settings.vanityURL && settings.vanityURL !== identifier) {
			window.history.pushState('', document.title, window.location.toString().replace(identifier, settings.vanityURL));
		}
	}, [settings.vanityURL]);

	
	if (loaded.state === false) return <NotFound />;

	return (
		<Contexts
			values={[
				[SettingsContext, settings]
			]}
		>
			<div id='main' className={cx({ still: cookies.getLocal('noAnimations') })}>
				<Background faded gradients={gradients} id={status.id} withContainer img={settings.logoURL} />
				<section className={cx('container', styles.nav)}>
					<div className={styles.stage}>
						<div className={styles.profileWrapper}>
							<div className={cx(styles.profilePicture, {[styles.preload]: !loaded.all })}>
								{loaded.all && settings.logoURL ?
									<Img src={settings.logoURL} alt='profile_picture' /> : 
									<div className={styles.defaultProfilePicture}>
										{!loaded.all || !status.name ? '\u200b' : status.name.match(regexes.lowerCase)?.map(v => v.substring(0, 1).toUpperCase()).join('')}
									</div>
								}
							</div>
						</div>
						{loaded.all && isLive ?
							<Link to={window.location.href.includes('dashboard') ?
								'#live' :
								px('t', settings.vanityURL || id, 'dashboard') + '#live'
							} className={styles.live}>
								<Circle diameter={180} width={10} className={styles.liveRing} />
								<FaPlay className={[styles.livePlay].join(' ')} />
							</Link>
							: null}
						<div className={[styles.extra, !loaded.all ? styles.preload : '/'].join(' ')}>
							{loaded.all ?
								settings.profileURL ? <a className={[styles.website].join(' ')} 
									href={settings.profileURL}
									target='_blank'
									rel='noopener noreferrer'
									title='Website'
								>
									<FaGlobe />
								</a> : null
								:  <div className={[styles.website].join(' ')}>{'\u200b'}</div>}
							{loaded.all ?
								settings.rulesURL ? 
									settings.rulesURL.endsWith('.pdf') && !isMobile() ?
										<Link to={['', 't', id, 'rules'].join('/')} className={styles.rules} title='Rules'>
											<FaBook />
										</Link>:
										<a
											className={[styles.rules].join(' ')} 
											href={settings.rulesURL}
											target='_blank'
											rel='noopener noreferrer'
											title='Rules'
										>
											<FaBook />
										</a> :
									null
								:  <div className={[styles.rules].join(' ')}>{'\u200b'}</div>}
						</div>
						<div className={styles.nameContainer}>
							<div className={styles.name} title={status.id}>
								{status.name || '\u200b'}
							</div>
						</div>
						<div className={styles.linkButtons}>
							{loaded.all ?
								<Link className={[styles.linkButton, 'header-button'].join(' ')} to={['', 't', id, 'announcements'].join('/')}>
									<FaBullhorn />Announcements
								</Link>
								:  <div className={[styles.linkButton].join(' ')}>{'\u200b'}</div>}
							{loaded.all ?
								status.user ? <div className={[styles.linkButton, 'header-button'].join(' ')} onClick={() => setFloat(floatContainer === 'description' ? '' : 'description')}>
									<FaFileAlt />Description
								</div> : null
								:  <div className={[styles.linkButton].join(' ')}>{'\u200b'}</div>}
							{loaded.all ?
								status.user ? <Link className={[styles.linkButton, 'header-button'].join(' ')} to={'/@/' + status.user}>
									<FaUser />{status.user}
								</Link> : null
								:  <div className={[styles.linkButton].join(' ')}>{'\u200b'}</div>}
						</div>
						<div className={styles.infoBox}>
							{loaded.all ? <>
								<div key='rules.1'>
									<div>Format: </div><div>{capitalise(settings.pairingSystem)}</div>
									<div>Created: </div><div>{new Date(status.createdAt).toString().slice(4, 15)}</div>
									<div>Type: </div><div>{capitalise(settings.competitors) + 's'}</div>
									<div>
										{capitalise(settings.competitors === 'team' ? 'Teams' : 'Players')}:
									</div>
									<div>
										{status.players}
									</div>
									{settings.gamePointTotal === 1 ? null : <>
										<div>Games:</div><div>{Math.ceil(players.reduce((acc, curr) => acc += curr.histories.reduce((acc, curr) => curr ? acc += curr.played : acc, 0), 0) / 2)}</div>
									</>}
								</div>
								<div key='rules.2'>
									<div>Location: </div><div>{settings.location || '-'}</div>
									<div>Time control: </div><div>{settings.timeControl || '-'}</div>
									<div>Status: </div><div>{status.active !== false ? 'Live' : status.round ? 'Finished' : 'Not yet started'}</div>
									{settings.prizes ? <div className={styles.tieBreakLink} onClick={() => setFloat(floatContainer === 'prizes' ? '' : 'prizes')}>Prizes</div> : settings.description ? <div>{'\u200b'}</div> : ''}
									<div className={styles.tieBreakLink} onClick={() => setFloat(floatContainer === 'tie breaks' ? '' : 'tie breaks')}>Tie breaks</div>
								</div>
							</>: null}
						</div>


						<Route path='/t/:id/:section?/:round?/' render={(rProps) => {
							let round = parseInt(rProps.match.params.round);
							if (isNaN(round)) round = status.round;
							if (round < 0 || round > settings.totalRounds) return null;
							return <Switcher.Mode
								id={props.match.params.id}
								settings={settings}
								section={rProps.match.params.section}
								round={round}
								loaded={loaded}
							/>;
						}} />
					</div>
				</section>
				<section className={cx(styles.tieBreakSection, {[styles.visible]: floatContainer })}>
					<div className='container'>
						<div>
							<div className={styles.tieBreakTitle} onClick={() => setFloat('')}>
								{floatContainer.toUpperCase()}
							</div>
							<div className={styles.tieBreaks}>
								{floatContainer === 'tie breaks' ?
									tieBreaks.map(([, v], i) => {
										if (!v) return null;
										return (
											<>
												<div key={[v, i, '1'].join(' ')}>#{i + 1}</div>
												<div key={[v, i, '2'].join(' ')}>{v.name}</div>
											</>
										);
									}) :
									floatContainer === 'description' ?
										settings.description :
										null
								}
							</div>
						</div>
					</div>
				</section>
				<Route path='/t/:id/:section?/:round?/' render={(rProps) => {
					let round: number;
					if (rProps.match.params.round === 'results' || rProps.match.params.round === 'individual') round = status.round + 1;
					else round = parseInt(rProps.match.params.round);
					if (isNaN(round)) round = status.round;
					if (settings.totalRounds === 'Infinity') settings.totalRounds = Infinity as number;
					if (round <= 0 || round > settings.totalRounds + 1) return null;
					let section = rProps.match.params.section;
					if (!section) section = 'pairings';
					if (section !== 'pairings' && section !== 'standings') return null;
					switch (section) {
					case 'standings':
						if (settings.pairingSystem === 'knockout') return null;
					}
					return <Switcher.Round
						direction={props.location.state.direction.slice(6)}
						section={rProps.match.params.section}
						round={round}
						id={props.match.params.id}
						playerDict={playerDict}
						status={status}
						settings={settings}
						loaded={loaded}
					/>;
				}} />
				<Route render={({location}) => {
					if (!loaded.status) return null;
					return (
						<DisplayNameContext.Provider value={getDisplayname}>
							<TiebreakContext.Provider value={tieBreaks}>
								<LiveContext.Provider value={liveContext}>
									<Switch location={location}>
										<Route exact path='/t/:id/' render={() => {
											let id = settings.vanityURL || props.match.params.id;
											return <Redirect push to={{
												pathname: `/t/${id}/dashboard`,
												state: props.history.location.state
											}}/>;
										}}/>
										<Route path='/t/:id/stats/' render={() => {
											return <Chart
												direction={props.location.state.direction.slice(6)}
												round={status.round}
												status={status}
												players={Array.from(playerDict.values())}
												settings={settings}
											/>;
										}} />
										<Route path='/t/:id/announcements/' render={() => {
											return <Announcements announcements={announcements.map((p, i) => {
												return {
													...p,
													content: p.announcement,
													id: i.toString(),
													tournamentId: id,
													author: '',
													role: 'director',
													title: '',
													link: ''
												};
											})} />;
										}} />
										<Route path='/t/:id/tv/' render={() => {
											return <Live
												streamURL={settings.streamURL}
												pop={pop}
												isLive={isLive}
											/>;
										}} />
										<Route path='/t/:id/rules/' render={(rProps) => {
											return <Rules
												id={rProps.match.params.id}
												settings={settings}
											/>;
										}} />
										<Route path='/t/:id/dashboard/' render={(rProps) => {
											return <Dashboard 
												status={status}
												settings={settings}
												players={players}
												loaded={loaded}
												pop={pop}
												isLive={isLive}
												announcements={announcements}
												
												direction={props.location.state.direction.slice(6)}
												id={props.match.params.id}
												profile={props.profile}
												playerDict = {playerDict}
												round = {round}
											/>;
										}} />
										<Route path='/t/:id/leaderboard' render={(rProps) => {
											return <Redirect push to={{
												pathname: ['', 't', rProps.match.params.id, 'standings', 'individual'].join('/'),
												state: props.history.location.state
											}} />;
										}} />
										<Route exact path='/t/:id/(pairings|standings)/' render={(rProps) => {
											if (status.round === undefined) return null;
											let showResults = rProps.match.url.includes('pairings') && status.round && !status.active;
											let slash = rProps.match.url.endsWith('/') ? '' : '/';
											return <Redirect push to={{
												pathname: rProps.match.url + slash + (showResults ? 'results' : status.round),
												state: props.history.location.state
											}}/>;
										}} />
										<Route path='/t/:id/pairings/:round' render={(rProps) => {
											if (rProps.match.params.round === 'results') {
												return <Results
													direction={props.location.state.direction.slice(6)}
													state={state} 
													playerDict={playerDict}
													settings={settings}
													id={rProps.match.params.id}
												/>;
											}
											let round = parseInt(rProps.match.params.round);
											if (isNaN(round)) return null;
											if (round < 0 || round > settings.totalRounds) return null;
											return <Pairings
												direction={props.location.state.direction.slice(6)}
												id={props.match.params.id}
												profile={props.profile}
												{...{ announcements, status, settings, playerDict, round }}
											/>;
										}}/>
										<Route path='/t/:id/standings/:round' render={(rProps) => {								
											return <StandingsRouter {...{ players, status, settings, state, rProps, direction: props.location.state.direction }} />;
										}} />
										<Route path='/t/:id/*' component={NotFound} status={404} />
									</Switch>
								</LiveContext.Provider>
							</TiebreakContext.Provider>
						</DisplayNameContext.Provider>
					);
				}}/>
				<Draggable margin={10}>
					{poppedComponent ?
						<div onClick={() => pop(null)}><FaWindowClose /></div> :
						null
					}
					<div dangerouslySetInnerHTML={{
						__html: poppedComponent?.outerHTML || ''
					}}/>
				</Draggable>
			</div>
		</Contexts>
	);
}