import React from 'react';

export default function ToggleSwitch(props: {
	on: boolean
	onSwitch: (newState: boolean) => void
	main: {
		on: string
		off: string
	}
	sub?: {
		on: string
		off: string
	}
}) {
	return (
		<div className={['header-button', 'switchContainer'].join(' ')}>
			<label>
				<div>{props.on ? props.main.on : props.main.off}</div>
				{props.sub ? <div>{props.on ? props.sub.on : props.sub.off }</div> : null}
			</label>
			<div className='toggleSwitch' onClick={() => props.onSwitch(!props.on)}>
				<input type='checkbox' className='check' checked={props.on}/>
				<div className='toggleSlider round' />
			</div>
		</div>
	);
}