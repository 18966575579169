import React, { ReactElement, useEffect, useState, useCallback } from 'react';
import cx from 'classnames';
import { ProfileData, Team, EnhancedPlayer, Player, Result, EnhancedMatch } from '../interfaces';
import { GET } from '../utils/requests';
import SectionBreak from '../components/SectionBreak';
import { RenderMatch } from './hooks';
import styles from '../css/profile.module.css';
import { Game } from '../resources/result';

export default function Play(props: {
	profile: Team & ProfileData
	type: 'team' | 'player'
}): ReactElement {

	const [upcoming, setUpcoming] = useState([] as EnhancedMatch[]);
	const [past, setPast] = useState([] as EnhancedMatch[]);
	const populateGames = useCallback(async () => {
		let u = [] as EnhancedMatch[];
		let p = [] as EnhancedMatch[];

		const tournaments = props.profile.tournaments.filter(t => t.active).sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf());

		let opponents = {} as {[key: string]: Player};
		let results = {} as {[key: string]: Result};
		let res = [] as string[];
		for (let t of tournaments) {
			let opps = [] as string[];
			if (t.histories) for (let i = 1; i < t.histories.length; i++) {
				let h = t.histories[i];
				if (!h) continue;
				opps.push(h.id);
				res.push(h.resultId);
			}

			let players = await GET({
				url: ['tournament', t.tournamentId, 'fetchPlayers'].join('/'), 
				params: { ids: opps.join(',') }
			}).catch((e) => {console.error(e); return [];});
			for (let p of players) opponents[p.id] = p;
		}
		let matches = await GET({ url: '/results', params: { ids: res.join(',') }}).catch((e) => {console.error(e); return [];});
		for (let i = 0; i < matches.length; i++) {
			let id = matches[i].id;
			results[id] = matches[i];
		}
		let games: {
			id: string,
			tournamentId: string
			games: Game[]
		}[] = await GET({ url: '/results', params: { ids: res.join(','), games: true }});

		for (let t of tournaments) {
			if (!t.histories) continue;
			for (let i = t.histories.length - 1; i >= 0; i--) {
				let currGame = t.histories[i];
				if (!currGame) continue;
				let result = results[t.histories[i].resultId];
				if (!result) continue;
				let match = {
					prop: t as EnhancedPlayer,
					opp: opponents[currGame.id] as Player,
					id: result.id,
					round: i,
					colour: currGame.colour,
					result,
					games: games.find(g => g.id === result.id)?.games,
					startTime: result.fetched?.startTime,
					endTime: result.fetched?.endTime
				};
				if (!match.result) continue;
				if (currGame.match === null) u.push(match);
				else p.push(match);
			}
		}
		setUpcoming(u);
		setPast(p);
	}, [props.profile, setUpcoming, setPast]);
	useEffect(() => {
		populateGames();
	}, [populateGames]);

	const [loaded, hasLoaded] = useState(false);
	const [gameDict, setGameDict] = useState({} as {[key: string]: Game[]});
	const fetchGames = useCallback((ids?: string[]) => {
		return GET({
			url: '/results',
			params: {
				round: 0,
				ids: ids?.join(','),
				games: true
			}
		}).then(arr => setGameDict(arr.reduce((acc: {[key: string]: Game[]}, curr: { id: string, games: Game[]}) => {
			acc[curr.id] = curr.games;
			return acc;
		}, {})));
	}, [setGameDict]);

	return <>
		<SectionBreak name='UPCOMING MATCHES'/>
		{upcoming.map((u, i) => <RenderMatch key={cx(u.id, i)} u={u} update={populateGames} />)}
		<SectionBreak name='PAST MATCHES' /*expanded={!upcoming.length}*/ />
		{past.map((u, i) => <RenderMatch key={cx(u.id, i)} u={u} update={populateGames} className={styles.inactive} />)}
		<section />
	</>;
}